import React from "react";
import {
  Box
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import {
  Header
} from "./components";

class Cabinet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  render () {
    const {
      classes
    } = this.props;

    return (
      <Box className={classes.root}>
        <Box className={classes.head}>
          <Header/>
        </Box>
        <Box className={classes.body}>
          { this.props.children }
        </Box>
      </Box>
    )
  }
}

const styles = {
  root: {
    height: "100lvh",
    backgroundColor: "#FAFAFA;"
  },
  head: {
    display: "flex",
    height: "70px",
  },
  body: {
    height: "calc(100lvh - 70px)",
    padding: 30,
    boxSizing: "border-box"
  }
};
Cabinet = withStyles(styles)(Cabinet);

export default Cabinet
