import {palette} from "../common";

export default {
    styleOverrides: {
        tooltip: {
            border: "1px solid #F0F0F0",
            backgroundColor: "#FFFFFF",
            boxShadow: "0px 5px 24px rgba(149, 157, 165, 0.15)",
            borderRadius: 8,
            padding: "8px 12px",


            fontSize: 12,
            lineHeight: '14px',
            color: "#282828",
            textAlign: "center"
        },
        arrow: {
            color: "white"
        }
    }
}
