import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,

  Grid,
  Tooltip,
  Pagination,
  IconButton,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon
} from "@mui/icons-material";
import moment from "moment";
import {Link} from "react-router-dom";
import allTranslations from "../../../../locales/allTranslations";
import {idFormat} from "../../../../helpers/formaters";

const TableCustom = (props) => {
  const {
    data,
    filter,
    totalCount,
    isLoad,
    onEdit,
    onDelete,
    onChangeFilter
  } = props;

  const handleChangePage = (event, page) => {
    let newFilter = {...filter};
    newFilter.page = page;
    onChangeFilter(newFilter);
  }

  return (
    <>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>{allTranslations('Фамилия')}</TableCell>
            <TableCell>{allTranslations('Имя')}</TableCell>
            <TableCell>{allTranslations('Отчетсво')}</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>{allTranslations('Телефон')}</TableCell>
            <TableCell align="right">{allTranslations('Дата рождения')}</TableCell>
            <TableCell align="right">{allTranslations('Дата обновления')}</TableCell>
            <TableCell align="right">{allTranslations('Дата создания')}</TableCell>
            <TableCell align="right">{allTranslations('Дата удаления')}</TableCell>
            <TableCell align="right"/>
          </TableRow>
        </TableHead>
        <TableBody>
          {Boolean(!isLoad) && data.map((t) => <RowData
            key={`RowData-${t.id}`}
            item={t}
            onEdit={onEdit}
            onDelete={onDelete}
          />)}
          {Boolean(isLoad) && <RowLoading/>}
        </TableBody>
      </Table>

      <Grid container justifyContent="flex-end" mt={2}>
        <Grid item>
          <Pagination
            page={filter.page}
            count={totalCount}
            onChange={handleChangePage}
          />
        </Grid>
      </Grid>

    </>
  )
}
const RowData = React.memo((props) => {
  const {
    item,
    onEdit,
    onDelete
  } = props;

  return (
    <TableRow>
      <TableCell sx={{whiteSpace: "nowrap"}}>
        { idFormat(item.id) }
      </TableCell>
      <TableCell>
        { item.lastName }
      </TableCell>
      <TableCell>
        { item.firstName }
      </TableCell>
      <TableCell>
        { item.patronymic }
      </TableCell>
      <TableCell>
        { item.email }
      </TableCell>
      <TableCell>
        { item.phone }
      </TableCell>
      <TableCell align="right">
        { moment(item.birthdate).format("DD.MM.YYYY") }
      </TableCell>
      <TableCell align="right">
        { moment(item.updatedAt).format("DD.MM.YYYY HH:mm:ss") }
      </TableCell>
      <TableCell align="right">
        { moment(item.createdAt).format("DD.MM.YYYY HH:mm:ss") }
      </TableCell>
      <TableCell align="right">
        { Boolean(item.deletedAt) ? moment(item.deletedAt).format("DD.MM.YYYY HH:mm:ss") : '-' }
      </TableCell>
      <TableCell align="right">
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Tooltip arrow title={allTranslations('Открыть')}>
              <IconButton to={`/patients/${item.id}`} color="primary" component={Link}>
                <VisibilityIcon/>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip arrow title={allTranslations('Редактировать')}>
              <IconButton color="primary" onClick={onEdit.bind(this, item, false)}>
                <EditIcon/>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip arrow title={allTranslations('Удалить')}>
              <IconButton color="error" onClick={onDelete.bind(this, item.id, false)}>
                <DeleteIcon/>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )
})
const RowLoading = React.memo(() => {
  return (
    <>
      {new Array(10).fill(1).map((t, i) => (
        <TableRow>

        </TableRow>
      ))}
    </>
  )
});

export default React.memo(TableCustom)
