import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,

  Box,
  Grid,
  Button,
  TextField,
  Container,
  FormHelperText,
  Typography, FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem
} from "@mui/material";
import {
  AutocompletePartner
} from "../../../../components";
import {
  DatePicker
} from "@mui/x-date-pickers/DatePicker";
import {
  Formik
} from "formik";
import * as Yup from "yup";
import {
  IMaskInput
} from "react-imask";
import allTranslations from "../../../../locales/allTranslations";
import getAllCameras from "../../../../helpers/getAllCameras";

const initForm = {
  "partnerId": null,
  "externalId": "",
  "editableId": "",
  "networkIsEnabled": false,
  "locationAddress": "",
  "lastCalibrationAt": "",
  "plannedCalibrationDate": "",
  "comment": ""
}

class DialogForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      listCamera: [],
      form: {...initForm},
      isOpen: false,
      isEdit: false,
      onSubmit: null
    }
    this.refFormik = React.createRef();
  }

  componentDidMount = async () => {
    await this.initListCamera();
  }

  initListCamera = async () => {
    const listCamera = await getAllCameras();
    this.setState({ listCamera });
  }

  open = ({form, onSubmit}) => {
    let newForm = form || {...initForm};
    if (!form && this.props.partnerId) {
      newForm.partnerId = this.props.partnerId;
    }

    this.setState({
      form: newForm,
      isEdit: Boolean(form),
      isOpen: true,
      onSubmit
    });
  }
  close = () => {
    this.setState({
      form: {...initForm},
      isOpen: false,
      isEdit: false,
      onSubmit: null
    })
  }

  changeForm = ({target}) => {
    if (!this.refFormik.current) {
      return
    }

    const {name, value} = target;
    let values = this.refFormik.current.values;
    values[name] = value;
    this.refFormik.current.setValues(values);
  }
  changeFormDate = (name, value) => {
    this.changeForm({
      target: {
        name,
        value
      }
    })
  }

  submit = (form) => {
    form.editableId = parseInt(String(form.editableId || '').replace(/\D+/g,""));
    ['partnerId', 'locationAddress', 'lastCalibrationAt', 'plannedCalibrationDate', 'comment'].map((t) => {
      if (!form[t]) {
        delete form[t];
      }
    })
    this.state.onSubmit(form, true);
  }
  checkValidation = () => {
    this.refFormik.current.handleSubmit();
  }

  render() {
    const {
      form,
      isOpen,
      isEdit,
      listCamera
    } = this.state;
    const {
      partnerId
    } = this.props;
    console.log('this.props: ', this.props);

    return (
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="md"
        onClose={this.close}
      >
        <DialogTitle>
          <Typography variant="h3">
            <Typography variant="h3">
              {allTranslations(isEdit ? 'Редактирование' : 'Создание')}
            </Typography>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Formik
            innerRef={this.refFormik}
            initialValues={form}
            validationSchema={validationSchema}
            onSubmit={this.submit}
          >{(props) => {
            const {
              values,
              errors,
              touched
            } = props;

            return (
              <Grid container pt={2} columnSpacing={2} rowSpacing={3}>
                {Boolean(!isEdit && !partnerId && false) && (
                  <Grid item xs={12}>
                    <AutocompletePartner
                      value={values.partnerId}
                      name="partnerId"
                      error={Boolean(touched.partnerId && errors.partnerId)}
                      helperText={touched.partnerId && errors.partnerId}
                      onChange={this.changeForm}
                    />
                  </Grid>
                )}
                {Boolean(!isEdit) && (
                  <Grid item xs={12}>
                    <FormControl fullWidth error={Boolean(touched.editableId && errors.editableId)}>
                      <InputLabel>ID {allTranslations('внешний')}`</InputLabel>
                      <Select
                        value={values.externalId}
                        label={`ID ${allTranslations('внешний')}`}
                        name="externalId"
                        onChange={this.changeForm}
                      >
                        {listCamera.map((item) => (
                          <MenuItem value={item.deviceId}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {Boolean(touched.editableId && errors.editableId) && (
                      <FormHelperText error={true} style={{textAlign: 'left', justifyContent: 'left'}}>{touched.editableId && errors.editableId}</FormHelperText>
                    )}
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    value={String(values.editableId || '')}
                    error={Boolean(touched.editableId && errors.editableId)}
                    helperText={touched.editableId && errors.editableId}
                    label={allTranslations('Внутренний идентификатор')}
                    name="editableId"
                    placeholder="..."
                    fullWidth
                    InputProps={{
                      inputComponent: TextMaskCustom
                    }}
                    onChange={this.changeForm}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={values.locationAddress}
                    error={Boolean(touched.locationAddress && errors.locationAddress)}
                    helperText={touched.locationAddress && errors.locationAddress}
                    label={allTranslations('Местоположение')}
                    name="locationAddress"
                    placeholder="..."
                    rows={2}
                    fullWidth
                    multiline
                    onChange={this.changeForm}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    value={values.lastCalibrationAt || null}
                    slotProps={{
                      textField: {
                        error: Boolean(touched.lastCalibrationAt && errors.lastCalibrationAt),
                        helperText: touched.lastCalibrationAt && errors.lastCalibrationAt,
                      },
                    }}
                    name="lastCalibrationAt"
                    label={allTranslations('Дата последней калибровки')}
                    placeholder="..."
                    fullWidth
                    sx={{width: "100%"}}
                    onChange={this.changeFormDate.bind(this, 'lastCalibrationAt')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    value={values.plannedCalibrationDate || null}
                    slotProps={{
                      textField: {
                        error: Boolean(touched.plannedCalibrationDate && errors.plannedCalibrationDate),
                        helperText: touched.plannedCalibrationDate && errors.plannedCalibrationDate,
                      },
                    }}
                    name="plannedCalibrationDate"
                    label={allTranslations('Дата запланированой калибровки')}
                    placeholder="..."
                    fullWidth
                    minDate={new Date()}
                    sx={{width: "100%"}}
                    onChange={this.changeFormDate.bind(this, 'plannedCalibrationDate')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={values.comment}
                    label={allTranslations('Комментарий')}
                    name="comment"
                    placeholder="..."
                    rows={4}
                    fullWidth
                    multiline
                    onChange={this.changeForm}
                  />
                </Grid>
                {Boolean(false) && (
                  <Grid item xs={12}>
                    <FormControlLabel
                      value={values.networkIsEnabled}
                      checked={values.networkIsEnabled}
                      control={<Checkbox/>}
                      label={allTranslations('Сеть включена')}
                      name="networkIsEnabled"
                      onChange={this.changeFormDate.bind(this, 'networkIsEnabled', !values.networkIsEnabled)}
                    />
                  </Grid>
                )}
              </Grid>
            )
          }}</Formik>
        </DialogContent>
        <DialogActions>
          <Grid container px={2} spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button variant="outlined" onClick={this.close}>
                {allTranslations('Отменить')}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={this.checkValidation}>
                {allTranslations(isEdit ? 'Редактировать' : 'Создать')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
}
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;

  const handleOnAccept = (value) => {
    onChange({ target: { name: props.name, value } })
  }

  return (
    <IMaskInput
      {...other}
      mask="00-000"
      scale={0}
      inputRef={ref}
      onAccept={handleOnAccept}
    />
  );
});

const validationSchema = Yup.object().shape({
  // partnerId: Yup.string().required(allTranslations('Заполните поле')),
  externalId: Yup.string().required(allTranslations('Заполните поле')),
  editableId: Yup.string().required(allTranslations('Заполните поле')),
  // locationAddress: Yup.string().required(allTranslations('Заполните поле')),
  // lastCalibrationAt: Yup
  //   .date()
  //   .required(allTranslations('Заполните поле')),
  // plannedCalibrationDate: Yup
  //   .date()
  //   .min(new Date(), allTranslations('Дата не может быть меньше сегодня'))
  //   .required(allTranslations('Заполните поле')),
});

export default DialogForm
