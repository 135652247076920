import React, {Component} from "react";
import {
  Grid,
  Button,
  Backdrop,
  CircularProgress
} from "@mui/material";
import {
  Table,
  DialogForm
} from "./components";
import {
  Notification,
  notificationTypes
} from "../../common/Notification";
import {
  DialogConfirmation
} from "../../components";
import agent from "../../agent/agent";
import allTranslations from "../../locales/allTranslations";

class Equipment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      filter: {
        page: 1
      },
      pagination: {
        total: 1
      },
      isLoad: true,
      isBackdrop: false,
    };
    this.refDialogForm = React.createRef();
    this.refDialogConfirmation = React.createRef();
  }

  componentDidMount = async () => {
    await this.getData();
  }

  getData = async () => {
    this.setState({ isLoad: true })

    const res = await agent.get(`/equipments?limit=20&page=${this.state.filter.page}`).then((res) => {
      return res.data
    }).catch(() => {
      return null
    });
    const pagination = {
      total: Math.ceil(res?.count / 20)
    }

    this.setState({
      data: res?.equipments,
      pagination,
      isLoad: false
    })
  }

  // Логика работы с оборудованием
  editEquipment = async (form, isSubmit) => {
    if (!isSubmit) {
      const initForm = {
        id: form.id,
        partnerId: form.partnerId,
        externalId: form.externalId,
        editableId: form.editableId,
        "networkIsEnabled": form.networkIsEnabled,
        "locationAddress": form.locationAddress,
        "lastCalibrationAt": new Date(form.lastCalibrationAt),
        "plannedCalibrationDate": new Date(form.plannedCalibrationDate),
        "comment": form.comment
      };
      this.refDialogForm.current.open({
        form: initForm,
        onSubmit: this.editEquipment.bind(this)
      })

      return
    }

    this.setState({ isBackdrop: true });

    const body = JSON.parse(JSON.stringify({...form}));

    const formId = body.id;
    delete body.id;
    delete body.partnerId;
    delete body.externalId;
    const res = await agent.patch(`/equipments/${ formId }`, body).then(() => {
      return true
    }).catch(() => {
      return false
    })
    if (!res) {
      this.setState({ isBackdrop: false });
      Notification({
        type: notificationTypes.error,
        message: allTranslations('Ошибка сервера')
      })

      return
    }
    await this.getData();
    this.refDialogForm.current.close();
    this.setState({ isBackdrop: false });
    Notification({
      type: notificationTypes.success,
      message: allTranslations('Оборудование успешно изменено')
    })
  };
  editSingleFieldEquipment = async (id, form) => {
    this.setState({ isBackdrop: true });

    const res = await agent.patch(`/equipments/${ id }`, form).then(() => {
      return true
    }).catch(() => {
      return false
    });
    if (!res) {
      this.setState({ isBackdrop: false });
      Notification({
        type: notificationTypes.error,
        message: allTranslations('Ошибка сервера')
      })

      return
    }

    await this.getData();
    this.setState({ isBackdrop: false });
    Notification({
      type: notificationTypes.success,
      message: allTranslations('Оборудование успешно изменено')
    })
  }
  deleteEquipment = async (id, isConfig) => {
    if (!isConfig) {
      this.refDialogConfirmation.current.open({
        message: `${allTranslations('Вы действительно хотите удалить оборудование')} ID: ${ id }`,
        onSubmit: this.deleteEquipment.bind(this, id, true)
      })
      return
    }

    this.setState({ isBackdrop: true });

    const res = await agent.delete(`/equipments/${ id }`).then(() => {
      return true
    }).catch(() => {
      return false
    });
    if (!res) {
      Notification({
        type: notificationTypes.error,
        message: allTranslations('Ошибка сервера')
      })
      return
    }

    await this.getData();
    this.setState({ isBackdrop: false });
    Notification({
      type: notificationTypes.success,
      message: allTranslations('Оборудование успешно удалено')
    })
  };
  createEquipment = async (form, isSubmit) => {
    if (!isSubmit) {
      this.refDialogForm.current.open({
        onSubmit: this.createEquipment.bind(this)
      })
      return
    };

    this.setState({ isBackdrop: true });

    const res = await agent.post('/equipments', form).then((res) => {
      return true
    }).catch(() => {
      return false
    });
    if (!res) {
      this.setState({ isBackdrop: false });
      Notification({
        type: notificationTypes.error,
        message: allTranslations('Ошибка сервера')
      })
      return
    }

    await this.getData();
    this.setState({ isBackdrop: false });
    this.refDialogForm.current.close();
    Notification({
      type: notificationTypes.success,
      message: allTranslations('Оборудование успешно добавлено')
    });
  };


  render() {
    const {
      data,
      filter,
      pagination,
      isLoad,
      isBackdrop
    } = this.state;
    const {
      partnerId
    } = this.props;

    return (
      <>

        <Grid container mb={2} justifyContent="flex-end">
          <Grid item>
            <Button variant="contained" onClick={this.createEquipment}>
              {allTranslations('Добавить')}
            </Button>
          </Grid>
        </Grid>

        <Table
          data={data}
          filter={filter}
          pagination={pagination}
          isLoad={isLoad}
          onEdit={this.editEquipment}
          onDelete={this.deleteEquipment}
          onEditSingleField={this.editSingleFieldEquipment}
        />

        <DialogForm ref={this.refDialogForm} partnerId={partnerId}/>
        <DialogConfirmation ref={this.refDialogConfirmation}/>

        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>

      </>
    );
  }
}

export default Equipment
