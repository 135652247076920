import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,

  Grid,
  Button,
  Typography
} from "@mui/material";
import {

} from "@mui/styles";
import allTranslations from "../../../../locales/allTranslations";

const ResultForm = (props) => {
  const {
    data,
    isAvaible1cOrder,
    onSendTo1c
  } = props;

  return (
    <>

      <Grid container alignItems="flex-end" justifyContent="space-between" mb={2}>
        <Grid item>
          <Typography variant="h1">Оправа и параметры центрирования</Typography>
        </Grid>
        {Boolean(isAvaible1cOrder) && (
          <Grid item>
            <Button variant="contained" onClick={onSendTo1c}>
              Отправить в 1C
            </Button>
          </Grid>
        )}
      </Grid>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{allTranslations('Параметры')}</TableCell>
            <TableCell align="center">{allTranslations('Правый')}</TableCell>
            <TableCell align="center">{allTranslations('Левый')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{allTranslations('Межзрачковое расстояние')} (PD)</TableCell>
            <TableCell align="center">{data?.pd?.r} mm</TableCell>
            <TableCell align="center">{data?.pd?.l} mm</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{allTranslations('Вертикальное расстояние между зрачками')} (CVD)</TableCell>
            <TableCell align="center">{data?.cvd?.r} mm</TableCell>
            <TableCell align="center">{data?.cvd?.l} mm</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{allTranslations('Пантоскопический угол')} (PT)</TableCell>
            <TableCell align="center">{data?.pt?.r}°</TableCell>
            <TableCell align="center">{data?.pt?.l}°</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{allTranslations('Угол лицевой формы')} (FFA)</TableCell>
            <TableCell colSpan={2} align="center">{data?.ffa}°</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{allTranslations('Высота центрирования')}</TableCell>
            <TableCell align="center">{data?.height?.r} mm</TableCell>
            <TableCell align="center">{data?.height?.l} mm</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{allTranslations('Ширина окуляра')} (BL)</TableCell>
            <TableCell align="center">{data?.box_h?.r} mm</TableCell>
            <TableCell align="center">{data?.box_h?.l} mm</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{allTranslations('Высота окуляра')} (BH)</TableCell>
            <TableCell align="center">{data?.box_v?.r} mm</TableCell>
            <TableCell align="center">{data?.box_v?.l} mm</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{allTranslations('Расстояние между линзами')} (DBL)</TableCell>
            <TableCell align="center" colSpan={2}>{data?.dbl} mm</TableCell>
          </TableRow>
        </TableBody>
      </Table>

    </>
  )
}

export default React.memo(ResultForm)
