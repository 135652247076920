import React, {Component} from "react";
import {
  Notification,
  notificationTypes
} from "../../common/Notification";
import queryString from "query-string";
import agent from "../../agent/agent";

class CreateFrom_1c extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {
    await this.parseUrl();
  }

  parseUrl = async () => {
    const search = window.location.search;
    if (!search) {
      this.props.history.replace('/')
      return
    }

    const searchObject = queryString.parse(search, {
      arrayFormat: "bracket"
    });

    // Авторизация по новому токену
    localStorage.setItem('token', searchObject?.['x-auth-token']);
    agent.defaults.headers['x-auth-token'] = searchObject?.['x-auth-token'];
    const authMe = await agent.get('/auth/me').then((res) => {
      return res.data
    }).catch(() => {
      return {}
    });
    this.props.setAuthMe(authMe);

    // Получения заказа по GET параметрам
    const bodyOrder = {...searchObject};
    delete bodyOrder['x-auth-token'];
    const resOrder = await agent.post('/orders', bodyOrder).then((res) => {
      return res.data?.order || null
    }).catch(() => {
      return null
    });
    if (!resOrder) {
      Notification({
        type: notificationTypes.error,
        message: "Заказ не найден"
      });
      this.props.history.replace('/')
      return
    }

    this.props.history.replace(`/orders/${ resOrder.id }?open-order-edit=true`);
  }

  render () {
    return (
      <>

      </>
    );
  }
}

export default CreateFrom_1c
