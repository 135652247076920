import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,

  Box,
  Grid,
  List,
  Typography,

  ListItem,
  ListItemText,
  ListItemButton
} from "@mui/material";
import allTranslations from "../../locales/allTranslations";

class DialogSelectedEquipment extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      isOpen: false,
      onSubmit: null
    }
  }

  open = ({ list, onSubmit }) => {
    this.setState({
      list,
      onSubmit,
      isOpen: true
    })
  }
  close = () => {
    this.setState({
      isOpen: false
    })
  }

  onSelected = (id) => {
    this.close();
    this.state.onSubmit(id)
  }

  render () {
    const {
      list,
      isOpen
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Typography variant="h3">
            {allTranslations('Выбор оборудования')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <List>
            {list.map((item, index) => (
              <ListItem>
                <ListItemButton onClick={this.onSelected.bind(this, item.id)}>
                  <ListItemText>
                    <span style={{color: 'black'}}>{item.locationAddress}</span>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    )
  }
}

export default DialogSelectedEquipment
