// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import CreateFrom_1c from './CreateFrom_1c';
import {setAuthMe} from "../../states/global";

export default compose(
  connect(
    state => ({}),
    dispatch => ({
      setAuthMe: (authMe) => dispatch(setAuthMe(authMe))
    }),
  ),
)(CreateFrom_1c);
