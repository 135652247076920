// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import OrderView from './OrderView';
import {setPatient} from "../../states/patient";

export default compose(
  connect(
    state => ({}),
    dispatch => ({
      setPatient: (patient) => dispatch(setPatient(patient))
    }),
  ),
)(OrderView);
