import React, {useContext, useState} from "react";
import {
  Box,
  Grid,
  Tooltip,
  Popover,
  IconButton, MenuList, MenuItem, ListItemIcon, ListItemText, Typography
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import {
  Menu as MenuIcon,
  People as PeopleIcon,
  Logout as LogoutIcon,
  GTranslate as GTranslateIcon,
  Description as DescriptionIcon,
  Engineering as EngineeringIcon,
  PersonSearch as PersonSearchIcon,
  Construction as ConstructionIcon,
  FlipCameraIos as FlipCameraIosIcon,
  FilterTiltShift as FilterTiltShiftIcon,
  FlipCameraAndroid as FlipCameraAndroidIcon
} from "@mui/icons-material";
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import {compose} from "recompose";
import {connect, ReactReduxContext, useSelector} from "react-redux";
import {setAuthMe} from "../../../states/global";
import {setPatient} from "../../../states/patient";
import {useHistory, useRouteMatch} from "react-router-dom";
import allTranslations from "../../../locales/allTranslations";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gyroscope: null
    }
  }

  componentDidMount = () => {
    this.initGyroscope();
    window.addEventListener("storageGyroscope", this.initGyroscope);
  }

  logoutAdmin = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('patient_id');
    this.props.setAuthMe(null);
    this.props.setPatient(null);
  }
  logoutPatient = () => {
    localStorage.removeItem('patient_id');
    this.props.setPatient(null);
  }

  initGyroscope = () => {
    const gyroscope = localStorage.getItem("gyroscopeSerialRequestPort");
    this.setState({
      gyroscope
    });
  }
  resetGyroscope = () => {
    localStorage.removeItem("gyroscopeSerialRequestPort");
    window.dispatchEvent(new Event("storageGyroscope"))
  }

  render () {
    const {
      classes,
      patient
    } = this.props;
    const {
      gyroscope
    } = this.state;

    return (
      <Box className={classes.root}>
        <Grid container sx={{height: "100%"}}>
          <Grid item sx={{flex: 1}}></Grid>
          <Grid item sx={{display: "flex", flex: 1, alignItems: "center", justifyContent: "center"}}>
            <a href="/">
              <img src={require('../../../assets/image/logo/logo.svg').default}/>
            </a>
          </Grid>
          <Grid item sx={{flex: 1}}>
            <Grid container spacing={1} alignItems="center" justifyContent="flex-end" pr={2} height="100%">
              {Boolean(gyroscope) && (
                <Grid item>
                  <Tooltip title={allTranslations('Сбросить выбранный гироскоп')} arrow>
                    <IconButton onClick={this.resetGyroscope}>
                      <FilterTiltShiftIcon/>
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              <Grid item>
                <LocaleMenu/>
              </Grid>
              <Grid item>
                <AdminMenu/>
              </Grid>
              {Boolean(patient?.patient) && (<Grid item>
                <Tooltip title={allTranslations('Сменить клиента')} arrow>
                  <IconButton color="primary" onClick={this.logoutPatient}>
                    <FlipCameraAndroidIcon/>
                  </IconButton>
                </Tooltip>
              </Grid>)}
              <Grid item>
                <Tooltip title={allTranslations('Выйти из системы')} arrow>
                  <IconButton color="error" onClick={this.logoutAdmin}>
                    <LogoutIcon/>
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    )
  }
}
const LocaleMenu = React.memo(() => {
  const languages = [
    {label: "Русский", value: "ru"},
    {label: "English", value: "en"},
  ];

  const handleChangeLanguages = (val) => {
    localStorage.setItem("locale", val);
    window.location.reload();
  }

  return (
    <PopupState variant="popover">
      {(popupState) => (
        <div>
          <IconButton {...bindTrigger(popupState)}>
            <GTranslateIcon/>
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuList>
              {languages.map((t) => (
                <MenuItem key={`MenuItem-${t.value}`} onClick={handleChangeLanguages.bind(this, t.value)}>
                  <Typography variant="h4" sx={{color: "black"}}>
                    {t.label}
                  </Typography>
                </MenuItem>
              ))}
            </MenuList>
          </Popover>
        </div>
      )}
    </PopupState>
  )
});
const AdminMenu = React.memo(() => {
  const globalState = useSelector((state) => state.global);
  const history = useHistory();

  const handleClickMenu = (path) => {
    history.push(path);
  }
  const _navigations = () => {
    let list = [
      {role: "all", path: "/calibration", title: allTranslations('Калибровка'), Icon: FlipCameraIosIcon},
      {role: "admin", path: "/users", title: allTranslations('Пользователи'), Icon: PeopleIcon},
      // {role: "admin", path: "/partners", title: allTranslations('Партнеры'), Icon: EngineeringIcon},
      {role: "all", path: "/equipment", title: allTranslations('Оборудование'), Icon: ConstructionIcon},
      {role: "all", path: "/orders", title: allTranslations('Заказы'), Icon: DescriptionIcon},
      {role: "all", path: "/patients", title: allTranslations('Пациенты'), Icon: PersonSearchIcon},
    ];

    return list.filter((t) => {
      return Boolean(t?.role === 'all' || t.role === globalState?.role)
    });
  }

  return (
    <PopupState variant="popover">
      {(popupState) => (
        <div>
          <IconButton {...bindTrigger(popupState)}>
            <MenuIcon/>
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuList>
              {_navigations().map((element, index) => (
                <MenuItem onClick={handleClickMenu.bind(this, element.path)}>
                  <ListItemIcon>
                    <element.Icon sx={{fontSize: 24}}/>
                  </ListItemIcon>
                  <Typography variant="h4" sx={{color: "black"}}>
                    { element.title }
                  </Typography>
                </MenuItem>
              ))}
            </MenuList>
          </Popover>
        </div>
      )}
    </PopupState>
  )
})

const styles = {
  root: {
    width: "100%",
    height: "100%",
    background: "#FFF",
    boxShadow: "0px 8px 24px 0px rgba(169, 169, 169, 0.15)",
  }
};
Header = withStyles(styles)(Header);

export default compose(
  connect(
    state => ({
      patient: state?.patient
    }),
    dispatch => ({
      setAuthMe: (authMe) => dispatch(setAuthMe(authMe)),
      setPatient: (patient) => dispatch(setPatient(patient)),
    }),
  ),
)(Header);
