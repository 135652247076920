import {palette} from "../common";

export default {
    styleOverrides: {

        root: {
            padding: 0,
            height: "auto",
            width: "auto"
        },

        track: {
            height: 24,
            width: 42,
            borderRadius: 100,
            backgroundColor: "#8E8E8E"
        },

        thumb: {
            width: 22,
            height: 22,
            backgroundColor: "white"
        },

        switchBase: {
            width: 22,
            height: 22,
            padding: 0,
            margin: "1px 2px",
            backgroundColor: "transparent",

            "&.Mui-checked": {
                margin: "1px -2px",
            },
            "&.Mui-checked+.MuiSwitch-track": {
                backgroundColor: "#10B879",
                opacity: 1
            },
        },

    }
}
