import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,

  Box,
  Grid,
  Button,
  Divider,
  TextField,
  Typography
} from "@mui/material";
import {
  Formik
} from "formik";
import * as Yup from "yup";
import {IMaskInput} from "react-imask";

const initForm = {
  "legalName": "",
  "inn": "",
  "contactFirstName": "",
  "contactLastName": "",
  "contactPatronymic": "",
  "contactPhone": "",
  "contactEmail": "",
  "address": ""
}

class DialogForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      form: {...initForm},
      isOpen: false,
      isEdit: false,
      onSubmit: null
    };
    this.refFormik = React.createRef();
  }

  open = ({ form, onSubmit }) => {
    this.setState({
      form: form || {...initForm},
      isOpen: true,
      isEdit: Boolean(form),
      onSubmit
    })
  }
  close = () => {
    this.setState({
      form: {...initForm},
      isOpen: false,
      isEdit: false,
      onSubmit: null
    });
  }

  changeForm = ({ target }) => {
    if (!this.refFormik.current) {
      return
    }

    const { name, value } = target;
    let values = this.refFormik.current.values;
    values[name] = value;
    this.refFormik.current.setValues(values);
  }

  submit = (form) => {
    this.state.onSubmit(form, true);
  }
  formikSubmit = () => {
    this.refFormik.current.handleSubmit();
  }

  render () {
    const {
      form,
      isOpen,
      isEdit
    } = this.state;

    return (
      <Dialog
        fullWidth
        maxWidth="md"
        open={isOpen}
        onClose={this.close}
      >
        <DialogTitle>
          <Typography variant="h3">
            {isEdit ? "Редактирование" : "Создание"}
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Formik
            innerRef={this.refFormik}
            initialValues={form}
            validationSchema={validationSchema}
            onSubmit={this.submit}
          >{(props) => {
            const {
              values,
              errors,
              touched,
            } = props;

            return (
              <Grid container rowSpacing={3} columnSpacing={2} pt={2}>
                <Grid item xs={6}>
                  <TextField
                    value={values.contactLastName}
                    error={Boolean(touched.contactLastName && errors.contactLastName)}
                    helperText={touched.contactLastName && errors.contactLastName}
                    label="Фамилия"
                    placeholder="..."
                    name="contactLastName"
                    fullWidth
                    onChange={this.changeForm}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={values.contactFirstName}
                    error={Boolean(touched.contactFirstName && errors.contactFirstName)}
                    helperText={touched.contactFirstName && errors.contactFirstName}
                    label="Имя"
                    placeholder="..."
                    name="contactFirstName"
                    fullWidth
                    onChange={this.changeForm}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={values.contactPatronymic}
                    error={Boolean(touched.contactPatronymic && errors.contactPatronymic)}
                    helperText={touched.contactPatronymic && errors.contactPatronymic}
                    label="Отчество"
                    placeholder="..."
                    name="contactPatronymic"
                    fullWidth
                    onChange={this.changeForm}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider/>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    value={values.legalName}
                    error={Boolean(touched.legalName && errors.legalName)}
                    helperText={touched.legalName && errors.legalName}
                    label="Юридическое название"
                    name="legalName"
                    placeholder="..."
                    fullWidth
                    onChange={this.changeForm}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={values.contactPhone}
                    error={Boolean(touched.contactPhone && errors.contactPhone)}
                    helperText={touched.contactPhone && errors.contactPhone}
                    label="Телефон"
                    name="contactPhone"
                    placeholder="+7 (___) ___ __ __"
                    fullWidth
                    onChange={this.changeForm}
                    InputProps={{inputComponent: TextMaskCustom}}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={values.contactEmail}
                    error={Boolean(touched.contactEmail && errors.contactEmail)}
                    helperText={touched.contactEmail && errors.contactEmail}
                    label="Email"
                    name="contactEmail"
                    placeholder="..."
                    fullWidth
                    onChange={this.changeForm}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={values.inn}
                    error={Boolean(touched.inn && errors.inn)}
                    helperText={touched.inn && errors.inn}
                    label="ИНН"
                    name="inn"
                    placeholder="..."
                    fullWidth
                    onChange={this.changeForm}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={values.address}
                    error={Boolean(touched.address && errors.address)}
                    helperText={touched.address && errors.address}
                    label="Адрес"
                    name="address"
                    placeholder="..."
                    fullWidth
                    onChange={this.changeForm}
                  />
                </Grid>
              </Grid>
            )
          }}</Formik>
        </DialogContent>

        <DialogActions>
          <Grid px={2} container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button variant="outlined" onClick={this.close}>
                Закрыть
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={this.formikSubmit}>
                {isEdit ? 'Изменить' : 'Создать'}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
};
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const {onChange, ...other} = props;

  const handleOnAccept = (value) => {
    if (value === props.value) {
      return
    }
    onChange({target: {name: props.name, value}})
  }

  return (
    <IMaskInput
      mask="+7 (000) 000 00 00"
      thousandsSeparator=""
      unmask={true}
      inputRef={ref}
      onAccept={handleOnAccept}
      {...other}
    />
  );
});

const validationSchema = Yup.object().shape({
  legalName: Yup.string().required('Обязательно для заполнения'),
  inn: Yup.string().min(10, 'Некорректно захваченные данные').max(12, 'Некорректно захваченные данные').required('Обязательно для заполнения'),
  contactFirstName: Yup.string().required('Обязательно для заполнения'),
  contactLastName: Yup.string().required('Обязательно для заполнения'),
  contactPatronymic: Yup.string(),
  contactPhone: Yup.string().required('Обязательно для заполнения'),
  contactEmail: Yup.string().email("Некорректно захваченные данные"),
  address: Yup.string().required('Обязательно для заполнения'),
});

export default DialogForm
