import React from "react";
import {
  Box,
  Grid,
  Slider,
  Typography
} from "@mui/material";
import {

} from "@mui/styles";

class ContrastSlider extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
    this.refSlider = React.createRef();
  }

  componentDidMount = () => {
    this.disabledKeyboarSlider();
  }

  disabledKeyboarSlider = () => {
    if (!this.refSlider.current) {
      setTimeout(() => {
        this.disabledKeyboarSlider();
      }, 100);
      return
    }

    const input = this.refSlider.current.querySelector('input');
    input.addEventListener("keydown", function(event) {
      event.preventDefault();
      return false;
    });
  }

  render () {
    const {
      value,
      onChange
    } = this.props;

    return (
      <Box>
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="subtitle1">
              Контраст
            </Typography>
          </Grid>
          <Grid item sx={{flex: 1}}>
            <Slider
              ref={this.refSlider}
              defaultValue={value}
              size="small"
              min={-100}
              max={100}
              aria-label="Small"
              valueLabelDisplay="on"
              onChange={(e, v) => onChange(v)}
            />
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Box>
    )
  }
}

export default ContrastSlider
