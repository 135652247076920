import React from "react";
import {
  Box,
  Grid,
  Typography
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import moment from "moment";
import {palette} from "../../../../theme/common";
import allTranslations from "../../../../locales/allTranslations";

const PatientInfo = (props) => {
  const {
    patient
  } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography variant="h1" mb="50px" color={palette.primary.main}>{allTranslations('Пациент')}</Typography>

      <Grid container spacing="30px">
        <Grid item xs={8}>
          <Typography className={classes.formTitle}>{allTranslations('ФИО')}</Typography>
          <Typography className={classes.formValue}>
            {[patient?.lastName, patient?.firstName, patient?.patronymic].filter((t) => !!t).join(" ")}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.formTitle}>{allTranslations('Консультация/сессия')}</Typography>
          <Typography className={classes.formValue}>{allTranslations('Консультация/сессия')}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.formTitle}>{allTranslations('Телефон')}</Typography>
          <Typography className={classes.formValue}>{patient?.phone}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.formTitle}>E-mail</Typography>
          <Typography className={classes.formValue}>{patient?.email}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.formTitle}>{allTranslations('Дата рождения')}</Typography>
          <Typography className={classes.formValue}>{moment(patient?.birthdate).format("DD.MM.YYYY")}</Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  root: {},

  formTitle: {
    marginLeft: 30,
    marginBottom: 15,
    fontSize: 16,
    lineHeight: "25px",
    color: "#747474"
  },
  formValue: {
    padding: "15px 30px",
    boxSizing: "border-box",
    borderRadius: "15px",
    border: "1px solid #E4E4E4",
    background: "#FDFDFD",
    width: "100%",

    fontSize: 16,
    lineHeight: "25px",
    color: "#000000"
  },
}));

export default React.memo(PatientInfo)
