import React from "react";
import {
  Box,
  Grid,
  Button,
  Typography,

  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import {
  Print as PrintIcon
} from "@mui/icons-material";
import {palette} from "../../../../theme/common";
import allTranslations from "../../../../locales/allTranslations";

const ListMeasurements = (props) => {
  const {
    data,
    calculationId
  } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography variant="h6">ID замера: {calculationId}</Typography>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>{allTranslations('Параметры')}</TableCell>
            <TableCell align="center">{allTranslations('Правый')}</TableCell>
            <TableCell align="center">{allTranslations('Левый')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{allTranslations('Межзрачковое расстояние')} (PD)</TableCell>
            <TableCell align="center">{data?.pd?.r} mm</TableCell>
            <TableCell align="center">{data?.pd?.l} mm</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{allTranslations('Вертикальное расстояние между зрачками')} (CVD)</TableCell>
            <TableCell align="center">{data?.cvd?.r} mm</TableCell>
            <TableCell align="center">{data?.cvd?.l} mm</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{allTranslations('Пантоскопический угол')} (PT)</TableCell>
            <TableCell align="center">{data?.pt?.r}°</TableCell>
            <TableCell align="center">{data?.pt?.l}°</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{allTranslations('Угол лицевой формы')} (FFA)</TableCell>
            <TableCell colSpan={2} align="center">{data?.ffa}°</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{allTranslations('Высота центрирования')}</TableCell>
            <TableCell align="center">{data?.height?.r} mm</TableCell>
            <TableCell align="center">{data?.height?.l} mm</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{allTranslations('Ширина окуляра')} (BL)</TableCell>
            <TableCell align="center">{data?.box_h?.r} mm</TableCell>
            <TableCell align="center">{data?.box_h?.l} mm</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{allTranslations('Высота окуляра')} (BH)</TableCell>
            <TableCell align="center">{data?.box_v?.r} mm</TableCell>
            <TableCell align="center">{data?.box_v?.l} mm</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{allTranslations('Расстояние между линзами')} (DBL)</TableCell>
            <TableCell align="center" colSpan={2}>{data?.dbl} mm</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    padding: "30px",
    boxSizing: "border-box",
    borderRadius: "12px",
    backgroundColor: "#F5F4F5"
  },
  table: {
    borderCollapse: "initial",
    borderSpacing: "0 10px",

    "& *": {
      boxShadow: "none",
    },
    "& .MuiTableHead-root .MuiTableRow-root": {
      "& .MuiTableCell-root:first-child": {
        borderRadius: "10px 0 0 10px"
      },
      "& .MuiTableCell-root:last-child": {
        borderRadius: "0 10px 10px 0"
      },
    },
    "& .MuiTableBody-root .MuiTableRow-root:nth-child(2n - 1)": {
      "& .MuiTableCell-root:first-child": {
        borderRadius: "10px 0 0 10px"
      },
      "& .MuiTableCell-root:last-child": {
        borderRadius: "0 10px 10px 0"
      },
    },
    "& .MuiTableBody-root .MuiTableRow-root:nth-child(2n)": {
      "& .MuiTableCell-root": {
        backgroundColor: "#F5F4F5"
      }
    },
  },
  buttonControls: {
    padding: "4px 8px",
    borderRadius: "4px",

    fontSize: "14px",
    lineHeight: "20px",
    color: "#5B5B5B",

    "& svg": {
      marginLeft: 10
    }
  }
}));

export default React.memo(ListMeasurements)
