import {palette} from "../common";

export default {
    styleOverrides: {
        root: {
            "& .MuiButtonBase-root": {
                width: 40,
                height: 40,
                backgroundColor: "#F7F7F7",
                borderRadius: 8,

                fontSize: 16,
                lineHeight: "20px",
                textAlign: "center",
                fontWeight: "500",
                color: palette.primary.main
            },
            "& .MuiButtonBase-root.Mui-selected": {
                backgroundColor: palette.primary.main,
                color: "#FFFFFF"
            },
        }
    }
}
