const SET_PATIENT = 'app/SET_PATIENT'

const initialState = {
  patient: null,
};

export function setPatient(patient) {
  return {
    type: SET_PATIENT,
    patient
  }
}

export default function PatientState(state = initialState, action = {}) {
  switch (action.type) {
    case SET_PATIENT: {
      let patient = action.patient

      return {
        ...state,
        patient
      }
    }
    default:
      return state;
  }
}
