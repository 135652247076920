import React from "react";
import {Switch, Redirect} from "react-router-dom";
import {
  RouteWithLayout
} from "../components";
import {
  Cabinet as CabinetLayouts, Minimal as MinimalLayouts
} from "../layouts";
import {
  Calibration as CalibrationPage,
  FrameMeasurement as FrameMeasurementPage
} from "../pages";
import {
  pages as pagesCommon
} from "./Registration";


const pages = [
  ...pagesCommon,
  {
    path: '/',
    component: FrameMeasurementPage,
    layout: CabinetLayouts,
    rules: [],
    exact: true
  }
];

const LandingRoutes = () => {
  return (
    <Switch>
      {
        pages.map((page, idx) => (
          <RouteWithLayout
            key={'page-' + idx}
            {...page}
          />
        ))
      }

      <Redirect to="/"/>
    </Switch>
  );
};

export default LandingRoutes;
