import agent from "../agent/agent";

const snapshotVideo = async (video, {width, height}) => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

  let blog = await new Promise(resolve => canvas.toBlob(resolve));
  blog = await new File([blog], `blog.jpg`, {type:'image/jpg'});

  return blog;
};
const splitImage = async (blob, {width, height}) => {
  const image = new Image();
  image.src = URL.createObjectURL(blob);
  await image.decode();

  const canvasLeft = document.createElement('canvas');
  canvasLeft.width = width / 2;
  canvasLeft.height = height;
  const contextLeft = canvasLeft.getContext('2d');
  contextLeft.drawImage(image, 0, 0, width, height);
  let blobLeft = await new Promise(resolve => canvasLeft.toBlob(resolve));
  blobLeft = await new File([blobLeft], `blobLeft.jpg`, {type:'image/jpg'});
  const imageLeft = new Image();
  imageLeft.id = "left";
  imageLeft.src = URL.createObjectURL(blobLeft);

  const canvasRight = document.createElement('canvas');
  canvasRight.width = width / 2;
  canvasRight.height = height;
  let contextRight = canvasRight.getContext('2d');
  contextRight.drawImage(image, width / 2, 0, width / 2, height, 0, 0, width / 2, height);
  let blobRight = await new Promise(resolve => canvasRight.toBlob(resolve,'image/jpg', 1));
  blobRight = await new File([blobRight], `blobRight.jpg`, {type:'image/jpg'});

  return [
    blobLeft,
    blobRight
  ]
};
const cropImageByArea = async (blob, {width, height, x, y}) => {
  const image = new Image();
  image.src = URL.createObjectURL(blob);
  await image.decode();

  const canvasOriginal = document.createElement('canvas');
  const contextOriginal = canvasOriginal.getContext('2d');
  contextOriginal.drawImage(image, 0, 0, width, height);
  const blobOriginal = await new Promise(resolve => canvasOriginal.toBlob(resolve));
  const imageOriginal = new Image();
  imageOriginal.src = URL.createObjectURL(blobOriginal);

  const canvasRight = document.createElement('canvas');
  canvasRight.width = width;
  canvasRight.height = height;
  let contextRight = canvasRight.getContext('2d');
  contextRight.drawImage(image, x, y, width, height, 0, 0, width, height);
  let blobRight = await new Promise(resolve => canvasRight.toBlob(resolve,'image/jpg', 1));
  blobRight = await new File([blobRight], `blobRight.jpg`, {type:'image/jpg'});

  return blobRight
};
const getOriginalSizeImage = async (blob) => {
  const imageFile = new Image();
  imageFile.src = URL.createObjectURL(blob);
  await imageFile.decode();
  return {
    width: imageFile.naturalWidth,
    height: imageFile.naturalHeight
  }
}
const getImageBlobFromOrder = async (imageInfo) => {
  const actionUrl = [`/calculation/${ imageInfo?.calculationId }/${ imageInfo.id }`].join("");
  const resBuffer = await agent.get(actionUrl).then((res) => {
    return res.data?.data
  });
  const buffer = new Uint8Array(resBuffer).buffer;
  return new Blob([buffer])
}

const checkImageasdasdasdasd = (position, blog) => {
  const body = document.createElement('div');
  body.style.position = "relative";

  const dot = document.createElement("div");
  dot.style.width = "10px";
  dot.style.height = "10px";
  dot.style.borderRadius = "100%";
  dot.style.backgroundColor = "black";
  dot.style.position = "absolute";
  dot.style.top = `${position.x}px`;
  dot.style.left = `${position.y}px`;

  const image = document.createElement("img");
  image.src = URL.createObjectURL(blog);

  body.appendChild(dot)
  body.appendChild(image)
  document.body.appendChild(body)
}

export {
  splitImage,
  snapshotVideo,
  cropImageByArea,
  getOriginalSizeImage,
  getImageBlobFromOrder,

  checkImageasdasdasdasd
}
