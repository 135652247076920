import React, {Component} from "react";
import {
  Box,
  Grid,
  Button,
  Container,
  Typography,
  Backdrop,
  CircularProgress
} from "@mui/material";
import {
  Notification,
  notificationTypes
} from "../../common/Notification";
import {
  PatientInfo as PatientInfoComponent,
  ListMeasurements as ListMeasurementsComponent
} from "./components";
import {
  palette
} from "../../theme/common";
import agent from "../../agent/agent";
import moment from "moment";
import allTranslations from "../../locales/allTranslations";
import {idFormat} from "../../helpers/formaters";
import ReactToPrint from 'react-to-print';

class OrderView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: {},
      paramsOfEyeglassFrame: {},
      orderId: props?.match?.params?.id,
      calculationId: null,
      isBackdrop: false
    };
    // this.refContent = React.createRef();
  }

  componentDidMount = async () => {
    await this.getOrder();
  }

  getOrder = async () => {
    const order = await agent.get(`/orders/${ this.state.orderId }`).then((res) => {
      return res.data?.order
    }).catch(() => {
      return null
    })
    if (!order) {
      Notification({
        message: allTranslations('Заказ не найден'),
        type: notificationTypes.error
      })
      return
    }

    await this.setState({
      order,
      calculationId: order?.calculations?.[0]?.id || null
    })

    const paramsOfEyeglassFrame = await agent.patch(`/calculation/${order?.calculations?.[0]?.id}/get-params-of-eyeglass-frame`).then((res) => {
      return res.data?.paramsOfEyeglassFrame || null
    }).catch(() => {
      return null
    })

    await this.setState({
      paramsOfEyeglassFrame
    })

    if ((this.props?.location?.search || '').includes('open-order-edit')) {
      await this.editOrder(order);
    }
  }
  editOrder = async (localOrder) => {
    const {
      order,
      orderId
    } = this.state;

    // Логика получения информации про поциента
    const patientId = order?.patientId || localOrder?.patientId;
    const patient = await agent.get(`/patients/${ patientId }`).then((res) => {
      return res.data?.patient || null
    }).catch(() => {
      return null
    });
    this.props.setPatient(patient);
    localStorage.setItem('patient_id', patientId);

    this.props.history.push(`/?order=${ orderId }`);
  }
  printOrder = async () => {


    // const divToPrint = document.getElementById('page-print');
    // const newWin = window.open("");
    // newWin.document.write(divToPrint.outerHTML);
    // newWin.print();
    // newWin.close();
  }

  sendTo1c = async () => {
    this.setState({ isBackdrop: true });
    const res = await agent.post(`/calculation/${this.state.calculationId}/send-to-1c`, {
      calculationId: this.state.calculationId
    }).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: true}
    });
    if (res.error || !res) {
      this.setState({ isBackdrop: false });
      Notification({
        message: "Ошибка отправки заказа в 1C",
        type: notificationTypes.error
      })
      return
    }
    this.setState({ isBackdrop: false });
    Notification({
      message: "Заказ успешно отправлен в 1C",
      type: notificationTypes.success
    })
  }

  render () {
    const {
      order,
      isBackdrop,
      calculationId,
      paramsOfEyeglassFrame
    } = this.state;

    return (
      <Container id="page-print" maxWidth="lg">
        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid item>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item>
                <Typography variant="h1" color={palette.primary.main}>
                  {allTranslations('Заказ')} №{idFormat(order.id, '#####')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography color="#6F6F6F" fontSize="16px">
                  {allTranslations('Дата заполнения')}: <span style={{color: "#000000", fontWeight: "500"}}>{moment(order.createdAt).format("DD.MM.YYYY")}</span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button size="small" variant="contained" onClick={this.editOrder}>
              Редактировать заказ
            </Button>
          </Grid>
        </Grid>
        <Box mt="50px"/>

        <Box ref={(el) => (this.refContent = el)}>
          <PatientInfoComponent patient={order?.patient || {}}/>
          <Box mt="50px"/>
          <ListMeasurementsComponent
            data={paramsOfEyeglassFrame}
            calculationId={calculationId}
            onPrint={this.printOrder}
          />
        </Box>
        <Box mt={2}/>
        <Grid container spacing={1}>
          <Grid item>
            <ReactToPrint
              trigger={() => (<Button component="a" href="#" variant="contained" size="small">Отправить на печать</Button>)}
              content={() => this.refContent}
            />
          </Grid>
          {Boolean(!!calculationId && order?.externalId) && (
            <Grid item>
              <Button variant="contained" size="small" onClick={this.sendTo1c}>
                Отправить в 1C
              </Button>
            </Grid>
          )}
        </Grid>

        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>
      </Container>
    );
  }
}

export default OrderView
