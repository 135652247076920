import {palette} from "../common";

export default {
    styleOverrides: {

        root: {
            borderRadius: 15,
            boxShadow: "none",
          padding: "15px 20px",

            fontWeight: "600",
            fontSize: 18,
            lineHeight: "22px",
            textAlign: "center",
            textTransform: "initial",


            "&.Mui-disabled": {
                backgroundColor: "#8E8E8E",
                color: "#FFFFFF"
            }
        },

        containedSecondary: {
            color: "white"
        },
        containedError: {
            color: "white"
        },
        containedSuccess: {
            color: "white"
        },

        colorInherit: {
            backgroundColor: "#F7F7F7"
        },

        outlined: {
            borderColor: palette.primary.main,
            color: palette.primary.main
        },
    }
}
