import React from "react";
import {
  TextField,
  Autocomplete, FormHelperText
} from "@mui/material";
import agent from "../../agent/agent";
import allTranslations from "../../locales/allTranslations";

class AutocompletePartner extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      isLoad: true
    }
  }

  getOptions = async () => {
    if ((this.state.options || []).length > 0) {
      return
    }

    const options = await agent.get('/partners?limit=999&page=1').then((res) => {
      return res?.data?.partners
    }).catch(() => {
      return []
    });
    this.setState({
      options
    });
  }

  onChange = (event, value) => {
    this.props.onChange({
      target: {
        name: this.props.name,
        value: value?.id || null
      }
    });
  }

  filterOptions = (options, { inputValue }) => {
    const search = (inputValue || "").toLowerCase();
    return options.filter((t) => {
      const legalName = (t?.legalName || "").toLowerCase();
      const contactEmail = (t?.contactEmail || "").toLowerCase();
      const contactFirstName = (t?.contactFirstName || "").toLowerCase();
      const contactLastName = (t?.contactLastName || "").toLowerCase();
      const contactPatronymic = (t?.contactPatronymic || "").toLowerCase();
      const contactPhone = (t?.contactPhone || "").toLowerCase();

      return Boolean(
        legalName.indexOf(search) > -1 ||
        contactEmail.indexOf(search) > -1 ||
        contactFirstName.indexOf(search) > -1 ||
        contactLastName.indexOf(search) > -1 ||
        contactPatronymic.indexOf(search) > -1 ||
        contactPhone.indexOf(search) > -1
      )
    })
  }
  getOptionLabel = (option) => {
    if (typeof option !== "object") {
      option = (this.state.options || []).find((t) => t.id === option) || {};
    }
    const fullName = [option?.contactLastName, option.contactFirstName, option.contactPatronymic].filter((t) => !!t).join(" ");
    return `${option.legalName} (${fullName})`
  }

  render () {
    const {
      options
    } = this.state;
    const {
      value,
      ...otherProps
    } = this.props;

    return (
      <form autoComplete="off">
        <Autocomplete
          value={value || null}
          options={options}
          fullWidth={true}
          autoComplete={true}
          disableClearable={true}
          filterSelectedOptions={true}
          name={Math.random().toString(36).slice(2, 7)}
          renderInput={(params) => <TextField {...params} {...otherProps} label={allTranslations('Партнер')}/>}
          filterOptions={this.filterOptions}
          getOptionLabel={this.getOptionLabel}
          onOpen={this.getOptions}
          onChange={this.onChange}
        />
      </form>
    )
  }
}

export default AutocompletePartner
