import React, {Component} from "react";
import {
  Box,
  Backdrop,
  Container,
  CircularProgress
} from "@mui/material";
import {
  PatientInfo,
  OrdersInfo
} from "./components";
import {
  Notification,
  notificationTypes
} from "../../common/Notification";
import {
  DialogConfirmation
} from "../../components";
import agent from "../../agent/agent";
import moment from "moment";
import allTranslations from "../../locales/allTranslations";

class PatientView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      patient: {},
      orders: [],

      patientId: props?.match?.params?.id,

      isBackdrop: false
    };
    this.refDialogConfirmation = React.createRef();
  }

  componentDidMount = async () => {
    await this.getPatient();
    await this.getOrders();
  }

  getPatient = async () => {
    const res = await agent.get(`/patients/${ this.state.patientId }`).then((res) => {
      return res.data?.patient || null
    }).catch(() => {
      return null
    });
    if (!res) {
      Notification({
        type: notificationTypes.error,
        message: allTranslations('Пользователь не найден')
      });
      this.props.history.push('/patients');
      return
    }

    this.setState({
      patient: res
    });
  }

  getOrders = async () => {
    const orders = await agent.get(`/orders?patientId=${this.state.patientId}&limit=999`).then((res) => {
      return res.data?.orders || []
    }).catch(() => {
      return []
    });
    this.setState({
      orders
    })
  }
  removeOrder = async (order, confirm) => {
    if (!confirm) {
      this.refDialogConfirmation.current.open({
        message: `${allTranslations('Вы действительно хотите удалить заказ')} №${order.id} ${moment(order.createdAt).format("DD.MM.YYYY")}`,
        onSubmit: this.removeOrder.bind(this, order, true)
      })
      return
    }

    this.setState({isBackdrop: true});
    const isSuccess = await agent.delete(`/orders/${order.id}`).then((res) => {
      return true
    }).catch(() => {
      return false
    });
    if (!isSuccess) {
      this.setState({isBackdrop: false});
      Notification({
        type: notificationTypes.error,
        message: allTranslations('Ошибка сервера')
      })
      return
    }
    Notification({
      type: notificationTypes.success,
      message: allTranslations('Заказ успешно удален')
    });
    await this.getOrders();
    this.setState({isBackdrop: false});
  }

  render () {
    const {
      patient,
      orders,
      isBackdrop
    } = this.state;

    return (
      <Container maxWidth="lg">
        <PatientInfo patient={patient}/>
        <Box mt="50px"/>
        <OrdersInfo
          orders={orders}
          onRemove={this.removeOrder}
        />

        <DialogConfirmation ref={this.refDialogConfirmation}/>

        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>
      </Container>
    );
  }
}

export default PatientView
