import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,

  Grid,
  Tooltip,
  Skeleton,
  Pagination,
  IconButton,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon
} from "@mui/icons-material";
import moment from "moment";
import allTranslations from "../../../../locales/allTranslations";
import {idFormat} from "../../../../helpers/formaters";

const TableCustom = (props) => {
  const {
    data,
    filter,
    pagination,
    isLoad,

    onEdit,
    onRemove,
    onChangePage
  } = props;

  const handleChangePage = (event, page) => {
    let newFilter = {...filter}
    newFilter.page = page;
    onChangePage(newFilter);
  }

  return (
    <>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>{allTranslations('Логин')}</TableCell>
            <TableCell align="right">{allTranslations('Дата обновления')}</TableCell>
            <TableCell align="right">{allTranslations('Дата создания')}</TableCell>
            <TableCell align="right"/>
          </TableRow>
        </TableHead>
        <TableBody>
          {Boolean(isLoad) && <TableRowLoading/>}
          {Boolean(!isLoad) && data.map((item, index) => <TableRowElement key={`TableRowElement-${index}`} item={item} onEdit={onEdit} onRemove={onRemove}/>)}
        </TableBody>
      </Table>

      <Grid container mt={2} justifyContent="flex-end">
        <Grid item>
          <Pagination
            page={filter.page}
            count={pagination.total}
            onChange={handleChangePage}
          />
        </Grid>
      </Grid>

    </>
  )
}
const TableRowElement = React.memo((props) => {
  const {
    item,
    onEdit,
    onRemove
  } = props;

  return (
    <TableRow>
      <TableCell>
        {idFormat(item.id)}
      </TableCell>
      <TableCell>
        {item.login}
      </TableCell>
      <TableCell align="right">
        {moment(item.updatedAt).format("DD.MM.YYYY HH:mm:ss")}
      </TableCell>
      <TableCell align="right">
        {moment(item.createdAt).format("DD.MM.YYYY HH:mm:ss")}
      </TableCell>
      <TableCell align="right">
        <Grid container justifyContent="flex-end" spacing={1}>
          <Grid item>
            <Tooltip title={allTranslations('Изменить')} arrow>
              <IconButton color="primary" onClick={onEdit.bind(this, item, false)}>
                <EditIcon/>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title={allTranslations('Удалить')} arrow>
              <IconButton color="error" onClick={onRemove.bind(this, item.id, false)}>
                <DeleteIcon/>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )
});
const TableRowLoading = React.memo(() => {
  return (
    <>
      {new Array(10).fill(1).map((t, i) => (
        <TableRow key={`loading-${i}`}>
          <TableCell><Skeleton/></TableCell>
        </TableRow>
      ))}
    </>
  )
})

export default React.memo(TableCustom)
