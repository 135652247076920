import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,

  Box,
  Grid,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import {
  AutocompletePartner
} from "../../../../components";
import {
  Formik
} from "formik";
import * as Yup from "yup";
import allTranslations from "../../../../locales/allTranslations";

const initForm = {
  login: "",
  password: "",
  partnerId: ""
};

class DialogForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      form: {...initForm},
      onSubmit: null,
      isOpen: false,
      isEdit: false,
    };
    this.refFormik = React.createRef();
  }

  open = ({ form, onSubmit }) => {
    this.setState({
      form: form || {...initForm},
      isEdit: Boolean(form),
      isOpen: true,
      onSubmit
    })
  }
  close = () => {
    this.setState({
      form: {...initForm},
      onSubmit: null,
      isOpen: false,
      isEdit: false,
    })
  }

  onChange = ({ target }) => {
    if (!this.refFormik.current) {
      return
    }
    const { name, value } = target;
    let values = this.refFormik.current.values;
    values[name] = value;
    this.refFormik.current.setValues(values);
  }

  submit = (form) => {
    this.state.onSubmit(form, true);
  }
  checkValidation = () => {
    this.refFormik.current.handleSubmit();
  }

  render () {
    const {
      form,
      isEdit,
      isOpen
    } = this.state;

    return (
      <Dialog
        fullWidth
        maxWidth="md"
        open={isOpen}
        onClose={this.close}
      >
        <DialogTitle>
          <Typography variant="h3">
            {allTranslations(isEdit ? 'Редактирование' : 'Создание')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Formik
            innerRef={this.refFormik}
            initialValues={form}
            validationSchema={Boolean(isEdit) ? validationSchemaEdit : validationSchemaCreate}
            onSubmit={this.submit}
          >{(props) => {
            const {
              values,
              errors,
              touched,
            } = props;

            return (
              <Grid container pt={2} columnSpacing={2} rowSpacing={3}>
                {Boolean(!isEdit) && (
                  <Grid item xs={12}>
                    <AutocompletePartner
                      value={values.partnerId}
                      name="partnerId"
                      error={Boolean(touched.partnerId && errors.partnerId)}
                      helperText={touched.partnerId && errors.partnerId}
                      onChange={this.onChange}
                    />
                  </Grid>
                )}
                {Boolean(isEdit) && (
                  <Grid item xs={12}>
                    <TextField
                      value={values.login}
                      name="login"
                      label={allTranslations('Логин')}
                      placeholder="..."
                      fullWidth
                      error={Boolean(touched.login && errors.login)}
                      helperText={touched.login && errors.login}
                      onChange={this.onChange}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    value={values.password}
                    name="password"
                    label={allTranslations('Пароль')}
                    type="password"
                    placeholder="..."
                    fullWidth
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    onChange={this.onChange}
                  />
                </Grid>
              </Grid>
            )
          }}</Formik>
        </DialogContent>
        <DialogActions>
          <Grid px={2} container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button variant="outlined" onClick={this.close}>
                {allTranslations('Отменить')}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={this.checkValidation}>
                {allTranslations(isEdit ? 'Изменить' : 'Создать')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
}

const validationSchemaEdit = Yup.object().shape({
  login: Yup.string().required(allTranslations('Заполните поле')),
  password: Yup.string().min(8, allTranslations('Минимум 8 символов'))
});
const validationSchemaCreate = Yup.object().shape({
  password: Yup.string().min(8, allTranslations('Минимум 8 символов')).required(allTranslations('Заполните поле')),
  partnerId: Yup.string().required(allTranslations('Заполните поле'))
});

export default DialogForm
