import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_HOST_API,
  headers: {
    'Content-Type': 'application/json',
    'x-auth-token': localStorage.getItem('token')
  }
})

export default axiosInstance
