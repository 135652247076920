import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,

  Box,
  Grid,
  Button,
  TextField,
  Typography
} from "@mui/material";
import {
  Formik
} from "formik";
import * as Yup from "yup";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {IMaskInput} from "react-imask";
import allTranslations from "../../../../locales/allTranslations";

const initForm = {
  firstName: "",
  lastName: "",
  patronymic: "",
  phone: "",
  email: "",
  birthdate: null,
};

class DialogForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isEdit: false,

      onSubmit: null
    };
    this.refFormik = React.createRef();
  }

  open = ({ form, onSubmit }) => {
    const newForm = form || {...initForm};
    this.setState({
      form: newForm,
      onSubmit,
      isOpen: true,
      isEdit: Boolean(form)
    })
  }
  close = () => {
    this.setState({
      isOpen: false,
      isEdit: false,
      onSubmit: null
    })
  }

  changeForm = ({ target }) => {
    if (!this.refFormik.current) {
      return
    }
    const { name, value } = target;
    let values = this.refFormik.current.values;
    values[name] = value;
    this.refFormik.current.setValues(values);
  }
  changeFormDate = (name, value) => {
    this.changeForm({
      target: {
        name,
        value
      }
    })
  }

  submit = (form) => {
    this.state.onSubmit(form, true);
  }
  checkValidation = () => {
    this.refFormik.current.handleSubmit();
  }

  render () {
    const {
      form,
      isOpen,
      isEdit
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="md"
        onClose={this.close}
      >
        <DialogTitle>
          <Typography variant="h3">
            { allTranslations(isEdit ? 'Редактирование' : 'Создание') }
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Formik
            innerRef={this.refFormik}
            initialValues={form}
            validationSchema={validationSchema}
            onSubmit={this.submit}
          >{(props) => {
            const {
              values,
              errors,
              touched,
            } = props;

            return (
              <Grid container rowSpacing={3} columnSpacing={2} mt={1}>
                <Grid item xs={6}>
                  <TextField
                    value={values.lastName}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                    label={allTranslations('Фамилия')}
                    name="lastName"
                    placeholder="..."
                    fullWidth
                    onChange={this.changeForm}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={values.firstName}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    label={allTranslations('Имя')}
                    name="firstName"
                    placeholder="..."
                    fullWidth
                    onChange={this.changeForm}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={values.patronymic}
                    error={Boolean(touched.patronymic && errors.patronymic)}
                    helperText={touched.patronymic && errors.patronymic}
                    label={allTranslations('Отчество')}
                    name="patronymic"
                    placeholder="..."
                    fullWidth
                    onChange={this.changeForm}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={values.phone}
                    error={Boolean(touched.phone && errors.phone)}
                    helperText={touched.phone && errors.phone}
                    label={allTranslations('Телефон')}
                    name="phone"
                    placeholder="..."
                    fullWidth
                    InputProps={{
                      inputComponent: TextMaskCustom
                    }}
                    onChange={this.changeForm}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    label="Email"
                    name="email"
                    placeholder="..."
                    fullWidth
                    onChange={this.changeForm}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    value={values.birthdate}
                    error={Boolean(touched.birthdate && errors.birthdate)}
                    helperText={touched.birthdate && errors.birthdate}
                    slotProps={{
                      textField: {
                        error: Boolean(touched.birthdate && errors.birthdate),
                        helperText: touched.birthdate && errors.birthdate,
                      },
                    }}
                    name="birthdate"
                    label={allTranslations('Дата рождения')}
                    placeholder="..."
                    fullWidth
                    minDate={new Date().setFullYear(1900, 1, 1)}
                    maxDate={new Date()}
                    onChange={this.changeFormDate.bind(this, 'birthdate')}
                    sx={{width: "100%"}}
                  />
                </Grid>
              </Grid>
            )
          }}</Formik>
        </DialogContent>
        <DialogActions>
          <Grid px={2} container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Button variant="outlined" onClick={this.close}>
                {allTranslations('Закрыть')}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={this.checkValidation}>
                {allTranslations(isEdit ? 'Редактировать' : 'Создать')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
}
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;

  const handleOnAccept = (value) => {
    onChange({ target: { name: props.name, value } })
  }

  return (
    <IMaskInput
      {...other}
      mask="+{7}(000)000-00-00"
      inputRef={ref}
      onAccept={handleOnAccept}
    />
  );
});

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(allTranslations('Заполните поле')),
  lastName: Yup.string().required(allTranslations('Заполните поле')),
  patronymic: Yup.string().required(allTranslations('Заполните поле')),
  phone: Yup.string().length(16, allTranslations('Некорректные данные')).required(allTranslations('Заполните поле')),
  email: Yup.string().email(allTranslations('Некорректные данные')).required(allTranslations('Заполните поле')),
  birthdate: Yup
    .date()
    .min('1900-01-01', allTranslations('Дата рождения не может быть меньше 01.01.1900'))
    .max(new Date(), allTranslations('Дата рождения не может быть больше сегодня'))
    .required(allTranslations('Заполните поле')),
});

export default DialogForm
