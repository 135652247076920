import React from "react";
import {
  Box,
  Grid,
  Button,
  Typography,

  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  withStyles,
  makeStyles
} from "@mui/styles";
import getAllCameras from "../../helpers/getAllCameras";
import {Notification, notificationTypes} from "../../common/Notification";
import {snapshotVideo} from "../../helpers/snapshotVideo";
import {getPositionGyroscope} from "../../helpers/calibration";
import allTranslations from "../../locales/allTranslations";

class StereoCameraShot extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      listCamera: [],
      selectedCamera: null,
      previewPicture: null,
      isLoadedVideo: false
    };
    this.refVideo = React.createRef();
  }

  componentDidMount = async () => {
    await this.initListCamera();
  }

  initListCamera = async () => {
    const listCamera = await getAllCameras();
    this.setState({
      listCamera,
      selectedCamera: listCamera?.[0]?.deviceId || null
    }, async () => {
      await this.startCamera();
    });

    this.props.onChangeSelectedCamera(listCamera?.[0]?.deviceId || null)
  }

  // Запуск и замены камеры
  startCamera = async () => {
    const {selectedCamera} = this.state;
    if (!selectedCamera) {
      Notification({
        type: notificationTypes.error,
        message: allTranslations('Мы не обнаружили ни одной камеры')
      })
      return
    }

    const device = await navigator.mediaDevices.getUserMedia({
      video: {
        deviceId: selectedCamera,
        height: 1520,
        width: 3040
      }
    });

    this.refVideo.current.pause();
    this.refVideo.current.srcObject = device;
    this.refVideo.current.play();
  }
  changeCamera = async (selectedCamera) => {
    const stream = this.refVideo.current.srcObject;
    const tracks = stream.getTracks();
    tracks.forEach(function(track) {
      track.stop();
    });
    this.refVideo.current.srcObject = null;

    this.setState({ selectedCamera }, async () => {
      await this.startCamera();
    });
  }

  // Сгенерировать снимок
  generateSnapshot = async () => {
    const imageSizeWidth = 3040;
    const imageSizeHeight = 1520;
    const video = document.getElementById('video-content');
    const gyroscope = Boolean(window.location.hostname === 'localhost') ? {
      x: 1,
      y: 1,
      z: 1,
      usbProductId: 'test'
    } : await getPositionGyroscope()
    const blob = await snapshotVideo(video, {
      width: imageSizeWidth,
      height: imageSizeHeight,
    });
    this.setState({
      previewPicture: blob,
      gyroscope
    });
  }
  successSnapshot = () => {
    this.props.onChange(this.state.previewPicture, this.state.gyroscope);
    this.setState({
      previewPicture: null
    })
  }
  cancelSnapshot = () => {
    this.setState({
      previewPicture: null
    })
  }

  render () {
    const {
      classes
    } = this.props;
    const {
      isLoadedVideo,
      selectedCamera,
      previewPicture
    } = this.state;

    return (
      <>
        <Box className={classes.cameraBox}>
          <video
            autoplay
            id="video-content"
            ref={this.refVideo}
            onLoadedData={() => this.setState({isLoadedVideo: true})}
          />
        </Box>
        <Grid container justifyContent="center" alignItems="center" mt={2}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              disabled={Boolean(!isLoadedVideo)}
              onClick={this.generateSnapshot}
            >
              {allTranslations('Сделать снимок')}
            </Button>
          </Grid>
        </Grid>


        <DialogConfirmSnapshot
          preview={previewPicture}
          onSuccess={this.successSnapshot}
          onCancel={this.cancelSnapshot}
        />
      </>
    )
  }
}
const DialogConfirmSnapshot = React.memo((props) => {
  const {
    preview,
    onCancel,
    onSuccess
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={Boolean(preview)}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>
        <Typography variant="h3">
          {allTranslations('Подтверждение снимка')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.dialogImageBox}>
          <img src={Boolean(preview) && URL.createObjectURL(preview)}/>
        </Box>
        <Box mt={2}>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button variant="outlined" onClick={onCancel}>
                {allTranslations('Повторить попытку')}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={onSuccess}>
                {allTranslations('Применить')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  )
})

const styles = {
  cameraBox: {
    width: "100%",
    height: 480,
    borderRadius: 15,
    border: '1px solid #A72681',
    backgroundColor: "white",
    position: "relative",
    overflow: "hidden",

    "& video": {
      width: "100%",
      height: "100%"
    }
  }
};
StereoCameraShot = withStyles(styles)(StereoCameraShot);

const useStyles = makeStyles(() => ({
  dialogImageBox: {
    "& img": {
      maxWidth: "100%",
      maxHeight: "100%",
    }
  }
}));

export default StereoCameraShot
