import React from "react";
import {
  Box,
  Grid,
  Button,
  Typography,

  Dialog,
  DialogTitle,
  DialogContent
} from "@mui/material";
import {withStyles} from "@mui/styles";
import zim from "zimjs";
import {
  TransformWrapper,
  TransformComponent,
} from "react-zoom-pan-pinch";
import clsx from "clsx";
import {getOriginalSizeImage} from "../../helpers/snapshotVideo";
import agent from "../../agent/agent";
import allTranslations from "../../locales/allTranslations";
import {ContrastSlider} from "../index";
// import RndComponent from "../../components/ContourRefinement/RndComponent";

class ContourRefinement extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      popUpZoom: null
    }
  }

  changeForm = (camera, position, values) => {
    let form = {...this.props.form};
    form['coords'][camera][position] = values;
    this.props.onChange(form);
  }
  submitForm = async () => {
    const {form, fullForm} = this.props;
    const scalePositionDot = async (clientSize, coords, blob, scaleOriginal) => {
      const originalSize = await getOriginalSizeImage(blob);
      const percentScaleWidth = originalSize?.width / clientSize?.width;
      const percentScaleHeight = originalSize?.height / clientSize?.height;
      return coords.map((t) => {
        return {
          x: Math.ceil(t.x * percentScaleWidth + scaleOriginal?.x),
          y: Math.ceil(t.y * percentScaleHeight + scaleOriginal?.y),
        }
      })
    }

    const positionLL = await scalePositionDot(document.getElementById('blobLeftLeft').getBoundingClientRect(), form?.coords?.cam_0?.cont_l, fullForm?.stage2?.blobLeftLeft, fullForm?.stage2?.scaleInfoCam0?.cont_l);
    const positionLR = await scalePositionDot(document.getElementById('blobLeftRight').getBoundingClientRect(), form?.coords?.cam_0?.cont_r, fullForm?.stage2?.blobLeftRight, fullForm?.stage2?.scaleInfoCam0?.cont_r);
    const positionRL = await scalePositionDot(document.getElementById('blobRightLeft').getBoundingClientRect(), form?.coords?.cam_1?.cont_l, fullForm?.stage2?.blobRightLeft, fullForm?.stage2?.scaleInfoCam1?.cont_l);
    const positionRR = await scalePositionDot(document.getElementById('blobRightRight').getBoundingClientRect(), form?.coords?.cam_1?.cont_r, fullForm?.stage2?.blobRightRight, fullForm?.stage2?.scaleInfoCam1?.cont_r);

    this.props.onSubmit({
      "contoursOfFrames": {
        "cam_0": {
          "cont_l": positionLL,
          "cont_r": positionLR
        },
        "cam_1": {
          "cont_l": positionRL,
          "cont_r": positionRR
        }
      }
    })
  }

  openPopUpZoom = (stageInfo, lineCounter, eyepiecesAreaToBack, popUpZoom) => {
    const originalSize = document.getElementById(popUpZoom.imageId).getBoundingClientRect();
    this.setState({
      popUpZoom: {
        ...popUpZoom,
        stageInfo,
        lineCounter,
        originalSize,
        eyepiecesAreaToBack
      }
    });
  }
  closePopUpZoom = () => {
    this.setState({
      popUpZoom: null
    })
  }
  submitPopUpZoom = (value, event) => {
    event(value);
    this.closePopUpZoom();
  }

  _getImagesClientSize = async () => {
    return {
      bll: document.getElementById('blobLeftLeft').getBoundingClientRect(),
      blr: document.getElementById('blobLeftRight').getBoundingClientRect(),
      brl: document.getElementById('blobRightLeft').getBoundingClientRect(),
      brr: document.getElementById('blobRightRight').getBoundingClientRect(),
    }
  }

  render() {
    const {
      form,
      fullForm,
      lineCounters,
      blobLeftLeft,
      blobLeftRight,
      blobRightLeft,
      blobRightRight,
      eyepiecesAreaToBack,

      classes
    } = this.props;
    const {
      popUpZoom
    } = this.state;

    if (Boolean(popUpZoom)) {
      return (
        <PopUpZoomElement
          blob={popUpZoom.blob}
          value={popUpZoom.value}
          center={popUpZoom.center}
          originalSize={popUpZoom.originalSize}
          classes={classes}
          stageInfo={popUpZoom.stageInfo}
          eventOnChange={popUpZoom.onChange}
          lineCounter={popUpZoom.lineCounter}
          eyepiecesAreaToBack={popUpZoom.eyepiecesAreaToBack}
          onClose={this.closePopUpZoom}
          onSubmit={this.submitPopUpZoom}
        />
      )
    }
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{color: "black"}}>{allTranslations('Камера')} 0</Typography>
          </Grid>
          <Grid item xs={6}>
            <GlassSection
              isVisibler={Boolean((form?.coords?.cam_0?.cont_r || []).length > 0)}
              values={form?.coords?.cam_0?.cont_r || []}
              image={blobLeftRight}
              imageId="blobLeftRight"
              classes={classes}
              center={fullForm?.stage3?.cam0?.eye_r}
              onChange={this.changeForm.bind(this, 'cam_0', 'cont_r')}
              onClick={this.openPopUpZoom.bind(this, fullForm.stage2?.cam_0?.rect_r, lineCounters?.cam_0?.cont_r, eyepiecesAreaToBack?.cam_0?.cont_r)}
            />
          </Grid>
          <Grid item xs={6}>
            <GlassSection
              isVisibler={Boolean((form?.coords?.cam_0?.cont_l || []).length > 0)}
              values={form?.coords?.cam_0?.cont_l || []}
              image={blobLeftLeft}
              imageId="blobLeftLeft"
              classes={classes}
              center={fullForm?.stage3?.cam0?.eye_l}
              onChange={this.changeForm.bind(this, 'cam_0', 'cont_l')}
              onClick={this.openPopUpZoom.bind(this, fullForm.stage2?.cam_0?.rect_l, lineCounters?.cam_0?.cont_l, eyepiecesAreaToBack?.cam_0?.cont_l)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{color: "black"}}>{allTranslations('Камера')} 1</Typography>
          </Grid>
          <Grid item xs={6}>
            <GlassSection
              isVisibler={Boolean((form?.coords?.cam_1?.cont_r || []).length > 0)}
              values={form?.coords?.cam_1?.cont_r || []}
              image={blobRightRight}
              imageId="blobRightRight"
              classes={classes}
              center={fullForm?.stage3?.cam1?.eye_r}
              onChange={this.changeForm.bind(this, 'cam_1', 'cont_r')}
              onClick={this.openPopUpZoom.bind(this, fullForm.stage2?.cam_1?.rect_r, lineCounters?.cam_1?.cont_r, eyepiecesAreaToBack?.cam_1?.cont_r)}
            />
          </Grid>
          <Grid item xs={6}>
            <GlassSection
              isVisibler={Boolean((form?.coords?.cam_1?.cont_l || []).length > 0)}
              values={form?.coords?.cam_1?.cont_l || []}
              image={blobRightLeft}
              imageId="blobRightLeft"
              classes={classes}
              center={fullForm?.stage3?.cam1?.eye_l}
              onChange={this.changeForm.bind(this, 'cam_1', 'cont_l')}
              onClick={this.openPopUpZoom.bind(this, fullForm.stage2?.cam_1?.rect_l, lineCounters?.cam_1?.cont_l, eyepiecesAreaToBack?.cam_1?.cont_l)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Button variant="contained" fullWidth onClick={this.submitForm}>
            {allTranslations('Зафиксировать')}
          </Button>
        </Grid>
      </Grid>
    )
  }
}
class PopUpZoomElement extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: [],
      linesCounter: [],
      center: {x: 0, y: 0},

      imageUrl: "",
      imageUrlMain: "",
      orientation: "height",

      zoom: 1,
      activePointIndex: -1,

      isReady: false,
      isDisabledPanning: false
    };
    this.refImage = React.createRef();
    this.refContent = React.createRef();
    this.refRndComponent = React.createRef();
    this.refLinesComponents = React.createRef();
    this.refTransformWrapper = React.createRef();
  }

  componentDidMount = () => {
    this.createImageMain();
    this.initialProportion();
  }
  componentWillUnmount = () => {
    window.removeEventListener("keydown", this.eventKeyboard)
  }

  eventKeyboard = (event) => {
    const { center, activePointIndex, value } = this.state;

    if (event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      this.checkLinePoints();
      return false
    }
    if (event.keyCode === 113) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();

      this.prevPoint();
      return false
    }
    if (event.keyCode === 114) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();

      this.nextPoint();
      return false
    }

    if (activePointIndex === -1) {
      return
    }
    if (![37,38,39,40].includes(event.keyCode)) {
      return;
    }

    const calcNextPoint = (p1, p2, delta) => {
      var a =  delta*(p2[0] - p1[0]) + p1[0];
      var b =  delta*(p2[1] - p1[1]) + p1[1];
      return {x: a, y: b}
    }
    const getNewPositionDot = (initMove, point) => {
      const firstPoint = calcNextPoint([point.x, point.y], [center.x, center.y], -1.01);

      let coordinates = [];
      for (var i=0; i<=512; i++)  {
        var delta = i/512;
        var x =  delta*(firstPoint.x - center.x) + center.x;
        var y =  delta*(firstPoint.y - center.y) + center.y;
        coordinates.push({x, y})
      }
      function distance(p) {
        return Math.sqrt(Math.pow((point.x + initMove.x) - p.x, 6) + Math.pow((point.y + initMove.y) - p.y, 6))
      }
      return coordinates.reduce((a, b) => distance(a) < distance(b) ? a : b);
    }

    let activeValue = value[activePointIndex];
    if (event.keyCode === 37) {
      const value = getNewPositionDot({x: -1, y: 0}, activeValue);
      const moveX = value.x - activeValue.x;
      const moveY = value.y - activeValue.y;
      this.refRndComponent.current.updatePointPosition(activePointIndex, moveX, moveY, value);
      activeValue = value
    }
    if (event.keyCode === 38) {
      const value = getNewPositionDot({x: 0, y: -1}, activeValue);
      const moveX = value.x - activeValue.x;
      const moveY = value.y - activeValue.y;
      this.refRndComponent.current.updatePointPosition(activePointIndex, moveX, moveY, value);
      activeValue = value
    }
    if (event.keyCode === 39) {
      const value = getNewPositionDot({x: 1, y: 0}, activeValue);
      const moveX = value.x - activeValue.x;
      const moveY = value.y - activeValue.y;
      this.refRndComponent.current.updatePointPosition(activePointIndex, moveX, moveY, value);
      activeValue = value
    }
    if (event.keyCode === 40) {
      const value = getNewPositionDot({x: 0, y: 1}, activeValue);
      const moveX = value.x - activeValue.x;
      const moveY = value.y - activeValue.y;
      this.refRndComponent.current.updatePointPosition(activePointIndex, moveX, moveY, value);
      activeValue = value
    }
    let newValue = [...value];
    newValue[activePointIndex] = activeValue;
    this.setState({ value: newValue });
  }

  createImageMain = () => {
    const { blob } = this.props;
    this.setState({
      imageUrlMain: URL.createObjectURL(blob)
    })
  }

  initialProportion = () => {
    if (!this.refContent.current || !this.refImage.current) {
      setTimeout(() => {
        this.initialProportion();
      }, 500);
      return
    }

    const {
      width: imageWidth
    } = this.refImage.current.getBoundingClientRect();

    const {
      width: contentWidth
    } = this.refContent.current.getBoundingClientRect();

    if (contentWidth > imageWidth) {
      this.initialValues();
      return;
    }

    this.setState({
      orientation: "width"
    }, () => {
      this.initialValues();
    })
  }
  initialValues = () => {
    const {
      value,
      lineCounter,
      originalSize,
      eyepiecesAreaToBack
    } = this.props;
    const {
      width: originalWidth,
      height: originalHeight
    } = originalSize;
    const {
      width: clientWidth,
      height: clientHeight
    } = this.refImage.current.getBoundingClientRect();

    const xScale = originalWidth / clientWidth;
    const yScale = originalHeight / clientHeight;
    const xBackScale = eyepiecesAreaToBack.width / clientWidth;
    const yBackScale = eyepiecesAreaToBack.height / clientHeight;
    const center = {x: clientWidth / 2, y: clientHeight / 2};

    const linesCounter = [...(lineCounter || [])].map((line) => {
      return [...line].map((t) => {
        const _x = ((Number.parseFloat(t.x) / xBackScale) - Number.parseFloat(eyepiecesAreaToBack.x) / xBackScale);
        const _y = ((Number.parseFloat(t.y) / yBackScale) - Number.parseFloat(eyepiecesAreaToBack.y) / xBackScale);

        return {
          x: Number.parseFloat(_x.toFixed(0)),
          y: Number.parseFloat(_y.toFixed(0)),

          x_2: Number.parseFloat(_x.toFixed(2)),
          y_2: Number.parseFloat(_y.toFixed(2)),
        }
      })
    });

    this.setState({
      value: (value || []).map((t) => ({x: t.x / xScale, y: t.y / yScale})),
      center: center,
      linesCounter,
      // imageUrl: URL.createObjectURL(this.props.blob)
    });
    window.addEventListener("keydown", this.eventKeyboard)
  }

  prevPoint = () => {
    let { value, activePointIndex } = this.state;

    activePointIndex = activePointIndex - 1;
    if (activePointIndex < -1) {
      activePointIndex = value.length - 1
    }

    this.setState({ activePointIndex })
  }
  nextPoint = () => {
    let { value, activePointIndex } = this.state;

    activePointIndex = activePointIndex + 1;
    if (activePointIndex > value.length - 1) {
      activePointIndex = -1
    }

    this.setState({ activePointIndex })
  }
  changePoint = (activePointIndex) => {
    this.setState({ activePointIndex });
  }

  changeDot = (index, _value) => {
    let value = [...this.state.value];
    value[index] = _value;
    this.setState({ value });
  }
  changeZoom = (event) => {
    this.setState({ zoom: event?.scale || 1 });
  }
  changeReady = (val) => {
    this.setState({
      isReady: val
    })
  }
  changeContrast = (value) => {
    const elements = document.querySelectorAll('.image-contrast-settings');
    elements.forEach((element) => {
      element.style.filter = `contrast(${value+100}%)`
    });
  }
  changeDisabledPanning = (isDisabledPanning, activePointIndex) => {
    this.setState({
      isDisabledPanning,
      activePointIndex: Boolean(typeof activePointIndex === "number") ? activePointIndex : this.state.activePointIndex
    });
  }

  checkLinePoints = () => {
    const { center, value, activePointIndex } = this.state;
    const apiLines = this.refLinesComponents.current.returnActiveLine();

    const findAllPointsForLine = (point, delta) => {
      const endLine = {
        x: delta*(center.x - point.x) + point.x,
        y: delta*(center.y - point.y) + point.y,
      };

      let list = [];
      var x = center.x, y = center.y, x2 = endLine.x, y2 = endLine.y;
      var time = Math.max( Math.abs(x - x2), Math.abs(y - y2));
      for (var i=0; i<=time; i++)  {
        var _delta = i/time ;
        var _x = _delta*(x2 - x) + x;
        var _y = _delta*(y2 - y) + y;
        list.push({
          _x, _y,
          xCeil: Number.parseFloat(_x.toFixed(0)),
          yCeil: Number.parseFloat(_y.toFixed(0)),
          x2Ceil: Number.parseFloat(_x.toFixed(2)),
          y2Ceil: Number.parseFloat(_y.toFixed(2)),
        })
      }

      return list
    }
    const points = [...value].map((t, i) => {
      return {
        ...t,
        index: i,
        linePoints: findAllPointsForLine(t, -1.001)
      }
    });
    const entryPoints = points.map((point) => {
      const linePoint = point.linePoints
        .find((linePoint) => apiLines.find((t, index) => {
          const tX = t.x;
          const linePointXCeilS = linePoint.xCeil - 1;
          const linePointXCeilB = linePoint.xCeil + 1;
          const isXIncluded = Boolean(linePointXCeilS <= tX && tX <= linePointXCeilB);

          const tY = t.y;
          const linePointYCeilS = linePoint.yCeil - 1;
          const linePointYCeilB = linePoint.yCeil + 1;
          const isYIncluded = Boolean(linePointYCeilS <= tY && tY <= linePointYCeilB);

          return Boolean(isXIncluded && isYIncluded)
        }));
      return {
        ...point,
        linePoint
      }
    }).filter((t) => t.linePoint);

    let newValue = [...value];
    entryPoints.map((entryPoint, index) => {
      if (entryPoint.index >= activePointIndex) {
        const moveX = entryPoint.linePoint._x - entryPoint.x;
        const moveY = entryPoint.linePoint._y - entryPoint.y;
        this.refRndComponent.current.updatePointPosition(entryPoint.index, moveX, moveY);
        newValue[entryPoint.index] = {
          x: entryPoint.linePoint._x,
          y: entryPoint.linePoint._y,
        }
      }
    })

    this.setState({
      value: newValue,
      activePointIndex: -1
    })
  }

  submitValue = () => {
    this.refTransformWrapper.current.zoomOut(99999, 0);
    const { value, zoom } = this.state;
    const {
      elementIndex,
      originalSize,
      eventOnChange
    } = this.props;
    const {
      width: originalWidth,
      height: originalHeight
    } = originalSize;
    const {
      width: clientWidth,
      height: clientHeight
    } = this.refImage.current.getBoundingClientRect();

    const xScale = originalWidth / clientWidth;
    const yScale = originalHeight / clientHeight;

    const newValues = (value || []).map((t) => ({x: t.x * xScale, y: t.y * yScale}));
    this.props.onSubmit(newValues, eventOnChange);
  }

  render () {
    const {
      blob,
      classes,
      onClose
    } = this.props;
    const {
      value,
      center,
      imageUrl,
      imageUrlMain,
      orientation,
      linesCounter,
      activePointIndex,

      isReady,
      isDisabledPanning
    } = this.state;

    return (
      <Dialog
        open={true}
        maxWidth="lg"
        fullWidth={true}
        fullScreen={true}
      >
        <DialogTitle>
          <Typography variant="h3">
            {allTranslations('Уточнение контуров линзы')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box className={classes.popUpZoomSectionBorder}>
            <TransformWrapper
              ref={this.refTransformWrapper}
              disabled={!isReady}
              disablePadding={true}
              panning={{
                disabled: isDisabledPanning,
                excluded: isDisabledPanning ? ['panningDisabled'] : [],
                velocityDisabled: true
              }}
              onZoom={this.changeZoom}
            >
              <TransformComponent>
                <Box
                  ref={this.refContent}
                  className={clsx({
                    [classes.popUpZoomSection]: true,
                    "width": Boolean(orientation === "width"),
                    "height": Boolean(orientation === "height"),
                  })}
                >
                  <div className="panningDisabled" style={{backgroundImage: `url(${imageUrlMain})`}}>
                    <img ref={this.refImage} src={imageUrlMain} className="image-contrast-settings"/>
                    <div className="--center" style={{top: '50%', left: '50%'}}/>

                    {Boolean(value.length) && (
                      <RndComponent
                        ref={this.refRndComponent}
                        classes={classes}
                        activeIndex={activePointIndex}
                        points={value}
                        center={center}
                        onChange={this.changeDot}
                        onChangeReady={this.changeReady}
                        onChangePoint={this.changePoint}
                        onChangeDisabledPanning={this.changeDisabledPanning}
                      />
                    )}
                    <LinesComponents
                      ref={this.refLinesComponents}
                      points={value}
                      classes={classes}
                      items={linesCounter}
                      activeIndex={activePointIndex}
                    />
                  </div>
                </Box>
              </TransformComponent>
            </TransformWrapper>
          </Box>
          <Box mt={1}/>
          <ContrastSlider
            value={0}
            onChange={this.changeContrast}
          />
          <Box mt={1}/>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <Button variant="outlined" disabled={!isReady} onClick={this.prevPoint}>
                    {allTranslations('Предыдущая точка')} (F2)
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" disabled={!isReady} onClick={this.nextPoint}>
                    {allTranslations('Следующая точка')} (F3)
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <Button variant="outlined" onClick={onClose}>
                    {allTranslations('Отменить')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" onClick={this.submitValue}>
                    {allTranslations('Зафиксировать')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  }
}

const GlassSection = React.memo((props) => {
  const {
    values,
    image,
    imageId,
    center,
    classes,
    onClick,
    onChange
  } = props;
  const refTransformWrapper = React.createRef();
  const handleClickRoot = () => {
    refTransformWrapper.current.zoomOut(99999, 0);
    onClick({
      blob: image,
      value: values,
      center: center,
      imageId: imageId,
      onChange
    })
  }

  return (
    <Box className={classes.glassSection} onClick={handleClickRoot}>
      <TransformWrapper
        ref={refTransformWrapper}
        disablePadding={true}
        panning={{excluded: ['panningDisabled'], velocityDisabled: true}}
      >
        <TransformComponent>
          <Box
            className={classes.glassSectionTransform}
            style={{backgroundImage: `url(${URL.createObjectURL(image)})`}}
          >
            <img id={imageId} src={URL.createObjectURL(image)}/>
            <div className={classes.glassSectionTransformCenter} style={{top: '50%', left: '50%'}}/>
            {(values || []).map((t) => (
              <div className={classes.glassSectionTransformCenter} style={{top: t.y, left: t.x}}/>
            ))}
          </Box>
        </TransformComponent>
      </TransformWrapper>
    </Box>
  )
})
const RndComponent = React.forwardRef((props, ref) => {
  const {
    points,
    center,
    classes,
    activeIndex,
    onChange,
    onChangeReady,
    onChangePoint,
    onChangeDisabledPanning
  } = props;
  let elementIframe = null;
  let timeOutSend = null;
  const refRoot = React.createRef();
  const elementId = Math.random().toString(36).slice(4, 16);
  const [ stateFrame, setStateFrame ] = React.useState(null);

  React.useEffect(() => {
    initSizeRoot();
  }, []);
  React.useEffect(() => {
    updateDotZim();
  }, [activeIndex]);

  React.useImperativeHandle(ref, () => ({
    updatePointPosition (index, x, y, point) {
      const objects = (stateFrame?.cursorList?.objects || []).filter((t) => !!t?.veeObj);
      (objects || []).map((element, _index) => {
        if (Boolean(index === _index)) {
          const movX = Boolean(point) ? point.x - element.x : null;
          const movY = Boolean(point) ? point.y - element.y : null;
          element.mov({x: movX || x, y: movY || y});
        }
      });
    }
  }));

  const initSizeRoot = () => {
    if (refRoot.current.clientWidth <= 0) {
      setTimeout(() => {
        initSizeRoot();
      }, 100);
      return
    }
    const sizes = {
      width: refRoot.current.clientWidth,
      height: refRoot.current.clientHeight,
    };
    initZimFrame(sizes);
  }
  const initZimFrame = (sizes) => {
    const calcNextPoint = (p1, p2, delta) => {
      var a =  delta * (p2[0] - p1[0]) + p1[0];
      var b =  delta * (p2[1] - p1[1]) + p1[1];
      return [a, b]
    }
    elementIframe = new zim.Frame({
      scaling: elementId,
      width: sizes.width,
      height: sizes.height,
      sensors: true
    });
    elementIframe.on('ready', async function () {
      const stage = elementIframe.stage;
      await Promise.all((points || []).map(async (point, index) => {
        const currentPoint = [point.x, point.y];
        const centerPoint = [center.x, center.y];
        const firstPoint = calcNextPoint(currentPoint, centerPoint, -2);
        const pathPoints = [centerPoint, firstPoint];

        const line = new zim.Line({
          points: [centerPoint, currentPoint, firstPoint],
          color: "rgba(0,0,0,0.5)",
          thickness: 0.5
        }).addTo(stage)
        const path = new zim.Squiggle({
          color: "#A72681",
          points: pathPoints,
          interactive:false,
          thickness:1,
          StickThickness: 1,
          handleSize: 1,
          strokeObj: {miterLimit: 1, ignoreScale: true},
        });

        const circle = new zim.Circle({
          color: new zim.RadialColor(['#9EFF00','rgba(255,255,255,0.01)'], [0, .3], 0, 0, 0, 0, 0, 10),
          borderColor: "#9EFF00",
          radius: 10,
          dashedOffset: 1,
          mouseChildren: true,
        })
          .addTo(stage)
          .animate({
            props: {path: path},
            drag: true
          })

        circle.on("rollover", onChangeDisabledPanning.bind(this, true, index));
        circle.on("rollout", onChangeDisabledPanning.bind(this, false, null));
        circle.on("pressmove", changePointPosition.bind(this, index));
        circle.on("click", onChangePoint.bind(this, index));
      }));
      await stage.update();
      await new Promise(r => setTimeout(r, 2000));
      await setStateFrame(elementIframe);
      const objects = (elementIframe?.cursorList?.objects || []).filter((t) => !!t?.veeObj);
      await Promise.all(objects.map(async (element, _index) => {
        const point = (points || [])[_index];
        element.x = point.x;
        element.y = point.y;

        await element.localToGlobal(point.x, point.y);
        await element.uncache();
        await elementIframe.update();
      }));

      await onChangeReady(true);
    });
  }
  const updateDotZim = () => {
    if (activeIndex <= -1) {
      return
    }
    const objects = (stateFrame?.cursorList?.objects || []).filter((t) => !!t?.veeObj);
    (objects || []).map((element, index) => {
      const color = Boolean(index === activeIndex) ? "#ff0d00" : "#9EFF00";
      element.color = new zim.RadialColor([color,'rgba(255,255,255,0.01)'], [0, .3], 0, 0, 0, 0, 0, 10);
      element.borderColor = color;
    })
  }
  const changePointPosition = (index, event) => {
    clearTimeout(timeOutSend);
    timeOutSend = setTimeout(() => {
      const x = event?.target?.x;
      const y = event?.target?.y;
      onChange(index,{x,y});
    }, 50)
  }

  return (
    <Box ref={refRoot} className={clsx([classes.rndRoot])}>
      <canvas id={elementId} className="panningDisabled"/>
    </Box>
  )
});
const LinesComponents = React.forwardRef((props, ref) => {
  const {
    items: _items,
    points: _points,
    activeIndex,

    classes
  } = props;
  const [activeLine, setActiveLine] = React.useState([]);

  React.useEffect(() => {
    findActiveLine();
  }, [_points]);
  React.useEffect(() => {
    findActiveLine();
  }, [activeIndex]);

  React.useImperativeHandle(ref, () => ({
    returnActiveLine () {
      return activeLine
    }
  }));

  const findActiveLine = () => {
    if (activeIndex < 0) {
      setActiveLine([]);
      return
    }

    const point = {
      x: Number.parseFloat(_points[activeIndex].x.toFixed(0)),
      y: Number.parseFloat(_points[activeIndex].y.toFixed(0)),
    };
    const findLine = _items.find((item) => {
      return Boolean(item.find((t) => {
        const pointXS = point.x - 0.5;
        const pointXB = point.x + 0.5;
        const isXIncluded = Boolean(pointXS <= t.x && t.x <= pointXB);

        const pointYS = point.y - 0.5;
        const pointYB = point.y + 0.5;
        const isYIncluded = Boolean(pointYS <= t.y && t.y <= pointYB);

        return Boolean(isXIncluded && isYIncluded)
      }))
    });

    setActiveLine(findLine || []);
  }

  return (
    <>
      {activeLine.map((item) => (
        <div className={classes.linesComponents} style={{ top: `${item.y}px`, left: `${item.x}px` }}/>
      ))}
    </>
  )
})

const styles = {
  glassSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid rgba(0,0,0,0.5)",
    height: 360,
    borderRadius: 10,
    overflow: "hidden",
    backgroundColor: "white"
  },
  glassSectionTransform: {
    display: "flex",
    position: "relative",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",

    "& img": {
      maxWidth: "100%",
      maxHeight: "360px",
      opacity: 0
    }
  },
  glassSectionTransformCenter: {
    position: "absolute",
    zIndex: 2,
    width: 0,
    height: 0,
    pointerEvents: "none",
    opacity: 1,

    "&:after": {
      content: "''",
      width: "6px",
      height: "6px",
      borderRadius: "100%",
      border: "1px solid #9EFF00",
      position: "absolute",
      top: "50%", left: "50%",
      transform: "translate(-50%, -50%)"
    }
  },

  areaEyepiece: {
    position: "absolute",
    width: "30px!important",
    height: "30px!important",
    userSelect: "none",
    borderRadius: "100%",

    "& img": {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: "50%", left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundSize: "contain",
      opacity: 1,
      filter: "invert(19%) sepia(53%) saturate(3458%) hue-rotate(297deg) brightness(95%) contrast(90%)"
    }
  },
  areaEyepieceActive: {
    background: "green",
    zIndex: "50"
  },

  rndRoot: {
    position: "absolute",
    top: 0, left: 0,
    width: "100%",
    height: "100%"
  },

  popUpZoomSectionBorder: {
    border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: 10,
    overflow: "hidden",
    position: "relative"
  },
  popUpZoomSection: {
    margin: "0 auto",
    width: 'calc(100vw - 100px)',
    height: 'calc(100vh - 200px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: "hidden",

    "& > div": {
      display: "flex",
      position: "relative",
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      margin: '0 auto',
    },
    "&.height > div": {
      height: '-webkit-fill-available',
    },
    "& .--center": {
      position: "absolute",
      width: "0px",
      height: "0px",
      pointerEvents: "none",
      "&:after": {
        "content": "''",
        position: "absolute",
        left: "50%", top: "50%",
        transform: "translate(-50%, -50%)",
        width: "6px",
        height: "6px",
        borderRadius: "100%",
        backgroundColor: "red",
      }
    }
  },

  linesComponents: {
    position: "absolute",
    width: "0px",
    height: "0px",
    "&:after": {
      content: "''",
      position: "absolute",
      top: "50%", left: "50%",
      transform: "translate(-50%, -50%)",
      width: 2, height: 2,
      borderRadius: "100%",
      backgroundColor: "red",
      pointerEvents: "none"
    }
  }
};
ContourRefinement = withStyles(styles)(ContourRefinement)

export default ContourRefinement
