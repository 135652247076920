import React, {Component} from "react";
import {
  Backdrop,
  CircularProgress
} from "@mui/material";
import {
  Table as TableComponent
} from "./components";
import {
  DialogConfirmation
} from "../../components";
import agent from "../../agent/agent";
import moment from "moment/moment";
import {Notification, notificationTypes} from "../../common/Notification";
import allTranslations from "../../locales/allTranslations";

const totalCountRows = 20;
class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      filter: {},
      totalPage: 1,
      currentPage: 1,
      isLoad: true,
      isBackdrop: false
    };
    this.refDialogConfirmation = React.createRef();
  }

  componentDidMount = async () => {
    await this.getOrders();
  }

  getOrders = async () => {
    this.setState({ isLoad: true });
    const res = await agent.get(`/orders?limit=20&page=${this.state.currentPage}`).then((res) => {
      return res.data
    }).catch(() => {
      return null
    });

    this.setState({
      orders: res?.orders || [],
      totalPage: Math.ceil(res?.count / totalCountRows),
      isLoad: false
    })
  }
  removeOrder = async (order, confirm) => {
    if (!confirm) {
      this.refDialogConfirmation.current.open({
        message: `${allTranslations('Вы действительно хотите удалить заказ')} №${order.id} ${moment(order.createdAt).format("DD.MM.YYYY")}`,
        onSubmit: this.removeOrder.bind(this, order, true)
      })
      return
    }
    this.setState({isBackdrop: true});
    const isSuccess = await agent.delete(`/orders/${order.id}`).then((res) => {
      return true
    }).catch(() => {
      return false
    });
    if (!isSuccess) {
      this.setState({isBackdrop: false});
      Notification({
        type: notificationTypes.error,
        message: allTranslations('Ошибка сервера')
      })
      return
    }
    Notification({
      type: notificationTypes.success,
      message: allTranslations('Заказ успешно удален')
    });
    await this.getOrders();
    this.setState({isBackdrop: false});
  }

  changePage = async (currentPage) => {
    await this.setState({currentPage});
    await this.getOrders();
  }

  render () {
    const {
      orders,
      filter,
      totalPage,
      currentPage,
      isLoad,
      isBackdrop
    } = this.state;

    return (
      <>
        <TableComponent
          data={orders}
          isLoad={isLoad}
          page={currentPage}
          total={totalPage}

          onRemove={this.removeOrder}
          onChangePage={this.changePage}
        />


        <DialogConfirmation ref={this.refDialogConfirmation}/>
        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>
      </>
    );
  }
}

export default Orders
