import React from 'react';
import {
  Box
} from "@mui/material";
import {
  compose
} from 'recompose';
import {
  connect
} from 'react-redux';
import {
  getEquipments,
  setAuthMe
} from "../states/global";
import AuthRoutes from "./Auth.js";
import LandingRoutes from "./Landing";
import RegistrationRoutes from "./Registration";
import agent from "../agent/agent";
import {setPatient} from "../states/patient";

class Router extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true
		};
	}

  componentDidMount = async () => {
    await this.checkAuthMe();
  }
  componentDidUpdate = async (prevProps) => {
    if (this.props?.global?.authMe !== prevProps?.global?.authMe) {
      await this.props.getEquipments();
    }
  }

  checkAuthMe = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      this.setState({isLoading: false})
      return
    }

    const authMe = await agent.get('/auth/me').then((res) => {
      return res.data
    }).catch(() => {
      return null
    });
    if (!authMe) {
      localStorage.removeItem('token');
      this.setState({isLoading: false})
      return
    }

    this.props.setAuthMe(authMe);
    await this.checkPatient();
    await this.props.getEquipments();
    this.setState({ isLoading: false });
  }
  checkPatient = async () => {
    const patientId = localStorage.getItem('patient_id');
    if (!patientId) {
      return
    }

    const patient = await agent.get(`/patients/${patientId}`).then((res) => {
      return res.data?.patient || null
    }).catch(() => {
      return null
    });
    if (!patient) {
      return null
    }

    this.props.setPatient(patient);
  }

  render() {
    const {
      isLoading
    } = this.state;
    const {
      global,
      patient
    } = this.props;

    if (isLoading) {
      return <LoadingContent/>
    }
    if (!global?.authMe) {
      return <AuthRoutes/>
    }
    if (!patient.patient) {
      return <RegistrationRoutes/>
    }

		return (
			<LandingRoutes/>
		)
	}
}
const LoadingContent = React.memo(() => {
  return (
    <Box>L O A D I N G . . .</Box>
  )
})

export default compose(
  connect(
    state => ({
      global: state.global,
      patient: state.patient
    }),
    dispatch => ({
      setAuthMe: (authMe) => dispatch(setAuthMe(authMe)),
      setPatient: (patient) => dispatch(setPatient(patient)),
      getEquipments: () => dispatch(getEquipments()),
    }),
  ),
)(Router);
