import React from "react";
import {
  Box,
  Grid,
  Button,
  Container,
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import {
  ContrastSlider
} from "../../components";
import {Rnd} from "react-rnd";
import allTranslations from "../../locales/allTranslations";
import clsx from "clsx";

class RefinementEyepieceArea extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      contrast: 0
    };

    this.refImageLeft = React.createRef();
    this.refImageRight = React.createRef();
    this.refBlobSection = React.createRef();
    this.timeOutUpdateContrast = React.createRef();
  }

  changeFormLeft = (camName, value) => {
    let form = {...this.props.form};
    form[camName]['rect_l'] = value;
    this.props.onChange(form);
  }
  changeFormRight = (camName, value) => {
    let form = {...this.props.form};
    form[camName]['rect_r'] = value;
    this.props.onChange(form);
  }
  changeContrast = (value) => {
    clearTimeout(this.timeOutUpdateContrast);
    const elements = document.querySelectorAll('.image-contrast-settings');
    elements.forEach((element) => {
      element.style.filter = `contrast(${value+100}%)`
    });
    this.timeOutUpdateContrast = setTimeout(() => {
      this.setState({ contrast: value })
    }, 1000);
  }

  submitForm = () => {
    this.props.onSubmit({
      clientSizeLeft: {
        width: this.refImageLeft.current.clientWidth,
        height: this.refImageLeft.current.clientHeight,
      },
      clientSizeRight: {
        width: this.refImageRight.current.clientWidth,
        height: this.refImageRight.current.clientHeight,
      },
    })
  }

  getSizeSection = () => {
    return {
      width: this.refBlobSection.current.clientWidth,
      height: this.refBlobSection.current.clientHeight,
    }
  }

  render () {
    const {
      blob,
      blobLeft,
      blobRight,

      form,
      classes
    } = this.props;
    const {
      contrast
    } = this.state;

    return (
      <>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Box ref={this.refBlobSection} className={clsx([classes.blobSection, 'rnd-bounds'])}>
              <img
                ref={this.refImageLeft}
                src={URL.createObjectURL(blobLeft)}
                className="image-contrast-settings"
                style={{filter: `contrast(${contrast+100}%)`}}
              />

              <RndComponent
                width={form?.cam_0?.rect_l?.width}
                height={form?.cam_0?.rect_l?.height}
                x={form?.cam_0?.rect_l?.x}
                y={form?.cam_0?.rect_l?.y}
                title={allTranslations("Левый")}
                xLeftLimit={form?.cam_0?.rect_r?.width + form?.cam_0?.rect_r?.x}
                classes={classes}
                onChange={this.changeFormLeft.bind(this, 'cam_0')}
              />
              <RndComponent
                width={form?.cam_0?.rect_r?.width}
                height={form?.cam_0?.rect_r?.height}
                x={form?.cam_0?.rect_r?.x}
                y={form?.cam_0?.rect_r?.y}
                xRightLimit={form?.cam_0?.rect_l?.x}
                title={allTranslations("Правый")}
                classes={classes}
                onChange={this.changeFormRight.bind(this, 'cam_0')}
              />
            </Box>
          </Grid>
          <Grid item>
            <Box className={clsx([classes.blobSection, 'rnd-bounds'])}>
              <img
                ref={this.refImageRight}
                src={URL.createObjectURL(blobRight)}
                className="image-contrast-settings"
                style={{filter: `contrast(${contrast+100}%)`}}
              />

              <RndComponent
                width={form?.cam_1?.rect_l?.width}
                height={form?.cam_1?.rect_l?.height}
                x={form?.cam_1?.rect_l?.x}
                y={form?.cam_1?.rect_l?.y}
                title={allTranslations("Левый")}
                classes={classes}
                onChange={this.changeFormLeft.bind(this, 'cam_1')}
              />
              <RndComponent
                width={form?.cam_1?.rect_r?.width}
                height={form?.cam_1?.rect_r?.height}
                x={form?.cam_1?.rect_r?.x}
                y={form?.cam_1?.rect_r?.y}
                title={allTranslations("Правый")}
                classes={classes}
                onChange={this.changeFormRight.bind(this, 'cam_1')}
              />
            </Box>
          </Grid>
        </Grid>
        <Box mt={1}/>

        <Container maxWidth="lg">
          <ContrastSlider
            value={contrast}
            onChange={this.changeContrast}
          />
          <Box mt={1}/>
          <Button fullWidth variant="contained" onClick={this.submitForm}>
            {allTranslations('Зафиксировать')}
          </Button>
        </Container>
      </>
    )
  }
}

let setTimeOutUpdate = null;
const RndComponent = React.memo((props) => {
  const {
    classes,
    width,
    height,
    x,
    y,
    xLeftLimit,
    xRightLimit,
    title,
    onChange
  } = props;
  const refRnd = React.createRef();
  const [_width, setWidth] = React.useState(width);
  const [_height, setHeight] = React.useState(height);
  const [position, setPosition] = React.useState({ x: x, y: y });

  React.useEffect(() => {
    setWidth(width);
    setHeight(height);
    setPosition({ x: x, y: y });
  }, [width, height, x, y]);

  const handleUpdateData = (e, direction, ref, delta, position) => {
    clearTimeout(setTimeOutUpdate)
    setWidth(ref?.style?.width || _width);
    setHeight(ref?.style?.height || _height);
    setPosition({...position});
    setTimeOutUpdate = setTimeout(() => {
      onChange({
        "x": position?.x,
        "y": position?.y,
        "width": Number.parseFloat(String(ref?.style?.width || _width || "")),
        "height": Number.parseFloat(String(ref?.style?.height || _height || "")),
      })
    }, 0);
  }

  return (
      <Rnd
        ref={refRnd}
          size={{ width: _width, height: _height }}
          position={{ x: position.x, y: position.y }}
          onDragStop={(e, d) => handleUpdateData(null, null, null, null, {x: d.x, y: d.y})}
          bounds="parent"
          onResizeStop={handleUpdateData}
          className={classes.areaEyepiece}
      >
        <span style={{pointerEvents: "none"}}>{ title }</span>
      </Rnd>
  )
})

const styles = {
  blobSection: {
    boxShadow: "0 0 0 1px rgba(0,0,0,0.1)",
    borderRadius: "10px",
    position: "relative",
    overflow: "hidden",
    width: 608,
    height: 608,

    "& img": {
      maxWidth: "100%",
      maxHeight: "100%"
    }
  },
  areaEyepiece: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    width: 120,
    height: 60,
    border: "1px solid #9EFF00",
    userSelect: "none",

    "& span": {
      position: "absolute",
      top: "50%", left: "50%",
      transform: "translate(-50%, -50%)",
      whiteSpace: "nowrap",

      fontSize: "16px",
      lineHeight: "20px",
      color: "black",
      fontFamily: "Montserrat",
      fontWeight: "600",
      textShadow: "1px 0 0px #fff, 0 1px 0px #fff, -1px 0 0px #fff, 0 -1px 0px #fff"
    }
  }
};
RefinementEyepieceArea = withStyles(styles)(RefinementEyepieceArea)

export default RefinementEyepieceArea
