import React, {Component} from "react";
import {
  Box,
  Grid,
  Button,
  Backdrop,
  TextField,
  Container,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import {
  Formik
} from "formik";
import {
  Notification,
  notificationTypes
} from "../../common/Notification";
import * as Yup from "yup";
import agent from "../../agent/agent";
import allTranslations from "../../locales/allTranslations";

class AdminAuth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        login: "admin",
        password: "testAdminPass",
      },

      isBackdrop: false
    };

    this.refFormik = React.createRef();
  }

  // Изменение формы
  changeForm = ({ target }) => {
    const { name, value } = target;
    let values = this.refFormik.current.values;
    values[name] = value;
    this.refFormik.current.setValues(values);
  }

  // Авторизация пользователя в системе
  onSubmit = async (body) => {
    this.setState({ isBackdrop: true });

    const response = await agent.post('/auth', body).then((res) => {
      return res.data
    }).catch((err) => {
      return { error: true }
    });
    if (response?.error) {
      this.setState({ isBackdrop: false });

      Notification({
        message: allTranslations('ошибка_авторизации'),
        type: notificationTypes.error
      })

      return
    }

    localStorage.setItem('token', response?.accessToken);
    agent.defaults.headers['x-auth-token'] = response?.accessToken;

    const authMe = await agent.get('/auth/me').then((res) => {
      return res.data
    }).catch(() => {
      return {}
    });
    this.setState({ isBackdrop: false });
    this.props.setAuthMe(authMe);
  }

  render() {
    const {
      form,
      isBackdrop
    } = this.state;
    const {
      classes
    } = this.props;

    return (
      <Box className={classes.root}>
        <Container>
          <Box className={classes.body}>

            <Typography variant="h1" align="center" mb="30px">
              {allTranslations('Авторизация в системе')}
            </Typography>

            <Formik
              innerRef={this.refFormik}
              initialValues={form}
              validationSchema={validationSchema}
              onSubmit={this.onSubmit}
            >{(props) => {
              const {
                values,
                errors,
                touched,

                handleSubmit
              } = props;

              return (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      value={values.login}
                      error={Boolean(touched.login && errors.login)}
                      helperText={touched.login && errors.login}
                      name="login"
                      label={allTranslations('Логин')}
                      placeholder="..."
                      fullWidth
                      onChange={this.changeForm}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={values.password}
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      name="password"
                      label={allTranslations('Пароль')}
                      placeholder="..."
                      type="password"
                      fullWidth
                      onChange={this.changeForm}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      size="large"
                      variant="contained"
                      fullWidth
                      onClick={handleSubmit}
                    >
                      {allTranslations('Авторизоваться')}
                    </Button>
                  </Grid>
                </Grid>
              )
            }}</Formik>

          </Box>
        </Container>

        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>
      </Box>
    );
  }
}

const styles = {
  root: {
    padding: "60px",
    boxSizing: "border-box",
    backgroundColor: "white"
  },
  body: {
    backgroundColor: "#F5F4F5",
    padding: "60px",
    boxSizing: "border-box",
    borderRadius: "12px"
  }
};
AdminAuth = withStyles(styles)(AdminAuth);

const validationSchema = Yup.object().shape({
  login: Yup.string().required(allTranslations('Заполните поле')),
  password: Yup.string().required(allTranslations('Заполните поле')),
});

export default AdminAuth
