const getAllCameras = async () => {
  if (!Boolean('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices)) {
    return {isError: true, message: "Тут текст ошибки"}
  }

  const devices = await navigator.mediaDevices.enumerateDevices();
  const videoDevices = devices.filter(device => device.kind === 'videoinput');

  return videoDevices
}

export default getAllCameras
