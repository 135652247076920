// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import AdminAuth from './AdminAuth';
import {setAuthMe} from "../../states/global";

export default compose(
  connect(
    state => ({}),
    dispatch => ({
      setAuthMe: (authMe) => dispatch(setAuthMe(authMe))
    }),
  ),
)(AdminAuth);
