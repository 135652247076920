import React, {Component} from "react";
import {
  Grid,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import {
  Table as TableComponent,
  DialogForm as DialogFormComponent
} from "./components";
import {
  DialogConfirmation
} from "../../components";
import {
  Notification,
  notificationTypes
} from "../../common/Notification";
import agent from "../../agent/agent";
import allTranslations from "../../locales/allTranslations";

class Patients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      patients: [],
      filter: {
        page: 1,
      },
      totalCount: 1,
      isLoad: true,
      isBackdrop: false
    };
    this.refDialogForm = React.createRef();
    this.refDialogConfirmation = React.createRef();
  }

  componentDidMount = async () => {
    await this.getPatients();
  }

  getPatients = async () => {
    this.setState({ isLoad: true });

    const filter = this.getFilter();
    const res = await agent.get(`/patients?${filter}`).then((res) => {
      return res.data
    }).catch(() => {
      return null
    })

    this.setState({
      patients: res?.patients || [],
      totalCount: Math.ceil((res?.count || 1) / 20),
      isLoad: false
    });
  }
  editPatient = async (form, isSubmit) => {
    if (!isSubmit) {
      const initForm = {
        id: form.id,
        "firstName": form.firstName,
        "lastName": form.lastName,
        "patronymic": form.patronymic,
        "phone": form.phone,
        "email": form.email,
        "birthdate": new Date(form.birthdate)
      }
      this.refDialogForm.current.open({
        form: initForm,
        onSubmit: this.editPatient.bind(this)
      })
      return
    }

    this.setState({ isBackdrop: true });

    let body = {...form};
    body.phone = body.phone.replace(/\D+/g,"");
    const res = await agent.patch(`/patients/${form.id}`, body).then((res) => {
      return true
    }).catch(() => {
      return false
    });
    if (!res) {
      this.setState({ isBackdrop: false });
      Notification({
        message: allTranslations('Ошибка сервера'),
        type: notificationTypes.error
      })
      return
    }

    await this.getPatients();
    this.refDialogForm.current.close();
    this.setState({ isBackdrop: false });
    Notification({
      message: allTranslations('Пациент успешно обновлен'),
      type: notificationTypes.success
    });
  }
  createPatient = async (form) => {
    if (!form) {
      this.refDialogForm.current.open({
        onSubmit: this.createPatient.bind(this)
      })
      return
    }

    this.setState({ isBackdrop: true });

    let body = {...form};
    body.phone = body.phone.replace(/\D+/g,"");
    const res = await agent.post('/patients', body).then((res) => {
      return true
    }).catch(() => {
      return false
    });
    if (!res) {
      this.setState({ isBackdrop: false });
      Notification({
        message: allTranslations('Ошибка сервера'),
        type: notificationTypes.error
      })
      return
    }

    await this.getPatients();
    this.refDialogForm.current.close();
    this.setState({ isBackdrop: false });
    Notification({
      message: allTranslations('Пациент успешно добавлен'),
      type: notificationTypes.success
    });
  }
  deletePatient = async (itemId, isConfirm) => {
    if (!isConfirm) {
      this.refDialogConfirmation.current.open({
        message: `${allTranslations('Вы действительно хотите удалить пациента')} №${itemId}`,
        onSubmit: this.deletePatient.bind(this, itemId, true)
      })
      return
    }

    this.setState({ isBackdrop: true });

    const res = await agent.delete(`/patients/${ itemId }`).then((res) => {
      return true
    }).catch(() => {
      return false
    });
    if (!res) {
      this.setState({ isBackdrop: false });
      Notification({
        message: allTranslations('Ошибка сервера'),
        type: notificationTypes.error
      })
      return
    }

    await this.getPatients();
    this.setState({ isBackdrop: false });
    Notification({
      message: allTranslations('Пациент успешно удален'),
      type: notificationTypes.success
    })
  }

  getFilter = () => {
    let str = [
      `page=${this.state.filter.page}`,
      'total=20'
    ];

    return str.join("&")
  }
  changeFilter = async (filter) => {
    await this.setState({ filter });
    await this.getPatients();
  }

  render () {
    const {
      patients,
      filter,
      totalCount,
      isLoad,
      isBackdrop
    } = this.state;

    return (
      <>

        <Grid container justifyContent="flex-end" mb={2}>
          <Grid item>
            <Button variant="contained" onClick={this.createPatient.bind(this, null)}>
              {allTranslations('Добавить')}
            </Button>
          </Grid>
        </Grid>

        <TableComponent
          data={patients}
          isLoad={isLoad}
          filter={filter}
          totalCount={totalCount}

          onEdit={this.editPatient}
          onDelete={this.deletePatient}
          onChangeFilter={this.changeFilter}
        />

        <DialogFormComponent ref={this.refDialogForm}/>
        <DialogConfirmation ref={this.refDialogConfirmation}/>

        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>

      </>
    );
  }
}

export default Patients
