import React from "react";
import {
  Box,
  Grid,
  Button,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,

  IconButton
} from "@mui/material";
import {
  Close as CloseIcon
} from "@mui/icons-material";
import allTranslations from "../../locales/allTranslations";

class DialogConfirmation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,

      title: "",
      message: "",
      acceptButtonTitle: "",
      cancelButtonTitle: "",
      acceptButtonAction: "",
      cancelButtonAction: "",
      hideCancel: false
    }
  }

  open = (props) => {
    this.setState({
      open: true,

      title: props.title || '',
      message: props.message || '',
      acceptButtonTitle: props.acceptButtonTitle || allTranslations('Применить'),
      cancelButtonTitle: props.cancelButtonTitle || '',
      acceptButtonAction: props.onSubmit || '',
      cancelButtonAction: props.onCancel || '',

      hideCancel: props?.hideCancel || false
    })
  }
  close = () => {
    this.setState({
      open: false
    })
  }

  onAccept = () => {
    this.setState({
      open: false
    });

    if (!this.state.acceptButtonAction) {
      return null
    }

    this.state.acceptButtonAction();
  }
  onCancel = () => {
    this.setState({
      open: false
    });

    if (!this.state.cancelButtonAction) {
      return null
    }

    this.state.cancelButtonAction();
  }

  render() {
    const {
      open,

      title,
      message,
      acceptButtonTitle,
      cancelButtonTitle,

      hideCancel
    } = this.state;

    return (
      <Dialog open={open} fullWidth maxWidth="md" onClose={this.onCancel}>
        <DialogTitle>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h3">{title || allTranslations('Подтверждение')}</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={this.onCancel}>
                <CloseIcon/>
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Typography
              variant="h4"
              sx={{color: "black"}}
              dangerouslySetInnerHTML={{
                __html: (message || allTranslations('Вы уверены?'))
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} justifyContent="flex-end">
            {Boolean(!hideCancel) && (
              <Grid item>
                <Button variant="outlined" fullWidth onClick={this.onCancel}>
                  {allTranslations('Отменить')}
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button variant="contained" fullWidth onClick={this.onAccept}>
                {acceptButtonTitle || allTranslations('Применить')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
}

export default DialogConfirmation
