import React, {Component} from "react";
import {
  Box, FormControl, Button,
  Grid, InputLabel, MenuItem, Select,
  Backdrop, CircularProgress, ButtonGroup,
  Typography
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import {
  DialogNotification,
  DialogSelectedEquipment
} from "../../components";
import {
  Notification,
  notificationTypes
} from "../../common/Notification";
import {
  snapshotVideo,
  splitImage
} from "../../helpers/snapshotVideo";
import getAllCameras from "../../helpers/getAllCameras";
import agent from "../../agent/agent";
import {getPositionGyroscope, initGyroscope} from "../../helpers/calibration";
import allTranslations from "../../locales/allTranslations";

class Calibration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cameras: [],
      activeCamera: null,
      isBackdrop: false,
      currentCountSendImages: 0,
      serialRequestPort: null,
      selectedEquipment: null,

      isFullDisabled: false,
      idCalibrationSession: null
    };

    this.refVideo = React.createRef();
    this.refDialogNotification = React.createRef();
    this.refDialogSelectedEquipment = React.createRef();
  }

  componentDidMount = async () => {
    await this.checkEquipments();
    await this.initialCameras();
  }

  checkEquipments = async () => {
    const equipments = this.props?.equipments || [];
    if (equipments.length <= 0) {
      this.refDialogNotification.current.open({
        message: allTranslations('У вас не найдено не одного оборудования')
      })
      this.setState({isFullDisabled: true});
      return
    }
    if (equipments.length > 1) {
      this.refDialogSelectedEquipment.current.open({
        list: equipments,
        onSubmit: this.selectedEquipments.bind(this)
      })
      return
    }
    this.setState({
      selectedEquipment: equipments?.[0]?.id
    })
  }
  selectedEquipments = (selectedEquipment) => {
    this.setState({
      selectedEquipment: selectedEquipment,
      isFullDisabled: false
    });
  }

  // Получение всех устроств
  initialCameras = async () => {
    const cameras = await getAllCameras();
    this.setState({
      cameras,
      activeCamera: cameras?.[0]?.deviceId || null
    }, async () => {
      await this.startCamera();
    });
  }

  // Запуск и замены камеры
  startCamera = async () => {
    const {activeCamera} = this.state;
    if (!activeCamera) {
      Notification({
        type: notificationTypes.error,
        message: allTranslations('Мы не обнаружили ни одной камеры')
      })
      return
    }

    const device = await navigator.mediaDevices.getUserMedia({
      video: {
        deviceId: activeCamera,
        height: 1520,
        width: 3040
      }
    });

    this.refVideo.current.pause();
    this.refVideo.current.srcObject = device;
    this.refVideo.current.play();
  }
  changeCamera = async (activeCamera) => {
    const stream = this.refVideo.current.srcObject;
    const tracks = stream.getTracks();
    tracks.forEach(function(track) {
      track.stop();
    });
    this.refVideo.current.srcObject = null;

    this.setState({ activeCamera }, async () => {
      await this.startCamera();
    });
  }

  // Тестиорвание стереокамеры
  startingCalibrationSession = async () => {
    const isSuccessInitGyroscope = await initGyroscope();
    if (!isSuccessInitGyroscope) {
      return
    }

    this.setState({isBackdrop: true});
    const equipment = await agent.get('/equipments').then((res) => {
      return res?.data
    }).catch(() => {
      return {}
    });
    const session = await agent.post('/calibration-sessions', {
      "equipmentId": this.state.selectedEquipment
    }).then((res) => {
      return res.data?.session
    }).catch(() => {
      return null
    });
    if (!session) {
      this.setState({isBackdrop: false});
      Notification({
        type: notificationTypes.error,
        message: allTranslations("Ошибка сервера"),
      })

      return
    }
    this.setState({
      isBackdrop: false,
      idCalibrationSession: session?.id,
      currentCountSendImages: 0
    });
    Notification({
      message: `${allTranslations('Сессия калибровки')} №${ session?.id }.`,
      type: notificationTypes.success
    });
  }
  endCalibrationSession = async () => {
    this.setState({isBackdrop: true});
    const sessionId = this.state.idCalibrationSession;
    const res = await agent.patch(`/calibration-sessions/${sessionId}/calibrate`).then((res) => {
      return res.data
    }).catch(() => {
      return null
    })
    this.setState({isBackdrop: false});
    if (!res) {
      Notification({
        type: notificationTypes.error,
        message: allTranslations("Ошибка сервера")
      })
      return
    }

    const isSuccess = Boolean(res?.calibrateIsSuccess || false);
    const notificationMessage = isSuccess ? "Колибровка успешно выполнена, можете запросить проверить калибровки" : "Колибровка выполнена с ошибкой";
    Notification({
      type: isSuccess ? notificationTypes.success : notificationTypes.error,
      message: notificationMessage
    })
  }
  intermediateCalibrationSession = async () => {
    this.setState({ isBackdrop: true });
    const imageSizeWidth = 3040;
    const imageSizeHeight = 1520;

    const sessionId = this.state.idCalibrationSession;
    const positionGyroscope = await getPositionGyroscope();

    const video = document.getElementById('video-content');
    const blob = await snapshotVideo(video, {
      width: imageSizeWidth,
      height: imageSizeHeight,
    });
    const images = await splitImage(blob, {
      width: imageSizeWidth,
      height: imageSizeHeight,
    });

    const formData = new FormData();
    images.map((t) => {
      formData.append('files', t);
    });
    formData.append("coords", JSON.stringify(positionGyroscope));
    const response = await agent.patch(`/calibration-sessions/${ sessionId }/upload-calibration-data`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {
      return res.data
    }).catch(() => {
      return null
    });
    this.setState({
      isBackdrop: false,
      currentCountSendImages: this.state.currentCountSendImages + 1
    });
    Notification({
      type: notificationTypes.info,
      message: allTranslations('Фото было отправлено')
    })
  }

  onCalibrationCheck = async () => {
    this.setState({isBackdrop: true});

    const isSuccessInitGyroscope = await initGyroscope();
    if (!isSuccessInitGyroscope) {
      this.setState({isBackdrop: false});
      Notification({
        type: notificationTypes.error,
        message: allTranslations("Ошибка сервера"),
      })
      return
    }

    // Получение данных с гороскопа
    const positionGyroscope = await getPositionGyroscope();

    // Получение 2х фото с камеры
    const imageSizeWidth = 3040;
    const imageSizeHeight = 1520;
    const video = document.getElementById('video-content');
    const blob = await snapshotVideo(video, {
      width: imageSizeWidth,
      height: imageSizeHeight,
    });
    const images = await splitImage(blob, {
      width: imageSizeWidth,
      height: imageSizeHeight,
    });

    // Подготовка данных и отправка для калибровки
    const formData = new FormData();
    images.map((t) => {
      formData.append('files', t);
    });
    formData.append("coords", JSON.stringify(positionGyroscope));
    const calibrationCheck = await agent.patch(`/calibration-sessions/${this.state.selectedEquipment}/calibration-check`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {
      return res.data
    }).catch(() => {
      return null
    });
    if (!calibrationCheck) {
      this.setState({isBackdrop: false});
      Notification({
        type: notificationTypes.error,
        message: allTranslations("Ошибка сервера"),
      })
      return
    }

    this.setState({isBackdrop: false});
    this.refDialogNotification.current.open({
      title: Boolean(calibrationCheck?.success) ? allTranslations('Успешно') : allTranslations('Ошибка'),
      message: calibrationCheck?.comment,
      type: Boolean(calibrationCheck?.success) ? notificationTypes.success : notificationTypes.error,
    })
  }

  render() {
    const {
      cameras,
      activeCamera,
      idCalibrationSession,
      currentCountSendImages,
      selectedEquipment,

      isBackdrop,
      isFullDisabled
    } = this.state;
    const {
      classes
    } = this.props;

    console.log('this.state.selectedEquipment: ', this.state.selectedEquipment)

    return (
      <>

        <Grid container spacing={2}>

          <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
            <Grid item xs={4}>
              <SelectDevices
                value={activeCamera}
                options={cameras}
                onChange={this.changeCamera}
              />
            </Grid>

            <Grid item container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button className={classes.headButton} variant="contained" disabled={Boolean(idCalibrationSession || isFullDisabled || !selectedEquipment)} onClick={this.startingCalibrationSession}>
                  {allTranslations('Начать калибровки')}
                </Button>
              </Grid>
              <Grid item>
                <Button className={classes.headButton} disabled={Boolean(!idCalibrationSession)} variant="contained" onClick={this.intermediateCalibrationSession.bind(this, true)}>
                  {allTranslations('Отправить снимок')}
                </Button>
              </Grid>
              <Grid item>
                <Button className={classes.headButton} variant="contained" disabled={Boolean(!idCalibrationSession)} onClick={this.endCalibrationSession}>
                  {allTranslations('Закончить клибровку')}
                </Button>
              </Grid>
              <Grid item>
                <Button className={classes.headButton} variant="contained" onClick={this.onCalibrationCheck}>
                  {allTranslations('Проверка калибровки')}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container>
            <Typography variant="h5">
              {allTranslations('Отправленно фото')} { currentCountSendImages } / 100;
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Box className={classes.cameraBox}>
              <video id="video-content" ref={this.refVideo} autoplay/>
            </Box>
          </Grid>

        </Grid>

        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>

        <DialogNotification
          ref={this.refDialogNotification}
        />
        <DialogSelectedEquipment
          ref={this.refDialogSelectedEquipment}
        />
      </>
    );
  }
}
const SelectDevices = React.memo((props) => {
  const {
    value,
    options,
    onChange
  } = props;

  const handleChange = (event, value) => {
    onChange(value?.props?.value);
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{allTranslations('Устройство')}</InputLabel>
      <Select
        value={value}
        label={allTranslations('Устройство')}
        onChange={handleChange}
      >
        {options.map((option) => (
          <MenuItem key={`deviceId-${option.deviceId}`} value={option.deviceId}>
            { option.label }
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
});

const styles = {
  cameraBox: {
    width: 1280,
    height: 480,
    borderRadius: 15,
    border: '1px solid #A72681',
    backgroundColor: "white",
    position: "relative",
    overflow: "hidden",

    "& video": {
      width: "100%",
      height: "100%"
    }
  },
  headButton: {
    padding: "8px 15px",
    fontWeight: "500",
    borderRadius: "5px",
  }
};
Calibration = withStyles(styles)(Calibration);

export default Calibration
