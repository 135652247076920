const { colors } = require('@mui/material');

const white = '#FFFFFF';
const black = '#000000';
const blackLight = '#282828';
const main = '#A72681';
const mainHover = '#A72681';

module.exports = {
  black,
  white,
  blackLight,

  primary: {
    contrastText: white,
    dark: mainHover,
    main: main,
    light: main,
    hover: main
  },
  secondary: {
    contrastText: white,
    dark: "#282828",
    main: "#282828",
    light: "#282828"
  },
  success: {
    contrastText: white,
    dark: "#62C787",
    main: "#62C787",
    light: "#62C787"
  },
  disabledBtn: {
    contrastText: white,
    dark: "#F7F7F7",
    main: "#F7F7F7",
    light: "#F7F7F7"
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: "#F5386A",
    main: "#F5386A",
    light: "#F5386A"
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: black,
    main: main,
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  background: {
    default: '#F4F6F8',
    paper: white
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
  gray: {
    default: '#8698B1',
    light: '#C2CFE0',
    veryLight: '#F3F5F9',
    dark: '#647083'
  }
};
