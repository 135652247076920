import React from "react";
import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  FormHelperText
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import {
  DatePicker
} from '@mui/x-date-pickers/DatePicker';
import {
  Formik
} from "formik";
import * as Yup from "yup";
import moment from "moment";
import {
  IMaskInput
} from "react-imask";
import allTranslations from "../../../../locales/allTranslations";

const initForm = {
  firstName: "firstName",
  lastName: "lastName",
  patronymic: "patronymic",
  phone: "",
  email: "",
  birthdate: "",
};

const Form = (props) => {
  const refFormik = React.createRef();

  const handleSubmit = (form) => {
    let body = {...form};
    // delete body.phone;
    delete body.email;
    delete body.birthdate;
    props.onSubmit(body);
  };

  // Изменения формы
  const handleChangeForm = ({ target }) => {
    if (!refFormik.current) {
      return
    }

    const { name, value } = target;
    let values = refFormik.current?.values || {};
    values[name] = value;
    refFormik.current.setValues(values);
  }
  const handleChangeFormDate = (name, value) => {
    let values = refFormik.current.values;
    values[name] = value;
    refFormik.current.setValues(values);
  }
  const handleResetForm = () => {
    refFormik.current.setValues({...initForm});
  }

  return (
    <Formik
      innerRef={refFormik}
      initialValues={{...initForm}}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >{(props) => {
      const {
        values,
        touched,
        errors,

        handleSubmit
      } = props;

      return (
        <>

          <Grid container rowSpacing={3} columnSpacing={2}>
            <Grid item xs={4}>
              <TextField
                value={values.lastName}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
                name="lastName"
                label={allTranslations('Фамилия')}
                placeholder="..."
                fullWidth
                onChange={handleChangeForm}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={values.firstName}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
                name="firstName"
                label={allTranslations('Имя')}
                placeholder="..."
                fullWidth
                onChange={handleChangeForm}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={values.patronymic}
                error={Boolean(touched.patronymic && errors.patronymic)}
                helperText={touched.patronymic && errors.patronymic}
                name="patronymic"
                label={allTranslations('Отчество')}
                placeholder="..."
                fullWidth
                onChange={handleChangeForm}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={values.phone}
                error={Boolean(touched.phone && errors.phone)}
                helperText={touched.phone && errors.phone}
                name="phone"
                label={allTranslations('Телефон')}
                placeholder="..."
                fullWidth
                InputProps={{
                  inputComponent: TextMaskCustom
                }}
                onChange={handleChangeForm}
              />
            </Grid>

            {Boolean(false) && (
              <>
                <Grid item xs={4}>
                  <TextField
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    name="email"
                    label="Email"
                    placeholder="..."
                    fullWidth
                    onChange={handleChangeForm}
                  />
                </Grid>
                <Grid item xs={4}>
                  <DatePicker
                    value={values.birthdate}
                    slotProps={{
                      textField: {
                        error: Boolean(touched.birthdate && errors.birthdate),
                        helperText: touched.birthdate && errors.birthdate,
                      },
                    }}
                    name="birthdate"
                    label={allTranslations('Дата рождения')}
                    placeholder="..."
                    fullWidth
                    minDate={new Date().setFullYear(1900, 1, 1)}
                    maxDate={new Date()}
                    onChange={handleChangeFormDate.bind(this, 'birthdate')}
                  />
                </Grid>
              </>
            )}
          </Grid>

          <Grid container justifyContent="space-between" mt="30px">
            <Grid item>
              <Button
                variant="outlined"
                sx={{paddingLeft: "80px", paddingRight: "80px"}}
                onClick={handleResetForm}
              >
                {allTranslations('Отменить')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                sx={{paddingLeft: "80px", paddingRight: "80px"}}
                onClick={handleSubmit}
              >
                {allTranslations('Добавить')}
              </Button>
            </Grid>
          </Grid>

        </>
      )
    }}</Formik>
  )
};
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;

  const handleOnAccept = (value) => {
    onChange({ target: { name: props.name, value } })
  }

  return (
    <IMaskInput
      {...other}
      mask="+{7}(000)000-00-00"
      inputRef={ref}
      onAccept={handleOnAccept}
    />
  );
});

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(allTranslations('Заполните поле')),
  lastName: Yup.string().required(allTranslations('Заполните поле')),
  patronymic: Yup.string().required(allTranslations('Заполните поле')),
  phone: Yup.string().length(16, allTranslations('Некорректные данные')).required(allTranslations('Заполните поле')),
  // email: Yup.string().email(allTranslations('Некорректные данные')).required(allTranslations('Заполните поле')),
  // birthdate: Yup
  //   .date()
  //   .min('1900-01-01', allTranslations('Дата рождения не может быть меньше 01.01.1900'))
  //   .max(new Date(), allTranslations('Дата рождения не может быть больше сегодня'))
  //   .required(allTranslations('Заполните поле')),
});

export default React.memo(Form)
