import {combineReducers} from 'redux';

import global from '../states/global';
import patient from '../states/patient';

const rootReducer = combineReducers({
  global,
  patient
});

export default rootReducer
