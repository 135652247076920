import React from "react";
import {
  Grid,
  Typography
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import moment from "moment";
import allTranslations from "../../../../locales/allTranslations";

const PatientInfo = (props) => {
  const {
    patient
  } = props;
  const classes = useStyles();

  return (
    <Grid container spacing="30px">
      <Grid item xs={12}>
        <Typography className={classes.formTitle}>{allTranslations('ФИО')}</Typography>
        <Typography className={classes.formValue}>
          {[patient?.firstName, patient?.lastName, patient?.patronymic].filter((t) => !!t).join(" ")}
        </Typography>
      </Grid>
      {Boolean(false) && (
        <>
          <Grid item xs={4}>
            <Typography className={classes.formTitle}>E-mail</Typography>
            <Typography className={classes.formValue}>
              {patient?.email}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.formTitle}>{allTranslations('Телефон')}</Typography>
            <Typography className={classes.formValue}>
              {patient?.phone}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.formTitle}>{allTranslations('Дата рождения')}</Typography>
            <Typography className={classes.formValue}>
              {moment(patient.birthdate).format("DD.MM.YYYY")}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  )
}

const useStyles = makeStyles(() => ({
  formTitle: {
    marginLeft: 30,
    marginBottom: 10,

    fontSize: 16,
    lineHeight: "25px",
    color: "#747474"
  },
  formValue: {
    borderRadius: '15px',
    border: '1px solid #E4E4E4',
    background: '#FDFDFD',
    padding: "15px 30px",
    boxSizing: "border-box",
    width: "100%",

    fontSize: "16px",
    lineHeight: "25px",
    color: "rgba(0,0,0,0.5)"
  },
}));

export default React.memo(PatientInfo)
