import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,

  Grid,
  IconButton,
  Typography
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import {
  Close as CloseIcon
} from "@mui/icons-material";
import {
  palette
} from "../../../../theme/common";
import moment from "moment";
import {useHistory} from "react-router-dom";
import allTranslations from "../../../../locales/allTranslations";

const OrdersInfo = (props) => {
  const {
    orders,
    onRemove
  } = props;
  const classes = useStyles();
  const history = useHistory();

  const handleGoOrder = (order) => {
    history.push(`/orders/${order.id}`);
  }

  return (
    <>

      <Typography variant="h1" mb="50px" color={palette.primary.main}>
        {allTranslations('Список заказов')}
      </Typography>

      <Table className={classes.table}>
        <TableBody>
          {orders.map((order) => (
            <TableRow key={`order-${order.id}`} hover onClick={handleGoOrder.bind(this, order)}>
              <TableCell>
                <a href={`/orders/${order.id}`} target="_blank">№{order.id}</a>
              </TableCell>
              <TableCell>
                {[order?.patient?.firstName, order?.patient?.lastName, order?.patient?.patronymic].filter((t) => !!t).join(" ")}
              </TableCell>
              <TableCell>{moment(order.createdAt).format("DD.MM.YYYY")}</TableCell>
              <TableCell>
                <Grid container spacing="20px" wrap="nowrap" justifyContent="flex-end">
                  <Grid item>
                    <IconButton
                      size="small"
                      sx={{padding: "4px", borderRadius: "4px"}}
                      onClick={onRemove.bind(this, order, false)}
                    >
                      <CloseIcon sx={{color: "#5B5B5B", fontSize: 24}}/>
                    </IconButton>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

const useStyles = makeStyles(() => ({
  table: {
    borderCollapse: "initial",
    borderSpacing: "0 10px",
    marginTop: "-10px",

    "& .MuiTableRow-root": {
      borderRadius: 15,
      background: '#FFF',
      cursor: "pointer",
      transition: "0.2s",

      "&:hover": {
        boxShadow: '0px 8px 24px 0px rgba(169, 169, 169, 0.15)',
        "& .MuiTableCell-root": {
          borderColor: "transparent!important"
        }
      }
    },
    "& .MuiTableRow-root .MuiTableCell-root": {
      borderTop: "1px solid #E4E4E4",
      borderBottom: "1px solid #E4E4E4",
      backgroundColor: "white!important",
      fontSize: "16px",
      lineHeight: "25px",
      transition: "0.2s",
      "&:first-child": {
        borderRadius: "15px 0 0 15px",
        borderLeft: "1px solid #E4E4E4",
      },
      "&:last-child": {
        borderRadius: "0 15px 15px 0",
        borderRight: "1px solid #E4E4E4",
      },
    },

    "& a": {
      color: palette.primary.main,
      fontWeight: "600"
    }
  }
}));

export default React.memo(OrdersInfo)
