import {palette} from "../common";

export default {
  styleOverrides: {
    root: {
      // width: 32,
      // height: 32,
      // borderRadius: 10,
      //
      // border: "2px solid",
      // borderColor: palette.primary.main,
      // position: "relative",
      //
      // "& svg": {
      //   fill: "transparent"
      // },
      // "&.Mui-checked": {
      //   backgroundColor: palette.primary.main,
      //   "&:after": {
      //     content: "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNCAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzIwOF8xODcpIj4KPHBhdGggZD0iTTEgNi41ODMzM0w1IDkuMjVMMTIuMzMzMyAxLjI1IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIuMDEwMTEiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzIwOF8xODciPgo8cmVjdCB3aWR0aD0iMTQiIGhlaWdodD0iMTEiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==)",
      //     position: "absolute",
      //     top: "50%",
      //     left: "50%",
      //     transform: "translate(-50%, calc(-50% - 2px))"
      //   }
      // },
      //
      // "&:after": {
      //   fontSize: 24,
      //   position: "absolute"
      // }
    }
  }
}
