export default {
	styleOverrides: {
		root: {
			"& .MuiTableRow-hover": {
				cursor: "pointer",
				"&:hover": {}
			}
		}
	}
}
