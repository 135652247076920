import agent from "../agent/agent";

const UPDATE_AUTH_ME = 'app/UPDATE_AUTH_ME';
const SET_ROLE = 'app/SET_ROLE';
const SET_EQUIPMENTS = 'app/SET_EQUIPMENTS';

const initialState = {
  authMe: null,
  role: null,

  equipments: [],
};

export function setAuthMe(authMe) {
  return async function (dispatch) {
    dispatch({
      type: UPDATE_AUTH_ME,
      authMe
    });
    dispatch({
      type: SET_ROLE,
      role: Boolean(authMe?.partner) ? 'partner' : 'admin'
    });
  }
}
export function getEquipments () {
  return async function (dispatch) {
    let res = await agent.get(`/equipments?limit=999&page=1`).then((res) => {
      return res.data?.equipments || []
    }).catch(() => {
      return []
    });
    res = [
      ...res,
      {id: "demo-2", locationAddress: "Демо режим"},
    ]

    dispatch({
      type: SET_EQUIPMENTS,
      equipments: res
    });
  }
}

export default function AppState(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_AUTH_ME: {
      let authMe = action.authMe

      return {
        ...state,
        authMe
      }
    }
    case SET_ROLE: {
      let role = action.role

      return {
        ...state,
        role
      }
    }
    case SET_EQUIPMENTS: {
      let equipments = action.equipments

      return {
        ...state,
        equipments
      }
    }
    default:
      return state;
  }
}
