import React from "react";
import {
  Box,
  Grid,
  Button,
  Typography,

  Dialog,
  DialogTitle,
  DialogContent
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import {Rnd} from "react-rnd";
import clsx from "clsx";
import {palette} from "../../theme/common";
import allTranslations from "../../locales/allTranslations";
import {ContrastSlider} from "../index";

class ClarificationCenterPupils extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      urlBlobLeftLeft: "",
      urlBlobLeftRight: "",
      urlBlobRightLeft: "",
      urlBlobRightRight: "",

      openPopUp: null
    }

    this.refLeftLeftImage = React.createRef();
    this.refLeftRightImage = React.createRef();
    this.refRightLeftImage = React.createRef();
    this.refRightRightImage = React.createRef();
  }

  componentDidMount = () => {
    this.setState({
      urlBlobLeftLeft: URL.createObjectURL(this.props.blobLeftLeft),
      urlBlobLeftRight: URL.createObjectURL(this.props.blobLeftRight),
      urlBlobRightLeft: URL.createObjectURL(this.props.blobRightLeft),
      urlBlobRightRight: URL.createObjectURL(this.props.blobRightRight),
    })
  }

  changeLeft = (camName, value) => {
    let form = {...this.props.form};
    form[camName]['eye_l'] = value;
    this.props.onChange(form);
  }
  changeRight = (camName, value) => {
    let form = {...this.props.form};
    form[camName]['eye_r'] = value;
    this.props.onChange(form);
  }

  onSubmit = () => {
    this.props.onSubmit({
      left_left: {
        width: this.refLeftLeftImage.current.clientWidth,
        height: this.refLeftLeftImage.current.clientHeight,
      },
      left_right: {
        width: this.refLeftRightImage.current.clientWidth,
        height: this.refLeftRightImage.current.clientHeight,
      },
      right_left: {
        width: this.refRightLeftImage.current.clientWidth,
        height: this.refRightLeftImage.current.clientHeight,
      },
      right_right: {
        width: this.refRightRightImage.current.clientWidth,
        height: this.refRightRightImage.current.clientHeight,
      },
    })
  }

  openPopUpZoom = (index) => {
    let value = null;
    let imageBlob = null;
    let urlImageBlob = null;
    let clientSizes = null;

    if (index === 0) {
      value = this.props.form?.cam0?.eye_r;
      imageBlob = this.props.blobLeftRight;
      urlImageBlob = this.state.urlBlobLeftRight;
      clientSizes = this.refLeftRightImage.current.getBoundingClientRect();
    }
    if (index === 1) {
      value = this.props.form?.cam0?.eye_l;
      imageBlob = this.props.blobLeftLeft;
      urlImageBlob = this.state.urlBlobLeftLeft;
      clientSizes = this.refLeftLeftImage.current.getBoundingClientRect();
    }
    if (index === 2) {
      value = this.props.form?.cam1?.eye_r;
      imageBlob = this.props.blobRightRight;
      urlImageBlob = this.state.urlBlobRightRight;
      clientSizes = this.refRightRightImage.current.getBoundingClientRect();
    }
    if (index === 3) {
      value = this.props.form?.cam1?.eye_l;
      imageBlob = this.props.blobRightLeft;
      urlImageBlob = this.state.urlBlobRightLeft;
      clientSizes = this.refRightLeftImage.current.getBoundingClientRect();
    }

    const openPopUp = {
      value,
      imageBlob,
      urlImageBlob,
      clientSizes,
      elementIndex: index
    };
    this.setState({
      openPopUp
    })
  }
  closePopUpZoom = () => {
    this.setState({
      openPopUp: null
    })
  }
  submitPopUpZoom = (value, index) => {
    if (index === 0) {
      this.changeRight('cam0', value)
    }
    if (index === 1) {
      this.changeLeft('cam0', value)
    }
    if (index === 2) {
      this.changeRight('cam1', value)
    }
    if (index === 3) {
      this.changeLeft('cam1', value)
    }
    this.closePopUpZoom();
  }

  _getImagesClientSize = async () => {
    return {
      left_left: {
        width: this.refLeftLeftImage.current.clientWidth,
        height: this.refLeftLeftImage.current.clientHeight,
      },
      left_right: {
        width: this.refLeftRightImage.current.clientWidth,
        height: this.refLeftRightImage.current.clientHeight,
      },
      right_left: {
        width: this.refRightLeftImage.current.clientWidth,
        height: this.refRightLeftImage.current.clientHeight,
      },
      right_right: {
        width: this.refRightRightImage.current.clientWidth,
        height: this.refRightRightImage.current.clientHeight,
      },
    }
  }

  render() {
    const {
      form,
      classes,
      blobLeftLeft,
      blobLeftRight,
      blobRightLeft,
      blobRightRight
    } = this.props;
    const {
      openPopUp,
      focusSection,
      urlBlobLeftLeft,
      urlBlobLeftRight,
      urlBlobRightLeft,
      urlBlobRightRight,
    } = this.state;

    return (
      <Grid container spacing={4}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{color: "black"}}>
              {allTranslations('Камера')} 0
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box className={clsx({[classes.sectionImage1]: true})} onClick={this.openPopUpZoom.bind(this, 0)}>
              <div className="panningDisabled" style={{backgroundImage: `url(${urlBlobLeftRight})`}}>
                <img ref={this.refLeftRightImage} src={URL.createObjectURL(blobLeftRight)}/>
                <RndComponent
                  value={form?.cam0?.eye_r}
                  classes={classes}
                />
              </div>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className={clsx({[classes.sectionImage1]: true})} onClick={this.openPopUpZoom.bind(this, 1)}>
              <div className="panningDisabled" style={{backgroundImage: `url(${urlBlobLeftLeft})`}}>
                <img ref={this.refLeftLeftImage} src={URL.createObjectURL(blobLeftLeft)}/>
                <RndComponent
                  value={form?.cam0?.eye_l}
                  classes={classes}
                />
              </div>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{color: "black"}}>{allTranslations('Камера')} 1</Typography>
          </Grid>
          <Grid item xs={6}>
            <Box className={clsx({[classes.sectionImage1]: true})} onClick={this.openPopUpZoom.bind(this, 2)}>
              <div className="panningDisabled" style={{backgroundImage: `url(${urlBlobRightRight})`}}>
                <img ref={this.refRightRightImage} src={URL.createObjectURL(blobRightRight)}/>
                <RndComponent
                  value={form?.cam1?.eye_r}
                  classes={classes}
                />
              </div>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className={clsx({[classes.sectionImage1]: true})} onClick={this.openPopUpZoom.bind(this, 3)}>
              <div className="panningDisabled" style={{backgroundImage: `url(${urlBlobRightLeft})`}}>
                <img ref={this.refRightLeftImage} src={URL.createObjectURL(blobRightLeft)}/>
                <RndComponent
                  value={form?.cam1?.eye_l}
                  classes={classes}
                />
              </div>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" fullWidth onClick={this.onSubmit}>
            {allTranslations('Зафиксировать')}
          </Button>
        </Grid>


        {Boolean(openPopUp) && (
          <PopUpZoomElement
            value={openPopUp.value}
            imageBlob={openPopUp.imageBlob}
            urlImageBlob={openPopUp.urlImageBlob}
            clientSizes={openPopUp.clientSizes}
            elementIndex={openPopUp.elementIndex}
            classes={classes}

            onClose={this.closePopUpZoom}
            onSubmit={this.submitPopUpZoom}
          />
        )}
      </Grid>
    )
  }
}
class PopUpZoomElement extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: {x: 0, y: 0},
      orientation: "height"
    }

    this.refImage = React.createRef();
    this.refContent = React.createRef();
  }

  componentDidMount = () => {
    this.initialProportion();
  }
  componentWillUnmount = () => {
    window.removeEventListener("keydown", this.eventKeyboard)
  }


  eventKeyboard = (event) => {
    const { value } = this.state;

    // UP
    if (event.keyCode === 38) {
      this.setState({value: {x: value.x, y: value.y - 1}})
    }

    // DOWN
    if (event.keyCode === 40) {
      this.setState({value: {x: value.x, y: value.y + 1}})
    }

    // RIGHT
    if (event.keyCode === 39) {
      this.setState({value: {x: value.x + 1, y: value.y}})
    }

    // LEFT
    if (event.keyCode === 37) {
      this.setState({value: {x: value.x - 1, y: value.y}})
    }
  }

  initialProportion = () => {
    if (!this.refContent.current || !this.refImage.current) {
      setTimeout(() => {
        this.initialProportion();
      }, 500);
      return
    }

    const {
      width: imageWidth,
      height: imageHeight
    } = this.refImage.current.getBoundingClientRect();

    const {
      width: contentWidth,
      height: contentHeight
    } = this.refContent.current.getBoundingClientRect();

    if (contentWidth > imageWidth) {
      this.initialValues();
      return;
    }

    this.setState({
      orientation: "width"
    }, () => {
      this.initialValues();
    })
  }
  initialValues = () => {
    const { value, clientSizes } = this.props;
    const {
      width: originalWidth,
      height: originalHeight
    } = clientSizes;
    const {
      width: clientWidth,
      height: clientHeight
    } = this.refImage.current.getBoundingClientRect();

    const xScale = originalWidth / clientWidth;
    const yScale = originalHeight / clientHeight;

    const x = value.x / xScale;
    const y = value.y / yScale;

    this.setState({
      value: {x, y}
    })
    window.addEventListener("keydown", this.eventKeyboard)
  }

  changeValue = (value) => {
    this.setState({ value });
  }
  submitValue = () => {
    const { value } = this.state;
    const { elementIndex, clientSizes } = this.props;
    const {
      width: originalWidth,
      height: originalHeight
    } = clientSizes;
    const {
      width: clientWidth,
      height: clientHeight
    } = this.refImage.current.getBoundingClientRect();

    const xScale = originalWidth / clientWidth;
    const yScale = originalHeight / clientHeight;

    const x = value.x * xScale;
    const y = value.y * yScale;

    this.props.onSubmit({x, y}, elementIndex);
  }

  changeContrast = (value) => {
    const elements = document.querySelectorAll('.image-contrast-settings');
    elements.forEach((element) => {
      element.style.filter = `contrast(${value+100}%)`
    });
  }

  render() {
    const {
      classes,
      imageBlob,
      urlImageBlob,

      onClose
    } = this.props;
    const {
      value,
      orientation
    } = this.state;

    return (
      <Dialog
        open={true}
        maxWidth="lg"
        fullWidth={true}
        fullScreen={true}
      >
        <DialogTitle>
          <Typography variant="h3">
            {allTranslations('Установка центра зрачка глаза')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            ref={this.refContent}
            className={clsx({
              [classes.popUpZoomSection]: true,
              "width": Boolean(orientation === "width"),
              "height": Boolean(orientation === "height"),
            })}
          >
            <div className="panningDisabled" style={{backgroundImage: `url(${urlImageBlob})`}}>
              <img ref={this.refImage} src={URL.createObjectURL(imageBlob)} className="image-contrast-settings"/>
              <RndComponent
                value={value}
                focused={true}
                classes={classes}
                onChange={this.changeValue}
              />
            </div>
          </Box>
          <Box mt={1}/>
          <ContrastSlider
            value={0}
            onChange={this.changeContrast}
          />
          <Box mt={1}/>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button variant="outlined" onClick={onClose}>
                {allTranslations('Отменить')}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={this.submitValue}>
                {allTranslations('Зафиксировать')}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  }
}

const RndComponent = React.memo((props) => {
  const {
    value,
    classes,
    onChange
  } = props;
  const refRnd = React.createRef();
  const [position, setPosition] = React.useState(value);

  React.useEffect(() => {
    setPosition(value);
  }, [value]);

  const handelChangePosition = (e, d) => {
    if (!onChange) {
      return
    }

    const pX = d.x + 20;
    const pY = d.y + 20;

    setPosition({x: pX, y: pY});
    onChange({x: pX, y: pY});
  }

  return (
    <Rnd
      ref={refRnd}
      size={{width: 40, height: 40}}
      position={{x: position.x - 20, y: position.y - 20}}
      onDragStop={handelChangePosition}
      lockAspectRatio={1}
      enableResizing={0}
      bounds="parent"
      disableDragging={Boolean(!onChange)}
      className={clsx({
        "panningDisabled": true,
        [classes.areaEyepiece]: true
      })}
    >
      <img src={require("../../assets/image/center.svg").default}/>
    </Rnd>
  )
})

const styles = {
  sectionImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 0 0 1px rgba(0,0,0,0.1)",
    borderRadius: "10px",
    position: "relative",
    overflow: "hidden",
    height: 360,

    "& > div": {
      display: "flex",
      overflow: "hidden",
      height: 360,
      maxWidth: "100%",
      maxHeight: "100%"
    },
    "& > div > img": {
      maxWidth: "100%",
      maxHeight: "100%"
    }
  },
  areaEyepiece: {
    display: "flex!important",
    position: "absolute",
    userSelect: "none",
    borderRadius: "100%",
    backgroundSize: "contain",

    "& img": {
      width: "42px",
      height: "42px",
      objectFit: "contain",
      pointerEvents: "none",
      filter: "invert(90%) sepia(24%) saturate(5498%) hue-rotate(31deg) brightness(110%) contrast(105%)",
      position: "absolute",
      top: "50%", left: "50%",
      transform: "translate(-50%, -50%)"
    }
  },

  sectionImage1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: "10px",
    position: "relative",
    overflow: "hidden",
    height: 360,
    cursor: "pointer",
    transition: "all 0.3s",

    "& > div": {
      display: "flex",
      position: "relative",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat"
    },
    "& > div > img": {
      maxWidth: "100%",
      maxHeight: "360px",
      opacity: 0
    },
    "&.focus": {
      borderColor: palette.primary.main
    },
    "&:hover": {
      boxShadow: "0px 8px 24px 0px rgba(169, 169, 169, 0.15)",
    }
  },

  popUpZoomSection: {
    margin: "0 auto",
    width: 'calc(100vw - 100px)',
    height: 'calc(100vh - 200px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: 10,
    overflow: "hidden",

    "& > div": {
      display: "flex",
      position: "relative",
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      margin: '0 auto',
    },
    "&.height > div": {
      height: '-webkit-fill-available',
    }
  }
};
ClarificationCenterPupils = withStyles(styles)(ClarificationCenterPupils)

export default ClarificationCenterPupils
