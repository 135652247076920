import React, {Component} from "react";
import {} from "@mui/material";
import {
  Notification,
  notificationTypes
} from "../../common/Notification";
import agent from "../../agent/agent";
import allTranslations from "../../locales/allTranslations";

class PartnerView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      partner: {},
      partnerId: props?.match?.params?.id
    };
  }

  componentDidMount = async () => {
    await this.getPartner();
  }

  getPartner = async () => {
    const res = await agent.get(`/partners/${ this.state.partnerId }`).then((res) => {
      return res.data?.partner || null
    }).catch(() => {
      return null
    })
    if (!res) {
      Notification({
        message: allTranslations('Партнер не найден'),
        type: notificationTypes.error
      });
      this.props.history.push('/partners');
      return
    }
    console.log('res: ', res);
  }

  render() {
    return (
      <>

      </>
    );
  }
}

export default PartnerView
