import React from "react";
import {ThemeProvider} from "@mui/material/styles";
import {StylesProvider, jssPreset} from "@mui/styles";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {create} from 'jss';
import {ReactNotifications} from 'react-notifications-component'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import theme from "./theme";
import ruLocale from 'date-fns/locale/ru';

import storeRedux from "./redux/store";
import Router from "./router";
import agent from "./agent/agent";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point'),
});
const localeMap = {
  ru: ruLocale
};

class App extends React.Component {

  componentDidMount = () => {
    this.setContentMinWidth();
  }

  setContentMinWidth = () => {
    if (window.innerWidth > 1200) {
      return
    }

    const mvp = document.getElementById('myViewport');
    mvp.setAttribute('content','width=1200');
  }

  render () {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap.ru}>
        <ThemeProvider theme={theme}>
          <StylesProvider jss={jss}>
            <Provider store={storeRedux}>
              <ReactNotifications/>

              <BrowserRouter>
                <Router/>
              </BrowserRouter>
            </Provider>
          </StylesProvider>
        </ThemeProvider>
      </LocalizationProvider>
    )
  }
}

export default App
