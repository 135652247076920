import React from "react";
import {Switch, Redirect} from "react-router-dom";
import {
  RouteWithLayout
} from "../components";
import {
  Cabinet as CabinetLayouts
} from "../layouts";
import {
  Users as UsersPage,
  Orders as OrdersPage,
  Patients as PatientsPage,
  Partners as PartnersPage,
  Equipment as EquipmentPage,
  OrderView as OrderViewPage,
  Calibration as CalibrationPage,
  PatientView as PatientViewPage,
  PartnerView as PartnerViewPage,
  CreateFrom1c as CreateFrom1cPage,
  RegistrationUser as RegistrationUserPage,
} from "../pages";


const pages = [
  {
    path: '/calibration',
    component: CalibrationPage,
    layout: CabinetLayouts,
    rules: [],
    exact: true
  },
  {
    path: '/users',
    component: UsersPage,
    layout: CabinetLayouts,
    rules: [],
    exact: true
  },
  {
    path: '/partners',
    component: PartnersPage,
    layout: CabinetLayouts,
    rules: [],
    exact: true
  },
  {
    path: '/partners/:id',
    component: PartnerViewPage,
    layout: CabinetLayouts,
    rules: [],
    exact: true
  },
  {
    path: '/equipment',
    component: EquipmentPage,
    layout: CabinetLayouts,
    rules: [],
    exact: true
  },
  {
    path: '/orders',
    component: OrdersPage,
    layout: CabinetLayouts,
    rules: [],
    exact: true
  },
  {
    path: '/orders/:id',
    component: OrderViewPage,
    layout: CabinetLayouts,
    rules: [],
    exact: true
  },
  {
    path: '/patients',
    component: PatientsPage,
    layout: CabinetLayouts,
    rules: [],
    exact: true
  },
  {
    path: '/patients/:id',
    component: PatientViewPage,
    layout: CabinetLayouts,
    rules: [],
    exact: true
  },
  {
    path: '/create-from-1c',
    component: CreateFrom1cPage,
    layout: CabinetLayouts,
    rules: [],
    exact: true
  },
];
const localPages = [
  {
    path: '/',
    component: RegistrationUserPage,
    layout: CabinetLayouts,
    rules: [],
    exact: true
  },
  ...pages
]

const RegistrationRoutes = () => {
  return (
    <Switch>
      {
        localPages.map((page, idx) => (
          <RouteWithLayout
            key={'page-' + idx}
            {...page}
          />
        ))
      }

      <Redirect to="/"/>
    </Switch>
  );
};

export { pages };
export default RegistrationRoutes;
