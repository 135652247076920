import React from "react";
import {
  Box,
  Grid,
} from "@mui/material";
import {
  makeStyles
} from "@mui/styles";
import clsx from "clsx";

class Stages extends React.PureComponent {
  render () {
    const {
      stages,
      active,

      changeStage
    } = this.props;

    return (
      <Box display="flex" pl="20px">
        {stages.map((stage, index) => (
          <StageButton
            stage={stage}
            first={Boolean(index === 0)}
            active={Boolean(active === index)}
            last={!Boolean(stage.disabledNext)}
            zIndex={stages.length - index}
            disabled={Boolean(stage.disabledNext)}

            onClick={changeStage.bind(this, index)}
          />))
        }
      </Box>
    )
  }
}
const StageButton = React.memo((props) => {
  const {
    stage,
    first,
    active,
    last,
    zIndex,
    disabled,

    onClick
  } = props;
  const classes = useStyles();

  const handleClick = () => {
    if (disabled) {
      return
    }
    onClick();
  }

  return (
    <Box
      className={clsx({
        [classes.stage]: true,
        '--first': first,
        '--active': active,
        '--last': Boolean(!active && last),
      })}
      zIndex={zIndex}
      onClick={handleClick}
    >
      <img className={classes.stageImage} src={ stage.icon }/>
      <span className={classes.stageLabel}>{ stage.label }</span>
    </Box>
  )
});

const useStyles = makeStyles(() => ({
  stage: {
    display: "flex",
    alignItems: "center",
    flex: "auto",
    padding: "15px 20px",
    paddingLeft: "40px",
    boxSizing: "border-box",
    borderRadius: "15px",
    border: "2px solid #FFF",
    background: "#F2F3F6",
    marginLeft: "-20px",
    cursor: "pointer",
    position: "relative",

    "&.--first": {
      paddingLeft: 20
    },
    "&.--active": {
      background: "#A72681",
      borderColor: "#A72681",
      zIndex: 10,

      "& $stageImage": {
        filter: "invert(1)"
      },
      "& $stageLabel": {
        color: "white",
        fontWeight: "500"
      },
    },
    "&.--last": {
      background: "#C582B1",
      "& $stageImage": {
        filter: "invert(1)"
      },
      "& $stageLabel": {
        color: "white"
      },
    }
  },
  stageImage: {
    width: 24,
    height: 24,
    objectFit: "contain",
    marginRight: 10
  },
  stageLabel: {
    color: "#5B5B5B",
    fontFamily: "Montserrat",
    fontSize: "14px",
    lineHeight: "20px",
    whiteSpace: "nowrap"
  }
}));

export default Stages
