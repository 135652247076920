import React, {Component} from "react";
import {
  Grid,
  Button,
  Backdrop,
  CircularProgress
} from "@mui/material";
import {
  Table,
  DialogForm
} from "./components";
import {
  Notification,
  notificationTypes
} from "../../common/Notification";
import {
  DialogConfirmation
} from "../../components";
import agent from "../../agent/agent";
import allTranslations from "../../locales/allTranslations";

class Partners extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      filter: {
        page: 1
      },
      pagination: {
        total: 1
      },
      isLoad: true,
      isBackdrop: false,
    };

    this.refDialogForm = React.createRef();
    this.refDialogConfirmation = React.createRef();
  }

  componentDidMount = async () => {
    await this.getData();
  }

  getData = async () => {
    this.setState({isLoad: true})

    const res = await agent.get(`/partners?limit=20&page=${this.state.filter.page}`).then((res) => {
      return res.data
    }).catch(() => {
      return null
    });
    const pagination = {
      total: Math.ceil(res?.count / 20)
    }

    this.setState({
      data: res?.partners,
      pagination,
      isLoad: false
    })
  }

  createPartner = async (form, isSubmit) => {
    if (!isSubmit) {
      this.refDialogForm.current.open({
        onSubmit: this.createPartner.bind(this)
      })
      return
    }

    this.setState({ isBackdrop: true })

    form.contactPhone = `7${(form.contactPhone || "").replace(/\D+/g,"")}`;
    const res = await agent.post('/partners', form).then((res) => {
      return true
    }).catch(() => {
      return false
    });
    if (!res) {
      this.setState({ isBackdrop: false });
      Notification({
        title: allTranslations("Ошибка"),
        message: allTranslations("Ошибка сервера"),
        type: notificationTypes.error
      })

      return
    }

    await this.getData();
    this.refDialogForm.current.close();
    this.setState({ isBackdrop: false });
    Notification({
      title: allTranslations('Успешно'),
      message: allTranslations('Партнер успешно добавлен'),
      type: notificationTypes.success
    });
  }
  editPartner = async (form, isSubmit) => {
    if (!isSubmit) {
      let initForm = {...form};
      initForm.contactPhone = (initForm?.contactPhone || '').substring(1);

      this.refDialogForm.current.open({
        form: initForm,
        onSubmit: this.editPartner.bind(this)
      })

      return
    }

    this.setState({ isLoad: true });


    const partnerId = form.id;
    const prevForm = (this.state.data || []).find((t) => t.id === partnerId);

    delete form.createdAt;
    delete form.equipments;
    delete form.id;
    delete form.updatedAt;
    delete form.users;
    Object.keys(form).map((key) => {
      if (form[key] === prevForm[key]) {
        delete form[key]
      }
    });

    form.contactPhone = `7${(form.contactPhone || "").replace(/\D+/g,"")}`;
    const res = await agent.patch(`/partners/${partnerId}`, form).then(() => {
      return true
    }).catch(() => {
      return false
    });
    if (!res) {
      this.setState({ isLoad: false });
      Notification({
        title: allTranslations("Ошибка"),
        message: allTranslations('Ошибка сервера'),
        type: notificationTypes.error
      })

      return
    }

    this.refDialogForm.current.close();
    await this.getData();
    this.setState({ isLoad: false });
    Notification({
      title: allTranslations('"Успешно"'),
      message: allTranslations('Партнер успешно изменен'),
      type: notificationTypes.success
    })
  }
  deletePartner = async (partnerId, isConfirm) => {
    if (!isConfirm) {
      this.refDialogConfirmation.current.open({
        title: allTranslations('Подтверждение'),
        message: allTranslations("Вы действительно хотите удалить партнера?"),

        onSubmit: this.deletePartner.bind(this, partnerId, true)
      })
      return
    }

    this.setState({ isBackdrop: true });

    const res = await agent.delete(`/partners/${partnerId}`).then((res) => {
      return true
    }).catch(() => {
      return false
    });
    if (!res) {
      this.setState({ isBackdrop: false });
      Notification({
        title: allTranslations('Ошибка'),
        message: allTranslations("Ошибка сервера"),
        type: notificationTypes.error
      })
      return
    }

    await this.getData();
    this.setState({ isBackdrop: false });
    Notification({
      title: allTranslations("Успешно"),
      message: allTranslations('Партнер успешно удален'),
      type: notificationTypes.success
    })
  }

  changeFilter = async (filter) => {
    await this.setState({ filter });
    await this.getData();
  }

  render() {
    const {
      data,
      filter,
      pagination,
      isLoad,
      isBackdrop
    } = this.state;

    return (
      <>

        <Grid container mb={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              onClick={this.createPartner.bind(this, null, false)}
            >
              {allTranslations('Добавить')}
            </Button>
          </Grid>
        </Grid>

        <Table
          data={data}
          filter={filter}
          pagination={pagination}
          isLoad={isLoad}

          onEdit={this.editPartner}
          onRemove={this.deletePartner}
          onChangePage={this.changeFilter}
        />

        <DialogForm
          ref={this.refDialogForm}
        />
        <DialogConfirmation
          ref={this.refDialogConfirmation}
        />

        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>

      </>
    );
  }
}

export default Partners
