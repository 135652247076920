import React, {Component} from "react";
import {
  Box,
  Backdrop,
  Container,
  CircularProgress,
} from "@mui/material";
import {
  StereoCameraShot, // Стереокамера
  RefinementEyepieceArea, // Уточнение области окуляров
  ClarificationCenterPupils, // Уточнение центра зрачков
  ContourRefinement,

  DialogNotification,
  DialogSelectedEquipment
} from "../../components";
import {
  Stages as StagesComponent,
  ResultForm as ResultFormComponent
} from "./components";
import {
  checkImageasdasdasdasd,
  cropImageByArea, getImageBlobFromOrder, getOriginalSizeImage,
  splitImage
} from "../../helpers/snapshotVideo";
import {
  Notification,
  notificationTypes
} from "../../common/Notification";
import agent from "../../agent/agent";
import {getPositionGyroscope} from "../../helpers/calibration";
import allTranslations from "../../locales/allTranslations";
import getAllCameras from "../../helpers/getAllCameras";

class FrameMeasurement extends Component {
  constructor(props) {
    super(props);

    const orderId = ((props?.location?.search || '').split("order=")?.[1] || "").split("&")?.[0];

    this.state = {
      formValues: {
        stage1: {
          blob: null,
          blobLeft: null,
          blobRight: null,
        },
        stage2: {
          "cam_0": {
            "rect_r": {
              "x": 0,
              "y": 0,
              "width": 120,
              "height": 60
            },
            "rect_l": {
              "x": 0,
              "y": 0,
              "width": 120,
              "height": 60
            }
          },
          "cam_1": {
            "rect_r": {
              "x": 0,
              "y": 0,
              "width": 120,
              "height": 60
            },
            "rect_l": {
              "x": 0,
              "y": 0,
              "width": 120,
              "height": 60
            }
          },
          scaleInfoCam0: {},
          scaleInfoCam1: {},

          blobLeftLeft: null,
          blobLeftRight: null,
          blobRightLeft: null,
          blobRightRight: null,
        },
        stage3: {
          "cam0": {
            "eye_r": {
              "x": 0,
              "y": 0
            },
            "eye_l": {
              "x": 0,
              "y": 0
            }
          },
          "cam1": {
            "eye_r": {
              "x": 0,
              "y": 0
            },
            "eye_l": {
              "x": 0,
              "y": 0
            }
          }
        },
        stage4: {
          "coords": {
            "cam_0": {
              "cont_l": [],
              "cont_r": []
            },
            "cam_1": {
              "cont_l": [],
              "cont_r": []
            },
          }
        },
      },
      resultat: {},
      lineCounters: {},
      eyepiecesAreaToBack: {},

      stages: [
        {disabledNext: false, label: allTranslations('Съёмка'), icon: require("../../assets/image/measurements/camera.svg").default},
        {disabledNext: true, label: allTranslations('Уточнение области окуляров'),icon: require("../../assets/image/measurements/eyepiece-area.svg").default},
        {disabledNext: true, label: allTranslations('Уточнение центров зрачка'), icon: require("../../assets/image/measurements/pupil-centers.svg").default},
        {disabledNext: true, label: allTranslations('Уточнение контуров линз'), icon: require("../../assets/image/measurements/contours.svg").default},
        {disabledNext: true, label: allTranslations('Результат'), icon: require("../../assets/image/measurements/result.svg").default},
      ],

      orderId: orderId || null,
      activeStage: 0,
      sessionId: null,
      equipmentId: null,
      selectedCameraId: null,
      order: null,
      calculation: null,
      usbProductId: null,

      isResult: false,
      isBackdrop: false,
      isFullDisabled: false
    };
    this.refContourRefinement = React.createRef();
    this.refClarificationCenterPupils = React.createRef();
    this.refDialogNotification = React.createRef();
    this.refDialogSelectedEquipment = React.createRef();
    this.refRefinementEyepieceArea = React.createRef();
  }

  componentDidMount = async () => {
    await this.initEquipment();
    await this.initFormValues();
  }

  initEquipment = async ({ isOrderSelectEquipment } = {}) => {
    const { orderId } = this.state;
    let equipments = this.props?.equipments || [];
    if (!!orderId) {
      equipments = equipments.filter((t) => !['demo', 'demo-2'].includes(t.id))
    }

    const listCamera = await getAllCameras();
    const findEquipments = equipments.find((t) => listCamera.find((c) => c.deviceId === t.externalId));
    if (!!findEquipments && false) {
      this.setState({
        equipmentId: findEquipments?.id
      });
      return
    }

    if (!!orderId && !isOrderSelectEquipment) {
      equipments = equipments.filter((t) => !['demo', 'demo-2'].includes(t.id));
      return
    }
    if (equipments.length <= 0) {
      this.refDialogNotification.current.open({
        message: allTranslations('У вас не найдено не одного оборудования')
      })
      this.setState({isFullDisabled: true});
      return
    }
    if (equipments.length > 1) {
      this.refDialogSelectedEquipment.current.open({
        list: equipments,
        onSubmit: this.selectedEquipments.bind(this)
      })
      return
    }
    this.setState({
      equipmentId: equipments?.[0]?.id
    })
  }
  selectedEquipments = (selectedEquipment) => {
    if (selectedEquipment === "demo") {
      (async () => {
        await this.initDemoMode();
      })();
      return
    }
    if (selectedEquipment === "demo-2") {
      (async () => {
        await this.initDemoMode2();
      })();
      return
    }
    this.setState({
      equipmentId: selectedEquipment,
      isFullDisabled: false
    });
  }

  initDemoMode = async () => {
    const demoId = 635;
    const order = await agent.get(`/orders/${ demoId }`).then((res) => {
      return res.data?.order || null
    }).catch(() => {
      return null
    });
    const calculation = await agent.get(`/calculation`).then((res) => {
      return (res.data?.calculations || []).find((t) => t?.id === (order?.calculations?.[0]?.id))
    }).catch(() => {
      return null
    });
    const blobLeft = await getImageBlobFromOrder(calculation?.pictures?.[0]);
    const blobRight = await getImageBlobFromOrder(calculation?.pictures?.[1]);

    let formValues = {...this.state.formValues};
    formValues.stage1.blob = null;
    formValues.stage1.blobLeft = blobLeft;
    formValues.stage1.blobRight = blobRight;

    await this.setState({
      formValues,
      calculation,
      activeStage: 1,
      equipmentId: 1,
      isFullDisabled: false,
      sessionId: calculation.id
    });
    await this.initFormStage2(calculation);
  }
  initDemoMode2 = async ({ useOrderId } = {}) => {
    this.setState({isBackdrop: true});

    const demoId = Boolean(useOrderId) ? this.state.orderId : 628;
    const _order = await agent.get(`/orders/${ demoId }`).then((res) => {
      return res.data?.order || null
    }).catch(() => {
      return null
    });
    const calculation = await agent.get(`/calculation`).then((res) => {
      return (res.data?.calculations || []).find((t) => t?.id === (_order?.calculations?.[0]?.id))
    }).catch(() => {
      return null
    });
    const blobLeft = await getImageBlobFromOrder(calculation?.pictures?.[0]);
    const blobRight = await getImageBlobFromOrder(calculation?.pictures?.[1]);

    const {patient} = this.props;
    const body = {
      patient: patient,
      partnerId: this.props.authMe?.partnerId || 1
    };
    const order = await agent.post('/orders', body).then((res) => {
      return res.data?.order
    }).catch(() => {
      return null
    })

    const formData = new FormData();
    formData.append("equipmentId", 58);
    formData.append("orderId", order?.id);
    formData.append("coords", calculation.coords);
    formData.append("files", blobLeft);
    formData.append("files", blobRight);

    const resCalculateEyepiecesArea = await agent.post('/calculation/calculate-eyepieces-area', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {
      return res.data?.calculation
    }).catch((err) => {
      return {error: err.response}
    });
    if (!resCalculateEyepiecesArea) {
      this.setState({isBackdrop: false});
      Notification({
        message: allTranslations('Ошибка сервера'),
        type: notificationTypes.error
      })

      return
    }

    let formValues = {...this.state.formValues};
    formValues.stage1.blob = null;
    formValues.stage1.blobLeft = blobLeft;
    formValues.stage1.blobRight = blobRight;

    await this.setState({
      formValues,
      activeStage: 1,
      sessionId: resCalculateEyepiecesArea?.id,
      isBackdrop: false,
      order
    });

    await this.initFormStage2(resCalculateEyepiecesArea);
  }
  initEditOrder = async () => {
    this.setState({isBackdrop: true});

    const demoId = this.state.orderId;
    const _order = await agent.get(`/orders/${ demoId }`).then((res) => {
      return res.data?.order || null
    }).catch(() => {
      return null
    });
    const calculation = await agent.get(`/calculation`).then((res) => {
      return (res.data?.calculations || []).find((t) => t?.id === (_order?.calculations?.[0]?.id))
    }).catch(() => {
      return null
    });
    if (!calculation) {
      await this.setState({
        activeStage: 0,
        isBackdrop: false,
        order: _order
      });
      await this.initEquipment({ isOrderSelectEquipment: true })
      return
    }

    const blobLeft = await getImageBlobFromOrder(calculation?.pictures?.[0]);
    const blobRight = await getImageBlobFromOrder(calculation?.pictures?.[1]);

    const {patient} = this.props;
    const body = {
      patient: patient,
      partnerId: this.props.authMe?.partnerId || 1
    };
    const order = await agent.post('/orders', body).then((res) => {
      return res.data?.order
    }).catch(() => {
      return null
    })

    const formData = new FormData();
    formData.append("equipmentId", 1);
    formData.append("orderId", order?.id);
    formData.append("coords", calculation.coords);
    formData.append("files", blobLeft);
    formData.append("files", blobRight);

    const resCalculateEyepiecesArea = await agent.post('/calculation/calculate-eyepieces-area', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {
      return res.data?.calculation
    }).catch((err) => {
      return {error: err.response}
    });
    if (!resCalculateEyepiecesArea) {
      this.setState({isBackdrop: false});
      Notification({
        message: allTranslations('Ошибка сервера'),
        type: notificationTypes.error
      })

      return
    }

    let formValues = {...this.state.formValues};
    formValues.stage1.blob = null;
    formValues.stage1.blobLeft = blobLeft;
    formValues.stage1.blobRight = blobRight;

    await this.setState({
      formValues,
      activeStage: 1,
      sessionId: resCalculateEyepiecesArea?.id,
      isBackdrop: false,
      order
    });

    await this.initFormStage2(resCalculateEyepiecesArea);
  }

  initFormValues = async () => {
    if (this.state.orderId) {
      await this.initEditOrder();
      return
    }

    const patientId = this.props.patient?.id;
    const calculations = await agent.get(`/calculation/${patientId}`).then((res) => {
      return res.data?.calculations
    }).catch(() => {
      return []
    });
    if (!calculations.length) {
      this.setState({isBackdrop: false})
      return
    }
  }

  changeStage = (activeStage) => {
    this.setState({activeStage});
  }
  changeSelectedCamera = (selectedCameraId) => {
    this.setState({ selectedCameraId })
  }

  // Логика работы с этапом съемки
  changeFormStage1 = async (blob, gyroscope) => {
    this.setState({isBackdrop: true});

    const {patient} = this.props;
    const body = {
      patient: patient,
      partnerId: this.props.authMe?.partnerId || 1
    };
    const order = this.state.order || await agent.post('/orders', body).then((res) => {
      return res.data?.order
    }).catch(() => {
      return null
    })

    const images = await splitImage(blob, {
      width: 3040,
      height: 1520,
    });
    const formData = new FormData();
    formData.append("equipmentId", this.state.equipmentId);
    formData.append("orderId", order?.id);
    formData.append("coords", JSON.stringify(gyroscope));
    images.map((image) => {
      formData.append("files", image);
    });

    const resCalculateEyepiecesArea = await agent.post('/calculation/calculate-eyepieces-area', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {
      return res.data?.calculation
    }).catch((err) => {
      return {error: err.response}
    });
    if (!resCalculateEyepiecesArea) {
      this.setState({isBackdrop: false});
      Notification({
        message: allTranslations('Ошибка сервера'),
        type: notificationTypes.error
      })

      return
    }

    let formValues = {...this.state.formValues};
    formValues.stage1.blob = blob;
    formValues.stage1.blobLeft = images[0];
    formValues.stage1.blobRight = images[1];

    await this.changeStageDisabledNext(0);

    await this.setState({
      formValues,
      activeStage: 1,
      sessionId: resCalculateEyepiecesArea?.id,
      isBackdrop: false,
      order,

      usbProductId: gyroscope.usbProductId
    });

    await this.initFormStage2(resCalculateEyepiecesArea);
  }

  // Логика работы с этапом уточнения области окуляров
  initFormStage2 = async (resCalculateEyepiecesArea) => {
    if (!this.refRefinementEyepieceArea.current) {
      setTimeout(async () => {
        await this.initFormStage2(resCalculateEyepiecesArea);
      }, 100);
      return
    }

    const {width: clientWidth} = this.refRefinementEyepieceArea.current.getSizeSection();
    const getParamsScale = (params) => {
      const scale = 1520 / clientWidth;

      return {
        x: params.x / scale,
        y: params.y / scale,
        width: params.width / scale,
        height: params.height / scale
      }
    }

    let formValues = {...this.state.formValues};
    formValues.stage2 = {
      ...formValues.stage2,
      "cam_0": {
        "rect_r": getParamsScale(resCalculateEyepiecesArea?.eyepiecesArea?.cam_0?.cont_r),
        "rect_l": getParamsScale(resCalculateEyepiecesArea?.eyepiecesArea?.cam_0?.cont_l)
      },
      "cam_1": {
        "rect_r": getParamsScale(resCalculateEyepiecesArea?.eyepiecesArea?.cam_1?.cont_r),
        "rect_l": getParamsScale(resCalculateEyepiecesArea?.eyepiecesArea?.cam_1?.cont_l)
      },
    };

    await this.changeStageDisabledNext(1);

    this.setState({
      formValues
    })
  }
  changeFormStage2 = async (form) => {
    let formValues = {...this.state.formValues};
    formValues.stage2 = form;
    await this.changeStageDisabledNext(1);
    this.setState({formValues});
  }
  submitFormStage2 = async (clientSizes) => {
    this.setState({isBackdrop: true});

    const originalWidth = 1520;
    const originalHeight = 1520;
    let stage2 = {...this.state.formValues.stage2};

    const roundNumberForGetSize = (val) => {
      if (Number.isInteger(val)) {
        return val
      }

      return Math.floor(val)
    }
    const getOriginalDataForm = (sizes, data) => {
      const clientLeftWidth = sizes?.width;
      const clientLeftHeight = sizes?.height;
      const percentScaleLeftWidth = (((originalWidth / sizes?.width) * 100) - 100) / 100;
      const percentScaleLeftHeight = (((originalWidth / sizes?.height) * 100) - 100) / 100;

      return {
        cont_l: {
          width: roundNumberForGetSize(data.rect_l.width + (percentScaleLeftWidth * data.rect_l.width)),
          height: roundNumberForGetSize(data.rect_l.height + (percentScaleLeftHeight * data.rect_l.height)),
          x: roundNumberForGetSize(data.rect_l.x + (percentScaleLeftWidth * data.rect_l.x)),
          y: roundNumberForGetSize(data.rect_l.y + (percentScaleLeftHeight * data.rect_l.y)),

          percentScaleWidth: percentScaleLeftWidth,
          percentScaleHeight: percentScaleLeftHeight,
        },
        cont_r: {
          width: roundNumberForGetSize(data.rect_r.width + (percentScaleLeftWidth * data.rect_r.width)),
          height: roundNumberForGetSize(data.rect_r.height + (percentScaleLeftHeight * data.rect_r.height)),
          x: roundNumberForGetSize(data.rect_r.x + (percentScaleLeftWidth * data.rect_r.x)),
          y: roundNumberForGetSize(data.rect_r.y + (percentScaleLeftHeight * data.rect_r.y)),

          percentScaleWidth: percentScaleLeftWidth,
          percentScaleHeight: percentScaleLeftHeight,
        },
      }
    };

    const scaneInfoCam0 = getOriginalDataForm(clientSizes?.clientSizeLeft, stage2.cam_0);
    const scaneInfoCam1 = getOriginalDataForm(clientSizes?.clientSizeLeft, stage2.cam_1);
    const eyepiecesArea = {
      cam_0: scaneInfoCam0,
      cam_1: scaneInfoCam1,
    };

    stage2.scaleInfoCam0 = scaneInfoCam0;
    stage2.scaleInfoCam1 = scaneInfoCam1;
    stage2.blobLeftLeft = await cropImageByArea(this.state.formValues.stage1.blobLeft, {
      width: scaneInfoCam0.cont_l.width,
      height: scaneInfoCam0.cont_l.height,
      x: scaneInfoCam0.cont_l.x,
      y: scaneInfoCam0.cont_l.y,
    });
    stage2.blobLeftRight = await cropImageByArea(this.state.formValues.stage1.blobLeft, {
      width: scaneInfoCam0.cont_r.width,
      height: scaneInfoCam0.cont_r.height,
      x: scaneInfoCam0.cont_r.x,
      y: scaneInfoCam0.cont_r.y,
    });
    stage2.blobRightLeft = await cropImageByArea(this.state.formValues.stage1.blobRight, {
      width: scaneInfoCam1.cont_l.width,
      height: scaneInfoCam1.cont_l.height,
      x: scaneInfoCam1.cont_l.x,
      y: scaneInfoCam1.cont_l.y,
    });
    stage2.blobRightRight = await cropImageByArea(this.state.formValues.stage1.blobRight, {
      width: scaneInfoCam1.cont_r.width,
      height: scaneInfoCam1.cont_r.height,
      x: scaneInfoCam1.cont_r.x,
      y: scaneInfoCam1.cont_r.y,
    });

    let formValues = {...this.state.formValues};
    formValues.stage2 = stage2;

    const body = {...eyepiecesArea};
    delete body.cam_0.cont_l.percentScaleHeight;
    delete body.cam_0.cont_l.percentScaleWidth;
    delete body.cam_0.cont_r.percentScaleHeight;
    delete body.cam_0.cont_r.percentScaleWidth;
    delete body.cam_1.cont_l.percentScaleHeight;
    delete body.cam_1.cont_l.percentScaleWidth;
    delete body.cam_1.cont_r.percentScaleHeight;
    delete body.cam_1.cont_r.percentScaleWidth;

    if (this.state.calculation) {
      await this.setState({
        formValues,
        isBackdrop: false,
        activeStage: 2
      });
      await this.initFormStage3();
      return
    }

    const resSendData = await agent.patch(`/calculation/${this.state.sessionId}/update-eyepieces-area`, {
      eyepiecesArea: body
    }).then((res) => {
      return res.data
    }).catch(() => {
      return null
    });

    await this.changeStageDisabledNext(1);

    await this.setState({
      formValues,
      lineCounters: resSendData?.eyepiecesArea || {},
      eyepiecesAreaToBack: eyepiecesArea,
      isBackdrop: false,
      activeStage: 2
    });

    await this.initFormStage3();
  }

  // Логика работы с этапом уточнения центра окуляра
  initFormStage3 = async () => {
    if (!this.refClarificationCenterPupils.current) {
      setTimeout(async () => {
        await this.initFormStage3();
      }, 500)
      return
    }
    const initForm = Boolean(this.state.calculation?.centerOfPupils) ? this.state.calculation : await agent.patch(`/calculation/${this.state.sessionId}/calculate-center-of-pupils`).then((res) => {
      return res.data?.calculation
    }).catch(() => {
      return null
    });
    const imagesClientSizez = await this.refClarificationCenterPupils.current._getImagesClientSize();

    const scaleParams = (originalData, clientData, formData) => {
      const scaleWidth = originalData?.width / clientData?.width;
      const scaleHeight = originalData?.height / clientData?.height;

      return {
        x: (formData.x - originalData.x) / scaleWidth,
        y: (formData.y - originalData.y) / scaleHeight,
      }
    }

    const centerLL = scaleParams(initForm?.eyepiecesArea?.cam_0?.cont_l, imagesClientSizez?.left_left, initForm?.centerOfPupils?.cam_0?.cont_l);
    const centerLR = scaleParams(initForm?.eyepiecesArea?.cam_0?.cont_r, imagesClientSizez?.left_right, initForm?.centerOfPupils?.cam_0?.cont_r);
    const centerRL = scaleParams(initForm?.eyepiecesArea?.cam_1?.cont_l, imagesClientSizez?.right_left, initForm?.centerOfPupils?.cam_1?.cont_l);
    const centerRR = scaleParams(initForm?.eyepiecesArea?.cam_1?.cont_r, imagesClientSizez?.right_right, initForm?.centerOfPupils?.cam_1?.cont_r);

    let formValues = {...this.state.formValues};
    formValues.stage3 = {
      ...formValues.stage3,
      "cam0": {
        "eye_r": centerLR,
        "eye_l": centerLL,
      },
      "cam1": {
        "eye_r": centerRR,
        "eye_l": centerRL,
      }
    }

    await this.changeStageDisabledNext(2);

    this.setState({
      formValues
    })
  }
  changeFormStage3 = async (form) => {
    let formValues = {...this.state.formValues};
    formValues.stage3 = form;
    await this.changeStageDisabledNext(2);
    this.setState({formValues});
  }
  submitFormStage3 = async (clientSizes) => {
    const value = this.state.formValues.stage3;

    const roundNumberForGetSize = (val) => {
      if (Number.isInteger(val)) {
        return val
      }

      return Math.round(Math.floor(val * 10) / 10)
    }
    const getProportionsData = async (data, blob, clientSize, displacementArea) => {
      const {width: originalWidth, height: originalHeight} = await getOriginalSizeImage(blob);
      const {width: clientWidth, height: clientHeight} = clientSize;
      const percentScaleLeftWidth = (((originalWidth / clientWidth) * 100) - 100) / 100;
      const percentScaleLeftHeight = (((originalWidth / clientHeight) * 100) - 100) / 100;

      return {
        x: roundNumberForGetSize((data.x + (percentScaleLeftWidth * data.x)) + displacementArea.x),
        y: roundNumberForGetSize((data.y + (percentScaleLeftWidth * data.y)) + displacementArea.y),
      }
    };

    const cam0Left = await getProportionsData(value.cam0.eye_l, this.state.formValues.stage2.blobLeftLeft, clientSizes.left_left, this.state.formValues.stage2.scaleInfoCam0.cont_l);
    const cam0Right = await getProportionsData(value.cam0.eye_r, this.state.formValues.stage2.blobLeftRight, clientSizes.left_right, this.state.formValues.stage2.scaleInfoCam0.cont_r);
    const cam1Left = await getProportionsData(value.cam1.eye_l, this.state.formValues.stage2.blobRightLeft, clientSizes.right_left, this.state.formValues.stage2.scaleInfoCam1.cont_l);
    const cam1Right = await getProportionsData(value.cam1.eye_r, this.state.formValues.stage2.blobRightRight, clientSizes.right_right, this.state.formValues.stage2.scaleInfoCam1.cont_r);

    const res = await agent.patch(`/calculation/${this.state.sessionId}/update-center-of-pupils`, {
      "centerOfPupils": {
        "cam_0": {
          "cont_r": {...cam0Right},
          "cont_l": {...cam0Left},
        },
        "cam_1": {
          "cont_r": {...cam1Right},
          "cont_l": {...cam1Left},
        },
      }
    }).then((res) => {
      return res.data
    }).catch(() => {
      return null
    });

    await this.changeStageDisabledNext(2);

    await this.setState({
      isBackdrop: false,
      activeStage: 3
    });
    await this.initFormStage4();
  }

  // Логика работы с уточнением контуров
  initFormStage4 = async () => {
    if (!this.refContourRefinement.current) {
      setTimeout(async () => {
        await this.initFormStage4();
      }, 500);
      return
    }
    const imagesClientSize = await this.refContourRefinement.current._getImagesClientSize();
    const res = Boolean(this.state?.calculation?.contoursOfFrames && this.state?.calculation?.eyepiecesArea)
      ? this.state.calculation
      : await agent.patch(`/calculation/${this.state.sessionId}/calculate-contours-of-frames`).then((res) => {
      return res.data?.calculation
    }).catch(() => {
      return null
    });

    const scaleDots = (dots, clientSize, originSize) => {
      const scaleWidth = originSize.width / clientSize.width;
      const scaleHeight = originSize.height / clientSize.height;
      return dots.map((t) => {
        return {
          x: (t.x - originSize.x) / scaleWidth,
          y: (t.y - originSize.y) / scaleHeight,
        }
      })
    }
    const contoursOfFrames = {
      cam_0: {
        cont_l: scaleDots(res?.contoursOfFrames?.cam_0?.cont_l, imagesClientSize?.bll, res?.eyepiecesArea?.cam_0?.cont_l),
        cont_r: scaleDots(res?.contoursOfFrames?.cam_0?.cont_r, imagesClientSize?.blr, res?.eyepiecesArea?.cam_0?.cont_r),
      },
      cam_1: {
        cont_l: scaleDots(res?.contoursOfFrames?.cam_1?.cont_l, imagesClientSize?.brl, res?.eyepiecesArea?.cam_1?.cont_l),
        cont_r: scaleDots(res?.contoursOfFrames?.cam_1?.cont_r, imagesClientSize?.brr, res?.eyepiecesArea?.cam_1?.cont_r),
      }
    }

    let formValues = {...this.state.formValues};
    formValues.stage4.coords = contoursOfFrames;
    await this.changeStageDisabledNext(3);
    this.setState({
      formValues
    })
  }
  changeFormStage4 = async (form) => {
    let formValues = {...this.state.formValues};
    formValues.stage4 = form;
    await this.changeStageDisabledNext(3);
    this.setState({formValues});
  }
  submitFormStage4 = async (formSubmit) => {
    if (this.state.calculation?.status === "UPDATE_CONTOURS_FRAMES") {
      this.props.history.push(`/orders/${this.state.calculation.orderId}`)
      return
    }

    this.setState({isBackdrop: true});
    const res = await agent.patch(`/calculation/${this.state.sessionId}/update-contours-of-frames`, formSubmit).then((res) => {
      return res.data
    }).catch(() => {
      return null
    });
    const result = await agent.patch(`/calculation/${this.state.sessionId}/get-params-of-eyeglass-frame`).then((res) => {
      return res.data?.paramsOfEyeglassFrame || {}
    }).catch(() => {
      return null
    });
    await this.changeStageDisabledNext(4);
    this.setState({
      resultat: result,
      activeStage: 4,
      isBackdrop: false,
      isResult: true
    });

    // this.props.history.push(`/orders/${this.state.order.id}`)
  }

  changeStageDisabledNext = (beforeDisabled) => {
    let stages = [...this.state.stages];
    stages = stages.map((t, i) => {
      return {
        ...t,
        disabledNext: Boolean(i > beforeDisabled)
      }
    })
    this.setState({ stages });
  }

  sendTo1c = async () => {
    this.setState({ isBackdrop: true });
    const res = await agent.post(`/calculation/${this.state.sessionId}/send-to-1c`, {
      calculationId: this.state.sessionId
    }).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: true}
    });
    if (res.error || !res) {
      this.setState({ isBackdrop: false });
      Notification({
        message: "Ошибка отправки заказа в 1C",
        type: notificationTypes.error
      })
      return
    }
    this.setState({ isBackdrop: false });
    Notification({
      message: "Заказ успешно отправлен в 1C",
      type: notificationTypes.success
    })
  }

  _selectedCameraEditableId = () => {
    const { equipments } = this.props;
    const { selectedCameraId } = this.state;
    const equipment = (equipments || []).find((t) => t.externalId === selectedCameraId);
    if (!equipment) {
      return selectedCameraId
    }
    return equipment?.editableId
  }

  render () {
    const {
      formValues,
      stages,
      resultat,
      activeStage,
      lineCounters,
      isResult,
      isBackdrop,
      sessionId,
      usbProductId,
      selectedCameraId,
      eyepiecesAreaToBack
    } = this.state;
    const isAvaible1cOrder = Boolean(this.state?.order?.externalId);

    return (
      <Box>

        <ContainerBoxElement isContainer={true}>
          <StagesComponent
            stages={stages}
            active={activeStage}
            changeStage={this.changeStage}
          />
        </ContainerBoxElement>

        <Box mt="20px"/>

        {/* Контейнер для этапов кроме 1 */}
        <ContainerBoxElement isContainer={true}>
          <VisibleContent visible={Boolean(activeStage === 0)}>
            <StereoCameraShot
              onChange={this.changeFormStage1}
              onChangeSelectedCamera={this.changeSelectedCamera}
            />
          </VisibleContent>
          <VisibleContent visible={Boolean(activeStage === 2)}>
            <ClarificationCenterPupils
              ref={this.refClarificationCenterPupils}
              blob={formValues?.stage1?.blob}
              blobLeft={formValues?.stage1?.blobLeft}
              blobRight={formValues?.stage1?.blobRight}
              blobLeftLeft={formValues.stage2.blobLeftLeft}
              blobLeftRight={formValues.stage2.blobLeftRight}
              blobRightLeft={formValues.stage2.blobRightLeft}
              blobRightRight={formValues.stage2.blobRightRight}
              form={formValues?.stage3}
              onChange={this.changeFormStage3}
              onSubmit={this.submitFormStage3}
            />
          </VisibleContent>
          <VisibleContent visible={Boolean(activeStage === 3)}>
            <ContourRefinement
              ref={this.refContourRefinement}
              form={formValues?.stage4}
              fullForm={formValues}
              lineCounters={lineCounters}
              eyepiecesAreaToBack={eyepiecesAreaToBack}

              blob={formValues?.stage1?.blob}
              blobLeft={formValues?.stage1?.blobLeft}
              blobRight={formValues?.stage1?.blobRight}
              blobLeftLeft={formValues.stage2.blobLeftLeft}
              blobLeftRight={formValues.stage2.blobLeftRight}
              blobRightLeft={formValues.stage2.blobRightLeft}
              blobRightRight={formValues.stage2.blobRightRight}

              onChange={this.changeFormStage4}
              onSubmit={this.submitFormStage4}
            />
          </VisibleContent>
          <VisibleContent visible={Boolean(activeStage === 4)}>
            <ResultFormComponent
              data={resultat}
              isAvaible1cOrder={isAvaible1cOrder}
              onSendTo1c={this.sendTo1c}
            />
          </VisibleContent>
        </ContainerBoxElement>

        <VisibleContent visible={Boolean(activeStage === 1)}>
          <RefinementEyepieceArea
            ref={this.refRefinementEyepieceArea}
            blob={formValues?.stage1?.blob}
            blobLeft={formValues?.stage1?.blobLeft}
            blobRight={formValues?.stage1?.blobRight}
            form={formValues?.stage2}
            onChange={this.changeFormStage2}
            onSubmit={this.submitFormStage2}
          />
        </VisibleContent>


        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>


        <DialogNotification ref={this.refDialogNotification}/>
        <DialogSelectedEquipment ref={this.refDialogSelectedEquipment}/>

        <Box sx={{
          position: "fixed",
          left: 20, bottom: 20,
          padding: "20px",
          zIndex: 20,
          backgroundColor: "white"
        }}>
          ID камеры: {this._selectedCameraEditableId()}<br/>
          ID расчета: {sessionId}
          {false && (
            <>
              ID гироскопа: {usbProductId}
            </>
          )}
        </Box>
      </Box>
    );
  }
}
const ContainerBoxElement = React.memo(({ children, isContainer }) => {
  if (!isContainer) {
    return (<Box>{children}</Box>)
  }
  return (<Container maxWidth="lg">{children}</Container>)
})

const VisibleContent = React.memo(({visible, children}) => {
  if (!visible) {
    return null
  }
  return children
});

export default FrameMeasurement
