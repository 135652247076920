import {palette} from "../common";

export default {
  styleOverrides: {
    root: {
      backgroundColor: "white"
    },

    multiline: {
      padding: "0!important"
    }
  }
}
