import {palette} from "../common";

export default {
  styleOverrides: {
    root: {
      "& .MuiInputLabel-root": {
        transform: "translate(30px, 18px) scale(1.2)",

        fontWeight: "300",
        fontSize: 16,
        lineHeight: "100%",
        color: "#8E8E8E",

        "&.Mui-focused, &.MuiFormLabel-filled": {
          transform: "translate(16px, -18px) scale(0.75)"
        }
      },
      // "& .MuiOutlinedInput-root": {
      //   borderRadius: 8,
      //   fontSize: 16,
      //   lineHeight: "19px",
      //
      //   "&.Mui-error": {
      //     borderColor: "#F5386A"
      //   }
      // },
      // "& .MuiOutlinedInput-input": {
      //   padding: 12
      // },

      "& .MuiFormHelperText-root": {
        fontSize: 14,
        lineHeight: "19px",
        color: "#F5386A",
        justifyContent: "flex-start"
      }
    }
  }
}
