import React from "react";
import {Switch, Redirect} from "react-router-dom";
import {
  RouteWithLayout
} from "../components";
import {
  Minimal as MinimalLayouts
} from "../layouts";
import {
  AdminAuth as AdminAuthPage,
  CreateFrom1c as CreateFrom1cPage
} from "../pages";


const pages = [
  {
    path: '/',
    component: AdminAuthPage,
    layout: MinimalLayouts,
    rules: [],
    exact: true
  },
  {
    path: '/create-from-1c',
    component: CreateFrom1cPage,
    layout: MinimalLayouts,
    rules: [],
    exact: true
  },
];

const RegistrationRoutes = () => {
  return (
    <Switch>
      {
        pages.map((page, idx) => (
          <RouteWithLayout
            key={'page-' + idx}
            {...page}
          />
        ))
      }

      <Redirect to="/"/>
    </Switch>
  );
};

export default RegistrationRoutes;
