import {palette} from "../common";

export default {
  styleOverrides: {
    root: {
      background: "white",
      border: "1px solid #E4E4E4",
      boxSizing: "border-box",
      borderRadius: 14,

      "& .MuiOutlinedInput-notchedOutline": {
        border: "none"
      },

      "& .MuiOutlinedInput-input": {
        padding: "15px 30px",
        fontSize: "16px",
        lineHeight: "25px"
      },

      "& .MuiInputBase-inputMultiline": {
        padding: "15px 30px"
      }
    }
  }
}
