export default {
    styleOverrides: {
        root: {
            "& .MuiBackdrop-root": {
                backgroundColor: "rgba(40, 40, 40, 0.4)",
                backdropFilter: "blur(5px)"
            },
            "& .MuiDialog-paperWidthMd": {
                borderRadius: 12,
                border: "1px solid #EAEAEA",
                maxWidth: 570
            },
            "& .MuiDialog-paperWidthSm": {
                borderRadius: 12,
                border: "1px solid #EAEAEA",
                maxWidth: 450
            },
            "& .MuiDialogContent-root": {
                padding: 20,
                "@media(max-width: 600px)": {
                    padding: 12,
                }
            },
            "& .MuiDialog-scrollBody": {
                overflowY: "scroll"
            },

            "@media(max-width: 600px)": {
                "& .MuiPaper-root": {
                    width: "calc(100% - 24px)",
                    margin: 12,
                },
                "& .MuiPaper-root.MuiDialog-paperScrollBody": {
                    maxWidth: "100%"
                }
            }
        }
    }
}
