import React, {Component} from "react";
import {
  Box,
  Backdrop,
  Container,
  Typography,
  CircularProgress
} from "@mui/material";
import {
  withStyles
} from "@mui/styles";
import {
  Form
} from "./components";
import {
  Notification,
  notificationTypes
} from "../../common/Notification";
import moment from "moment";
import agent from "../../agent/agent";
import allTranslations from "../../locales/allTranslations";

class RegistrationUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isBackdrop: false
    };
  }

  componentDidMount = () => {
  }

  // Регистрация пациента
  registrationPatient = async (form) => {
    this.setState({ isBackdrop: true });

    let body = {
      ...form
    };
    body.phone = (body.phone || '').replace(/\D+/g,"");
    body.birthdate = moment(body.birthdate).toISOString();
    const registrationPatient = await agent.post('/patients', body).then((res) => {
      return res.data
    }).catch((err) => {
      return {error: true}
    });
    if (registrationPatient.error) {
      this.setState({ isBackdrop: false });
      Notification({
        message: allTranslations('Ошибка создания пациента, проверьте корректность введенных данных'),
        type: notificationTypes.error
      })
      return
    }

    localStorage.setItem("patient_id", registrationPatient?.patient?.id);
    this.props.setPatient(registrationPatient?.patient);

    this.setState({ isBackdrop: false });
  }

  render () {
    const {
      classes
    } = this.props;
    const {
      isBackdrop
    } = this.state;

    return (
      <Box className={classes.root}>
        <Container>
          <Box className={classes.body}>

            <Typography variant="h1" align="center" mb="15px">
              {allTranslations('Добавить заказ')}
            </Typography>
            <Typography className={classes.subtitle}>
              {allTranslations('Дата заполнения')}: <span>{moment().format("DD.MM.YYYY")}</span>
            </Typography>

            <Form
              onSubmit={this.registrationPatient}
            />

          </Box>
        </Container>

        <Backdrop open={isBackdrop}>
          <CircularProgress/>
        </Backdrop>
      </Box>
    );
  }
}

const styles = {
  root: {
    padding: "60px 0",
    boxSizing: "border-box",
  },
  body: {
    backgroundColor: "#F5F4F5",
    padding: "60px",
    boxSizing: "border-box",
    borderRadius: "12px"
  },
  subtitle: {
    marginBottom: "25px",

    fontSize: "16px",
    lineHeight: "25px",
    color: "#6F6F6F",
    textAlign: "center",

    "& span": {
      display: "black",
      marginLeft: "10px",

      color: "black",
      fontWeight: "600"
    }
  }
};
RegistrationUser = withStyles(styles)(RegistrationUser);

export default RegistrationUser
